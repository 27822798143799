import { useEffect, useState, useCallback } from 'react';
import { LoginContainerProps } from './LoginContainer';
import { getJWTCookie, deleteJWTCookie } from '../../middleware/auth';
import { ErrorType } from '../../store/errorHelpers';
import DynamicConfig from '../../config/DynamicConfig';

const getAuthValues = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const authCode = urlParams.get('code') || '';
  const oauthError = urlParams.get('error') || '';
  const redirectUrl =
    window.location.protocol + '//' + window.location.host + window.location.pathname;
  const jwtCookie = getJWTCookie();

  return { authCode, redirectUrl, jwtCookie, oauthError };
};

export const toOktaLogin = (redirectUrl: string, sourcePath: string): void => {
  const { OKTA_OAUTH_URL, OKTA_CLIENT_ID } = DynamicConfig.GetConfig();
  window.location.href = `${OKTA_OAUTH_URL}?client_id=${OKTA_CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(
    redirectUrl,
  )}&scope=openid&state=${encodeURIComponent(sourcePath)}`;
};

export const LoginContainerLogic = ({
  location,
  loggedIn,
  userLoaded,
  authenticateUser,
  loadUser,
  history,
}: LoginContainerProps) => {
  // path where user logs in from - (e.g. /)
  const [sourcePath, setSourcePath] = useState('');
  const [loginError, setLoginError] = useState(ErrorType.NONE);

  const toOktaLoginCallback = useCallback(() => {
    setLoginError(ErrorType.NONE);
    const { redirectUrl } = getAuthValues();
    toOktaLogin(redirectUrl, sourcePath);
  }, [sourcePath]);

  /**
   * grab the path to redirect back to. the path can come from:
   *   1. location.state.from (react-router) - when no auth is needed
   *   2. state (url query string) - when redirected back from oauth
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const loginFrom = urlParams.get('state') || '';
    setSourcePath(location.state?.from || loginFrom || '/');
  }, [location]);

  /**
   * authentication
   *   - if there's an auth code, authenticate user
   *   - if no auth code, then redirect to oauth to get one
   *   - if there's a jwt, don't run hook at all
   */
  useEffect(() => {
    const { authCode, redirectUrl, jwtCookie, oauthError } = getAuthValues();
    if (jwtCookie || sourcePath === '') return;
    if (oauthError !== '') {
      setLoginError(ErrorType.UNAUTHORIZED_USER);
      return;
    }

    if (authCode === '') {
      toOktaLoginCallback();
    } else {
      authenticateUser(authCode, redirectUrl).catch((err: ErrorType) => {
        history.replace('/login'); // remove auth_code from URL
        setLoginError(err);
      });
    }
  }, [authenticateUser, sourcePath, toOktaLoginCallback]);

  /**
   * try to load user from jwt cookie
   */
  useEffect(() => {
    if (userLoaded || sourcePath === '') return;
    const { jwtCookie } = getAuthValues();
    if (jwtCookie !== '') {
      loadUser().catch((err: ErrorType) => {
        deleteJWTCookie();

        if (err === ErrorType.HTTP_STATUS_401) {
          // session expired, ask to login again
          toOktaLoginCallback();
        } else {
          setLoginError(err);
        }
      });
    }
  }, [userLoaded, loggedIn, sourcePath, loadUser, toOktaLoginCallback, deleteJWTCookie]);

  return { sourcePath, toOktaLoginCallback, loginError };
};
