import React from 'react';
import styled from 'styled-components';
import { spaceMixin } from '@zillow/constellation';
import { NoStyleLink } from '../Links';
import { ConditionalWrapper } from '../ConditionalWrapper';
import { ZImage } from '../ZImage';
import fallbackImage from '../../assets/filenotfound.png';
export interface BadgeProps {
  /** name of badge */
  name: string;
  /** photo url of badge icon */
  iconUrl: string;
  /** optional link if clickable */
  link?: string;
  /** size overide */
  size?: string;
}

const BadgeWrapper = styled.span`
  margin: ${spaceMixin('xs')};
`;

const BadgeIcon = styled(ZImage)<{ size?: string }>`
  max-width: ${(props) => (props.size ? props.size : '110px')};
  max-height: ${(props) => (props.size ? props.size : '110px')};
  width: 100%;
`;

export const Badge: React.FC<BadgeProps> = ({ name, iconUrl, link, size }: BadgeProps) => {
  return (
    <BadgeWrapper data-testid='render-badge-test'>
      <ConditionalWrapper
        condition={link !== undefined}
        wrapper={(children) => <NoStyleLink to={link || '#'}>{children}</NoStyleLink>}
      >
        <BadgeIcon
          imageUrl={iconUrl}
          alt={name}
          title={name}
          fallbackUrl={fallbackImage}
          size={size}
        />
      </ConditionalWrapper>
    </BadgeWrapper>
  );
};
