import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Page } from '@zillow/constellation';
import HeaderContainer from '../containers/HeaderContainer';
import ImpersonationContainer from '../containers/ImpersonationContainer';
import { Footer } from '../components/Footer';
import Login from './Login';
import Profile from './Profile';
import InsightsPages from './Insights';
import BadgePages from './Badges';
import InterestPages from './Interests';
import NotFound from './NotFound';
import UnderMaintenance from './UnderMaintenance';
import Admin from './Admin';
import { RootState } from '../store';
import { selectLoggedIn } from '../store/user';
import { ZallAppLogic } from './ZallApp.hooks';
import { loadWallConfig } from '../store/wallConfig';
import DynamicConfig from '../config/DynamicConfig';
import { HtmlTitle } from '../components/HtmlTitle';

export interface ZallAppProps extends RouteComponentProps {
  loggedIn: boolean;
  loadWallConfig: () => Promise<void>;
}

const PageWrapper = styled(Page)`
  min-height: 100vh;
`;

const MainContentWrapper = styled.div`
  min-height: 90vh;
`;

const ZallApp: React.FunctionComponent<ZallAppProps> = (props: ZallAppProps) => {
  const { MAINTENANCE_MODE } = DynamicConfig.GetConfig();
  const impersonationModalProps = ZallAppLogic(props);

  return (
    <PageWrapper>
      <HtmlTitle>
        <title>ZG ZallWall</title>
      </HtmlTitle>
      <ImpersonationContainer {...impersonationModalProps} />
      <HeaderContainer {...impersonationModalProps} />
      <MainContentWrapper>
        <Switch>
          {MAINTENANCE_MODE ? <Route path="/*" component={UnderMaintenance} /> : ''}
          <Route path="/admin" component={Admin} />
          <Route path="/login" component={Login} />
          <Route path="/insights" component={InsightsPages} />
          <Route path="/badges" component={BadgePages} />
          <Route path="/interests" component={InterestPages} />
          <Route path="/notfound" component={NotFound} />
          <Route path="/:username" component={Profile} />
          <Route path="/" component={Profile} />
        </Switch>
      </MainContentWrapper>
      <Footer />
    </PageWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  loggedIn: selectLoggedIn(state),
});

const mapDispatchToProps = {
  loadWallConfig: loadWallConfig,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

export default withRouter(
  connect<StateToPropsType, DispatchToPropsType, Record<string, unknown>, RootState>(
    mapStateToProps,
    mapDispatchToProps,
  )(ZallApp),
);
