import React from 'react';
import styled from 'styled-components';
import { PieChart } from 'react-minimal-pie-chart';
import { Text, fontSizeMixin, lineHeightMixin } from '@zillow/constellation';

export interface TenureProps {
  /** person's full name */
  fullName: string;
  /** number of employees starting before person */
  before: number | undefined;
  /** number of employees starting after person */
  after: number | undefined;
}

const TenureWrapper = styled.div`
  width: 200px;
  height: 200px;
  margin: auto;
  text-align: center;
`;

const Label = styled(Text)`
  font-size: ${fontSizeMixin('bodySmallHeading')};
  line-height: ${lineHeightMixin('finePrintHeading')};
`;

const PieChartWrapper = styled.div`
  width: 150px;
  margin: 10px auto 5px;
`;

const LegendWrapper = styled(Text)<{ color: string }>`
  margin: 10px;
  &:before {
    background: ${(props) => props.color};
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 4px;
  }
`;

export const Tenure: React.FC<TenureProps> = ({ fullName, before, after }: TenureProps) => {
  if (before === undefined || after === undefined) return null;

  const tenure = [
    { title: `Before: ${before}`, value: before, color: '#1B5098' },
    { title: `After: ${after}`, value: after, color: '#5EAB22' },
  ];

  return (
    <TenureWrapper data-testid='render-tenure-test'>
      <Label fontType="bodySmallHeading">Employees Starting Before Or After {fullName}</Label>
      <PieChartWrapper>
        <PieChart
          data={tenure}
          label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%'}
          labelStyle={{
            fill: '#fff',
            pointerEvents: 'none',
            fontSize: '8px',
          }}
          data-testid='render-tenure-chart-test'
        />
      </PieChartWrapper>
      <LegendWrapper fontType="bodySmallHeading" color={'#1b5098'}>
        BEFORE
      </LegendWrapper>
      <LegendWrapper fontType="bodySmallHeading" color={'#5eab1f'}>
        AFTER
      </LegendWrapper>
    </TenureWrapper>
  );
};
