import { useState, useEffect, useCallback } from 'react';
import { AllBadgesContainerProps } from './AllBadgesContainer';

export const AllBadgesContainerLogic = ({ loadAllBadges }: AllBadgesContainerProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const loadAllBadgesCallback = useCallback((): Promise<void> => {
    return loadAllBadges();
  }, [loadAllBadges]);

  useEffect(() => {
    setIsLoading(true);
    loadAllBadgesCallback().then(() => setIsLoading(false));
  }, []);

  return { isLoading };
};
