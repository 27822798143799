import Snowflakes from 'magic-snowflakes';

export class AnimationUtils {
  /** add falling snowflakes to container */
  public static AddSnowflakes = (container: HTMLElement) => {
    new Snowflakes({
      color: 'white',
      container: container,
      minSize: 10,
      maxSize: 40,
      zIndex: 0,
      speed: 0.8,
      // use less snowflakes on smaller screens
      count: window.innerWidth > 600 ? 35 : 20,
    });
  };

  /** add spring flowers to container */
  public static AddSpringFlowers = (container: HTMLElement) => {
    const MAX_NUM_FLOWERS = 30;
    const FLOWER_TYPES = 6;

    for (let i = 0; i <= MAX_NUM_FLOWERS; i++) {
      const flower = document.createElement('div');
      flower.className = `flower flower-${i} type-${i % FLOWER_TYPES}`;
      const stem = document.createElement('span');
      flower.appendChild(stem);
      container.appendChild(flower);
    }
  };
}
