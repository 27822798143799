import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import {
  Image,
  ButtonGroup,
  IconButton,
  Spacer,
} from '@zillow/constellation';
import ZodiacLogo from '../../assets/zodiacLogo.png';
import LinkedInLogo from '../../assets/linkedInLogo.png';
import OutlookLogo from '../../assets/outlookLogo.png';
import DynamicConfig from '../../config/DynamicConfig';

interface EmployeeProfileLinksContainerProps {
    email: string;
    linkedIn?: string;
    inTech?: boolean;
}

const ZodiacImg = styled(Image)`
  height: 16px;
  width: 30px;
  margin-top: 5px;
`;
const ExternalAppImg = styled(Image)`
  height: 25px;
  width: 27px;
  margin-top: 5px;
`;
const LinkButton = styled(IconButton)`
  border-color: #f6f6fa;
  box-shadow: 0 2px 4px 0 #0000001a, 0 3px 10px 0 #00000018;
`

const getUsername = (email: string) => email.split('@')[0];
export const EmployeeProfileLinksContainer: React.FC<EmployeeProfileLinksContainerProps> = ({
    email,
    linkedIn,
    inTech
}: EmployeeProfileLinksContainerProps) => {

  const ifLinkedIn = () => {
    return linkedIn !== undefined && linkedIn !== '';
  }

  const { ZODIAC_DOMAIN } = DynamicConfig.GetConfig();

  const handleClick = (identifyer: string) => {
    switch (identifyer) {
      case 'zodiac':
        window.open(ZODIAC_DOMAIN + getUsername(email));
        break;
      case 'outlook':
        window.open(`mailto:${email}`);
        ReactGA.event({ category: 'Profile', action: 'Clicked Employee Email link' })
        break;
      case 'linkedin':
        window.open(linkedIn);
        break;
      default:
        break;
    }
  }

  return (
<a>
  <Spacer data-testid="spacer-render-test" marginBottom="xs"></Spacer >
  <ButtonGroup aria-label="Button type example">
      <LinkButton data-testid="outlook-button-render-test" inverse size="md" onClick={() =>handleClick('outlook')} buttonType="secondary"  icon={<ExternalAppImg alt="Email Address" src={OutlookLogo}/>} title="Right" />
      {ifLinkedIn() && <LinkButton data-testid="linkedin-button-render-test" inverse size="md" onClick={() =>handleClick('linkedin')} buttonType="secondary"  icon={<ExternalAppImg alt="LinkedIn Profile Link" src={LinkedInLogo}/>} title="Right" /> }
      { inTech && <LinkButton data-testid="zodiac-button-render-test" inverse size="md" onClick={() =>handleClick('zodiac')} buttonType="secondary"  icon={<ZodiacImg alt="Zodiac Profile Link" src={ZodiacLogo}/>} title="Right" />}
  </ButtonGroup>
</a>
  );
};
