import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Heading,
  FormField,
  Label,
  Combobox,
  LoadingButton,
  Banner,
  Flex,
  IconUser,
  Text,
  TextButton,
  MediaObject,
  Paragraph,
  Spacer,
  IconButton,
  IconClose,
  Alert,
  AdornedInput,
  Adornment,
  IconSearch,
  Input,
  spaceMixin,
} from '@zillow/constellation';
import { AvatarDisk } from '../../components/AvatarDisk';
import { RootState } from '../../store';
import { searchEmployees, EmployeeFound } from '../../store/search';
import { Employee, selectUserEmployeeInfo } from '../../store/user';
import {
  selectIsImpersonating,
  selectImpersonator,
  impersonateUser,
  stopImpersonation,
  getMyImpersonatees,
  GetMyImpersonateesPromiseResponse,
} from '../../store/impersonation';
import { Modal } from '../../components/Modal';
import { ImpersonationContainerLogic } from './ImpersonationContainer.hooks';
import DynamicConfig from '../../config/DynamicConfig';
import { ErrorType } from '../../store/errorHelpers';
import { Loader } from '../../components/Loader';

export interface StateProps {
  /** is currently in impersonation mode */
  isImpersonating: boolean;
  /** user being impersonated (only if isImpersonating is true) */
  impersonatee: Employee;
  /** user impersonation request */
  impersonateUser: (employeeId: string) => Promise<void>;
  /** stop impersonation */
  stopImpersonation: () => Promise<void>;
  /** search employees */
  searchEmployees: (keyword: string) => Promise<EmployeeFound[]>;
  /** get my impersonatees */
  getMyImpersonatees: () => Promise<GetMyImpersonateesPromiseResponse>;
}

interface OwnProps {
  /** is impersonation modal open */
  isImpersonationModalOpen: boolean;
  /** setter for is impersonation modal open */
  setIsImpersonationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export type ImpersonationContainerProps = StateProps & OwnProps;

const ImpersonationBanner = styled(Banner)`
  position: sticky;
  top: 0;
  z-index: 100;
`;

const BannerContent = styled(Flex)`
  width: 100%;
`;

const ModalBody = styled(Flex)`
  min-height: 300px;
  padding-top: ${spaceMixin('md')};
`;

export type SearchOption = {
  id: string;
  value: string;
  label: string;
  employee: EmployeeFound;
  children: React.ReactNode;
};

export const renderSearchOption = (employee: EmployeeFound) => {
  const { fullName, email, photoUrl } = employee;
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  return (
    <MediaObject
      media={
        <Spacer marginTop="xs">
          <AvatarDisk
            fullName={fullName}
            photoUrl={photoUrl && `${ZALL_ENGINE_PROXY}${photoUrl}`}
            size="sm"
            shape={'square'}
          />
        </Spacer>
      }
      align="center"
      data-testid="impersonation-container-search-option"
    >
      <Text>{fullName}</Text>
      <Paragraph fontType="finePrint">{email}</Paragraph>
    </MediaObject>
  );
};

const ImpersonationContainerBase: React.FC<ImpersonationContainerProps> = (
  props: ImpersonationContainerProps,
) => {
  const { isImpersonating, isImpersonationModalOpen, impersonatee } = props;
  const {
    areImpersonateesLoaded,
    isAllowedAll,
    allowedImpersonatees,
    searchInput,
    isLoadingOptions,
    searchOptions,
    selectedOption,
    isRequestingImpersonation,
    isStoppingImpersonation,
    startImpersonationErr,
    handleOnInputChange,
    handleSelectOption,
    handleResetSelectedOption,
    handleStartImpersonation,
    handleStopImpersonation,
    handleImpersonateClick,
    handleCloseModal,
  } = ImpersonationContainerLogic(props);

  /**
   * pass onChange event to Input instead of Combobox to:
   *    1. trigger each time the search input is modified
   *    2. avoid triggering onChange event when an option is selected
   */
  const renderInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ onClear, onTagClose, onChange, ...rest }) => (
      <AdornedInput
        input={
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleOnInputChange(e.target.value);
              onChange(e);
            }}
            {...rest}
            data-testid="impersonation-container-search-input"
          />
        }
        rightAdornment={
          <Adornment>
            <IconSearch />
          </Adornment>
        }
      />
    ),
    [handleOnInputChange],
  );

  return (
    <>
      {isImpersonating && (
        <ImpersonationBanner appearance="warning" role="status" icon={<IconUser />}>
          <BannerContent display="flex" paddingX="lg">
            <Flex flex="1 0 auto">
              <Text paddingX="sm">
                <IconUser marginRight="xs" /> Impersonating <strong>{impersonatee.fullName}</strong>
                <Text marginX="sm">•</Text>
                {impersonatee.email}
              </Text>
            </Flex>
            <Flex flex="0 0 auto">
              <Text>
                <TextButton onClick={handleImpersonateClick}>Impersonate</TextButton>
                <Text marginX="sm">•</Text>
              </Text>
              <TextButton onClick={handleStopImpersonation} disabled={isStoppingImpersonation}>
                {isStoppingImpersonation ? 'Stopping...' : 'Stop impersonating'}
              </TextButton>
            </Flex>
          </BannerContent>
        </ImpersonationBanner>
      )}

      <Modal
        isOpen={isImpersonationModalOpen}
        shouldCloseOnOutsideClick={false}
        header={<Heading level={6}>Impersonation</Heading>}
        body={
          <ModalBody display="flex" flexDirection="column" justifyContent="space-between">
            {selectedOption?.employee ? (
              <div>
                <Heading level={6} marginBottom="sm">
                  Employee to impersonate:
                </Heading>
                <Flex display="flex" justifyContent="space-between">
                  {renderSearchOption(selectedOption.employee)}
                  <IconButton
                    buttonType="caution"
                    appearance="circle"
                    size="sm"
                    marginRight="sm"
                    title={'Reset employee to impersonate'}
                    icon={<IconClose />}
                    onClick={handleResetSelectedOption}
                    data-testid="impersonation-container-reset-selected"
                  />
                </Flex>
              </div>
            ) : (
              <Loader loading={!areImpersonateesLoaded}>
                <FormField
                  label={<Label>Whom do you want to impersonate?</Label>}
                  control={
                    isAllowedAll ? (
                      <Combobox
                        focusFirstOption
                        appearance="input"
                        autoCompleteBehavior="none"
                        renderInput={renderInput}
                        loading={isLoadingOptions}
                        options={searchInput.length ? searchOptions : []}
                        onOptionSelect={handleSelectOption}
                        data-testid="impersonation-container-combobox-allowed-all"
                      />
                    ) : (
                      <Combobox
                        options={allowedImpersonatees}
                        onOptionSelect={handleSelectOption}
                        data-testid="impersonation-container-combobox-allowed-selected"
                      />
                    )
                  }
                />
              </Loader>
            )}
            {startImpersonationErr !== ErrorType.NONE && (
              <Alert
                appearance="error"
                body={
                  startImpersonationErr === ErrorType.HTTP_STATUS_403
                    ? `Your request to impersonate ${selectedOption?.label} was not authorized`
                    : 'Something went wrong. Please try again later.'
                }
              />
            )}
          </ModalBody>
        }
        handleClose={handleCloseModal}
        footer={
          <LoadingButton
            buttonType="primary"
            disabled={!selectedOption}
            loading={isRequestingImpersonation}
            onClick={handleStartImpersonation}
            data-testid="impersonation-container-submit"
          >
            Go
          </LoadingButton>
        }
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isImpersonating: selectIsImpersonating(state),
  impersonator: selectImpersonator(state),
  impersonatee: selectUserEmployeeInfo(state),
});

const mapDispatchToProps = {
  searchEmployees: searchEmployees,
  impersonateUser: impersonateUser,
  stopImpersonation: stopImpersonation,
  getMyImpersonatees: getMyImpersonatees,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const ImpersonationContainer = connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(ImpersonationContainerBase);

export { ImpersonationContainer as default, ImpersonationContainerBase };
