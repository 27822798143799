import * as React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Heading, Paragraph, Spacer, IconChevronLeftOutline } from '@zillow/constellation';
import {
  BannerTextWrapper,
  MainWrapper,
  LeftPanel,
  RightPanel,
} from '../../containers/InsightsTwinsContainer';
import { RootState, Badge as BadgeType } from '../../store';
import { loadMyBadges, selectUserBadges } from '../../store/user';
import { BadgePagesLogic } from './BadgePages.hooks';
import { BadgeMembers } from './BadgeMembers';
import { AllBadges } from './AllBadges';
import { Banner } from '../../components/Banner';
import { Badge as BadgeIcon } from '../../components/Badge';
import { NoStyleLink } from '../../components/Links';
import DynamicConfig from '../../config/DynamicConfig';

export interface BadgePagesProps {
  /** user's own badges */
  myBadges: BadgeType[];
  loadMyBadges: () => Promise<void>;
}

const BadgePagesBase: React.FunctionComponent<BadgePagesProps> = (props: BadgePagesProps) => {
  const location = useLocation();
  const isAllBadgePage = location.pathname === '/badges' || location.pathname === '/badges/';
  const { myBadges } = props;
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  BadgePagesLogic(props);
  return (
    <>
      <Banner>
        <BannerTextWrapper flexDirection="column" alignItems="center">
          <Heading fontColor="textWhite" level="3">
            Zillow Group Badges
          </Heading>
          <Spacer margin="md" />
          <Paragraph fontColor="textWhite" fontType="body">
            Employees doing awesome stuff
          </Paragraph>
        </BannerTextWrapper>
      </Banner>
      <MainWrapper display="flex" flexWrap="wrap">
        <LeftPanel>
          {!isAllBadgePage && (
            <NoStyleLink to={`/badges`}>
              <Paragraph fontType="h6" fontColor="brand">
                <IconChevronLeftOutline /> All Badges
              </Paragraph>
            </NoStyleLink>
          )}
          <Heading level="4" marginY="sm">
            My Badges
          </Heading>
          {myBadges.map((badge, idx) => (
            <span
              key={idx}
              onClick={() => ReactGA.event({ category: 'Badges', action: 'Clicked Badge icon' })}
              data-testid={'my-badges-badge-icon'}
            >
              <BadgeIcon
                name={badge.name}
                iconUrl={`${ZALL_ENGINE_PROXY}${badge.iconUrl}?ts=${badge.updatedDate}`}
                link={`/badges/${badge.id}`}
              />
            </span>
          ))}
        </LeftPanel>
        <RightPanel>
          <Switch>
            <Route path="/badges/:badgeId" component={BadgeMembers} />
            <Route path="/badges" component={AllBadges} />
          </Switch>
        </RightPanel>
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  myBadges: selectUserBadges(state),
});

const mapDispatchToProps = {
  loadMyBadges: loadMyBadges,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const BadgePages = connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(BadgePagesBase);

export { BadgePages as default, BadgePagesBase };
