import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  token,
  spaceMixin,
  mediaBreakpointMixin,
  Flex,
  Heading,
  IconChevronDown,
  TextButton,
  Menu,
  MenuItem,
  MenuPopper,
} from '@zillow/constellation';
import { Searchbox } from '../../components/Searchbox';
import { AvatarDisk } from '../../components/AvatarDisk';
import { NoStyleLink } from '../../components/Links';
import { RootState } from '../../store';
import { searchEmployees, EmployeeFound } from '../../store/search';
import {
  selectLoggedIn,
  selectUserFullName,
  selectUserPhoto,
  selectIsImpersonationAllowed,
} from '../../store/user';
import { HeaderContainerLogic } from './HeaderContainer.hooks';
import DynamicConfig from '../../config/DynamicConfig';

export interface StateProps extends RouteComponentProps {
  /** is user logged in */
  loggedIn: boolean;
  /** person's full name */
  fullName: string;
  /** user's photo url */
  photoUrl?: string;
  /** is user allowed to impersonate */
  isImpersonationAllowed: boolean;
  /** search employees */
  searchEmployees: (keyword: string) => Promise<EmployeeFound[]>;
}

interface OwnProps {
  /** setter for is impersonation modal open */
  setIsImpersonationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export type HeaderContainerProps = StateProps & OwnProps;

const HeaderAndSuggestionBoxWrapper = styled.div`
  position: relative;
`;

const HeaderWrapper = styled.div`
  background-color: ${token('colors.backgroundDarkBlue')};
  position: relative;
  height: ${(props) => props.theme.size.headerHeight};
  z-index: 2; /* to be higher than suggestion box */
`;

const HeaderBar = styled(Flex)`
  @media ${mediaBreakpointMixin('xs')} {
    padding: ${spaceMixin('xs')} ${spaceMixin('sm')};
  }

  @media ${mediaBreakpointMixin('md')} {
    padding: ${spaceMixin('xs')} ${spaceMixin('md')};
  }

  @media ${mediaBreakpointMixin('lg')} {
    padding: ${spaceMixin('xs')} ${spaceMixin('xl')};
  }
`;

const ZallLogo = styled(Heading)`
  @media ${mediaBreakpointMixin('xs')} {
    font-size: 16px;
  }

  @media ${mediaBreakpointMixin('sm')} {
    font-size: 20px;
  }

  @media ${mediaBreakpointMixin('md')} {
    font-size: 28px;
  }

  @media ${mediaBreakpointMixin('lg')} {
    font-size: 44px;
  }
`;

const AvatarIcon = styled.div`
  @media ${mediaBreakpointMixin('xs')} {
    display: none;
  }
  @media ${mediaBreakpointMixin('md')} {
    margin-left: ${spaceMixin('sm')};
    display: block;
  }
`;

const HeaderContainerBase: React.FC<HeaderContainerProps> = (props: HeaderContainerProps) => {
  const { loggedIn, fullName, photoUrl, isImpersonationAllowed } = props;
  const {
    searchResults,
    handleSearchInputChange,
    handleSelectSearchOption,
    handleImpersonateClick,
  } = HeaderContainerLogic(props);
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();

  return (
    <HeaderAndSuggestionBoxWrapper>
      <HeaderWrapper>
        <HeaderBar display="flex" justifyContent="space-between">
          <Flex display="flex">
            <NoStyleLink to={'/'}>
              <ZallLogo level="2" fontColor="white" data-testid="header-container-header-logo">
                ZallWall
              </ZallLogo>
            </NoStyleLink>
          </Flex>

          {loggedIn && (
            <Flex display="flex" alignItems="center">
              <Searchbox
                searchResults={searchResults}
                onChange={handleSearchInputChange}
                onSelectResult={handleSelectSearchOption}
              />
              <AvatarIcon>
                <AvatarDisk
                  fullName={fullName}
                  photoUrl={photoUrl && `${ZALL_ENGINE_PROXY}${photoUrl}`}
                  size="md"
                  link={'/'}
                  gaCategory="Profile"
                  gaAction="Clicked on Header Avatar"
                />
              </AvatarIcon>
              {isImpersonationAllowed && (
                <MenuPopper
                  triggered={
                    <Menu>
                      <MenuItem onClick={handleImpersonateClick}>Impersonate</MenuItem>
                    </Menu>
                  }
                  offset={[-15, 25]}
                >
                  <TextButton
                    icon={
                      <IconChevronDown
                        fontColor="white"
                        aria-hidden="false"
                        aria-describedby="open-menu-title open-menu-desc"
                      >
                        <title id="open-menu-title">Open menu</title>
                        <desc id="open-menu-desc">Chevron pointing down</desc>
                      </IconChevronDown>
                    }
                    marginLeft="xs"
                  />
                </MenuPopper>
              )}
            </Flex>
          )}
        </HeaderBar>
      </HeaderWrapper>
    </HeaderAndSuggestionBoxWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  loggedIn: selectLoggedIn(state),
  fullName: selectUserFullName(state),
  photoUrl: selectUserPhoto(state),
  isImpersonationAllowed: selectIsImpersonationAllowed(state),
});

const mapDispatchToProps = {
  searchEmployees: searchEmployees,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const HeaderContainer = withRouter(
  connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
    mapStateToProps,
    mapDispatchToProps,
  )(HeaderContainerBase),
);

export { HeaderContainer as default, HeaderContainerBase };
