import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import axios from 'axios';
import { RpcImpl } from '../../contract/rpc';
import {
  GetMyImpersonateesRequest,
  GetMyImpersonateesResponse,
  ZallWebClientImpl,
} from '../../contract/zallengine_zallweb';
import {
  ImpersonationState,
  StartImpersonationMode,
  StopImpersonationAction,
  GetMyImpersonateesPromiseResponse,
} from './index';
import {
  validateGetMyImpersonateesResp,
  validateStartImpersonationResp,
  validateStopImpersonationResp,
} from './validators';
import { UserInfo } from '../user';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { GetDisplayError } from '../errorHelpers';
import DynamicConfig from '../../config/DynamicConfig';

export const impersonateUser = (
  employeeId: string,
): ThunkAction<Promise<void>, ImpersonationState, undefined, Action> => async (): Promise<void> => {
  const headers = {
    withCredentials: true,
  };
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const url = `${ZALL_ENGINE_PROXY}/impersonation/start?impersonatee_id=${employeeId}`;
  return axios
    .get(url, headers)
    .then((resp) => {
      const err = validateStartImpersonationResp(resp.data);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      const error = GetDisplayError(err);
      return Promise.reject(error);
    });
};

export const startImpersonationMode = (impersonator: UserInfo): StartImpersonationMode => ({
  type: 'START_IMPERSONATION_MODE',
  impersonator: impersonator,
});

export const stopImpersonationSuccess = (): StopImpersonationAction => ({
  type: 'STOP_IMPERSONATION_SUCCESS',
});

export const stopImpersonation = (): ThunkAction<
  Promise<void>,
  ImpersonationState,
  null | undefined,
  StopImpersonationAction
> => async (
  dispatch: ThunkDispatch<ImpersonationState, null | undefined, StopImpersonationAction>,
): Promise<void> => {
  const headers = {
    withCredentials: true,
  };
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const url = `${ZALL_ENGINE_PROXY}/impersonation/stop`;
  return axios
    .get(url, headers)
    .then((resp) => {
      const err = validateStopImpersonationResp(resp.data);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(stopImpersonationSuccess());
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      const error = GetDisplayError(err);
      return Promise.reject(error);
    });
};

export const getMyImpersonatees = (): ThunkAction<
  Promise<GetMyImpersonateesPromiseResponse>,
  ImpersonationState,
  undefined,
  Action
> => async (): Promise<GetMyImpersonateesPromiseResponse> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: GetMyImpersonateesRequest = {};

  return client
    .GetMyImpersonatees(req)
    .then((resp: GetMyImpersonateesResponse) => {
      const [isAllowedAll, impersonatees, err] = validateGetMyImpersonateesResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        return Promise.resolve({ isAllowedAll, impersonatees });
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      return Promise.reject(errResp);
    });
};
