import { useState, useCallback } from 'react';
import { KarmaContainerProps } from './KarmaContainer';

const parseCoreValueAndLeadershipBP = (raw: string): [string | undefined, string | undefined] => {
  if (!raw) return [undefined, undefined];
  const arr = raw.split(':');
  if (arr[0] === 'cv') {
    return [arr[1], undefined];
  } else if (arr[0] === 'lb') {
    return [undefined, arr[1]];
  }
  return [undefined, undefined];
};

export const KarmaContainerLogic = ({
  employeeId,
  giveKarma,
  fetchKarmas,
  deleteKarma,
}: KarmaContainerProps) => {
  const [isGivingKarma, setIsGivingKarma] = useState(false);
  const [numKarmaReceivedShow, setNumKarmaReceivedShow] = useState(3);
  const [numKarmaSentShow, setNumKarmaSentShow] = useState(0);
  const [karmaSubmissionText, setKarmaSubmissionText] = useState('Thank you for being awesome!');
  const [karmaSubmissionCVorLB, setKarmaSubmissionCVorLB] = useState('');

  const giveKarmasCallback = useCallback(() => {
    if (karmaSubmissionText) {
      const [coreValue, leadershipBP] = parseCoreValueAndLeadershipBP(karmaSubmissionCVorLB);
      giveKarma(employeeId, karmaSubmissionText, coreValue, leadershipBP);
    }
  }, [employeeId, karmaSubmissionText, karmaSubmissionCVorLB, giveKarma]);

  const fetchKarmasCallback = useCallback(
    (type: 'sent' | 'received', numLoaded: number) => {
      fetchKarmas(employeeId, type, `${numLoaded}`, '6');
    },
    [employeeId],
  );

  const deleteKarmaCallback = useCallback((karmaId: string) => {
    if (window.confirm('Are you sure you wish to delete this karma?')) {
      deleteKarma(karmaId);
    }
  }, []);

  return {
    isGivingKarma,
    setIsGivingKarma,
    numKarmaReceivedShow,
    setNumKarmaReceivedShow,
    numKarmaSentShow,
    setNumKarmaSentShow,
    karmaSubmissionText,
    setKarmaSubmissionText,
    karmaSubmissionCVorLB,
    setKarmaSubmissionCVorLB,
    giveKarmasCallback,
    fetchKarmasCallback,
    deleteKarmaCallback,
  };
};
