import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../store';
import { selectIsBadgeAdmin } from '../../store/user';
import { Banner } from '@zillow/constellation';
import BadgeReview from './Badges';

export interface AdminPagesProps extends RouteComponentProps {
  /** is badge admin */
  isBadgeAdmin: boolean;
}

const AdminPages: React.FunctionComponent<AdminPagesProps> = ({
  isBadgeAdmin,
}: AdminPagesProps) => {
  if (!isBadgeAdmin) {
    return <Banner appearance="warning" body={'User not authorized'} isOpen={true} />;
  }

  return (
    <Switch>
      <Route path="/admin/badges" component={BadgeReview} />
    </Switch>
  );
};

const mapStateToProps = (state: RootState) => ({
  isBadgeAdmin: selectIsBadgeAdmin(state),
});

const mapDispatchToProps = {};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

export default connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(AdminPages);
