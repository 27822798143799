import { createRef, useState, useEffect } from 'react';
import { BannerProps, BrandBanners } from './Banner';
import { AnimationUtils } from '../../utilities/animationutils';
import { DateUtils } from '../../utilities/dateutils';

export const BannerLogic = ({ brand }: BannerProps) => {
  const bannerRef = createRef<HTMLDivElement>();
  const [bannerType, setBannerType] = useState(BrandBanners.zillowgroup);
  const [animationActive, setAnimationActive] = useState(false);

  /** set banner type based on brand */
  useEffect(() => {
    if (brand) {
      const b = brand.replace(/[^A-Z]/gi, '').toLowerCase();

      if (b in BrandBanners) {
        setBannerType(BrandBanners[b]);
      }
    }
  }, []);

  const areSnowflakesEnabled = () => {
    const startDate = '12/01';
    const endDate = '01/31';
    return DateUtils.IsTodayInDateRange(startDate, endDate, true);
  };

  const areSpringFlowersEnabled = () => {
    const startDate = '02/28';
    const endDate = '05/31';
    return DateUtils.IsTodayInDateRange(startDate, endDate, false);
  };

  /** set animation */
  useEffect(() => {
    if (!bannerRef.current || animationActive) return;

    if (areSnowflakesEnabled()) {
      AnimationUtils.AddSnowflakes(bannerRef.current);
      setAnimationActive(true);
    } else if (areSpringFlowersEnabled()) {
      AnimationUtils.AddSpringFlowers(bannerRef.current);
      setAnimationActive(true);
    }
  }, [bannerRef]);

  return { bannerRef, bannerType };
};
