import React from 'react';
import styled from 'styled-components';
import {
  ClearFix,
  TextButton,
  IconAskQuestionOutline,
  Trigger,
  ModalDialog,
  Heading,
  ButtonGroup,
  Button
} from '@zillow/constellation';
import {FAQbody} from './FAQBody'


const faqModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }, close: () => void) => {
  return(<ModalDialog
            size={ModalDialog.SIZES.LG}
            isOpen={isOpen}
            onClose={onClose}
            renderHeader={<Heading level="6">Frequently Asked Questions</Heading>}
            renderBody={<FAQbody/>}
            renderFooter={ 
            <ButtonGroup aria-label="Modal actions">
              <Button buttonType="primary" onClick={close}>
                  Close
              </Button>
            </ButtonGroup>}
        />)
}


const FAQWrapper = styled(ClearFix)`
text-align: center;
margin-right: 80%;
display: inline-block;
`;

export const FAQ = () => {

  return (
    <FAQWrapper>
          <Trigger triggered={faqModal}>
          <TextButton fontColor="blue600" icon={<IconAskQuestionOutline />} iconPosition="end"> FAQ </TextButton>
          </Trigger> 
        </FAQWrapper>
  );
};
