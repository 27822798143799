import React from 'react';
import styled from 'styled-components';
import {
  Paragraph,
  fontSizeMixin,
  lineHeightMixin,
  mediaBreakpointMixin,
} from '@zillow/constellation';
import { PersonCard } from './PersonCard';

export interface ManagerCardProps {
  /** person's full name */
  fullName: string;
  /** person's username */
  username: string;
  /** person's job title */
  title: string;
  /** number of person's reportees */
  totalReportsCount: string;
  /** person's photo */
  photoUrl?: string;
}

const ManagerInfoContainer = styled.div`
  @media ${mediaBreakpointMixin('md')} {
    min-width: 230px;
  }
  @media ${mediaBreakpointMixin('lg')} {
    min-width: 250px;
  }
  max-width: 250px;
`;

const Text = styled(Paragraph)`
  font-size: ${fontSizeMixin('body')};
  line-height: ${lineHeightMixin('finePrint')};
`;

const Italic = styled(Text)`
  font-style: italic;
  font-size: ${fontSizeMixin('bodySmall')};
  padding-top: 5px;
`;

export const ManagerCard: React.FC<ManagerCardProps> = ({
  fullName,
  username,
  title,
  totalReportsCount,
  photoUrl,
}: ManagerCardProps) => {
  return (
    <div>
      <PersonCard
        data-testid="render-person-card-test"
        fullName={fullName}
        username={username}
        photoUrl={photoUrl}
        cardLabel={<Italic fontColor="textLight">MANAGER</Italic>}
        cardBody={
          <ManagerInfoContainer data-testid="render-manager-card-test">
            <Text>{title}</Text>
            <Italic fontColor="textLight">
              {totalReportsCount} {totalReportsCount === '1' ? 'person' : 'people'} on {fullName}
              &apos;s team
            </Italic>
          </ManagerInfoContainer>
        }
        gaCategory="Person Card"
        gaAction="Clicked on Manager Card"
      />
    </div>
  );
};
