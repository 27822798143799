import { useEffect, useCallback } from 'react';
import { ProfileContainerProps } from './ProfileContainer';
import { ErrorType } from '../../store/errorHelpers';

export const ProfileContainerLogic = ({
  loaded,
  username,
  history,
  loadWallDetail,
  resetWall,
}: ProfileContainerProps) => {
  const loadWallDetailCallback = useCallback(() => {
    loadWallDetail(username).catch((err) => {
      if (err.code && err.code === ErrorType.NOT_FOUND) {
        history.push('/notfound');
      }
    });
  }, [username, history, loadWallDetail]);

  useEffect(() => {
    if (!loaded) {
      loadWallDetailCallback();
    }
  }, [loaded]);

  /** reset wall when username changes */
  useEffect(() => {
    resetWall();
  }, [username]);
};
