import { useEffect, useCallback, useState, useMemo } from 'react';
import { InterestMembersContainerProps } from './InterestMembersContainer';
import { Interest as InterestType } from '../../store';

export const InterestMembersContainerLogic = ({
  myInfo,
  interestId,
  members,
  myInterests,
  loadInterestWallDetail,
  addToMyInterests,
  removeFromMyInterests,
}: InterestMembersContainerProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [memberList, setMemberList] = useState(members);

  const userHasInterest = useMemo(() => {
    return myInterests.some((i) => i.id === interestId);
  }, [myInterests, interestId]);

  const loadInterestWallDetailCallback = useCallback(
    (interestId: string): Promise<void> => {
      return loadInterestWallDetail(interestId);
    },
    [loadInterestWallDetail],
  );

  const addToMyInterestsCallback = useCallback(
    (interest: InterestType) => {
      if (interest) {
        addToMyInterests(interest).then(() => setMemberList([myInfo, ...memberList]));
      }
    },
    [addToMyInterests, myInfo, memberList],
  );

  const removeFromMyInterestsCallback = useCallback(
    (interest: InterestType) => {
      if (interest) {
        removeFromMyInterests(interest).then(() =>
          setMemberList(memberList.filter((member) => member.id !== myInfo.id)),
        );
      }
    },
    [removeFromMyInterests, myInfo, memberList],
  );

  useEffect(() => {
    if (interestId) {
      setIsLoading(true);
      loadInterestWallDetailCallback(interestId).then(() => setIsLoading(false));
    }
  }, [interestId]);

  useEffect(() => {
    if (members) {
      setMemberList(members);
    }
  }, [members]);

  return {
    isLoading,
    memberList,
    userHasInterest,
    addToMyInterestsCallback,
    removeFromMyInterestsCallback,
  };
};
