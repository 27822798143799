import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  ZallWebClientImpl,
  SearchEmployeesRequest,
  SearchEmployeesResponse,
} from '../contract/zallengine_zallweb';
import { RpcImpl } from '../contract/rpc';
import { EmployeeFound as EmployeeFoundContract } from '../contract/zallengine_employee';
import { StatusType } from '../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { ZallEngineErrorResponse, GetErrorStatusType } from './errorHelpers';
import DynamicConfig from '../config/DynamicConfig';

export type EmployeeFound = EmployeeFoundContract;

export const searchEmployees = (
  keyword: string,
): ThunkAction<Promise<EmployeeFound[]>, null, null, Action> => async (): Promise<
  EmployeeFound[]
> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: SearchEmployeesRequest = {
    keyword: keyword,
  };

  return client
    .SearchEmployees(req)
    .then((resp: SearchEmployeesResponse) => {
      const [result, err] = validateSearchEmployeesResp(resp);
      if (
        err.code === StatusType.STATUS_TYPE_SUCCESS ||
        err.code === StatusType.STATUS_TYPE_NOT_FOUND
      ) {
        return Promise.resolve(result);
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const validateSearchEmployeesResp = (
  resp: SearchEmployeesResponse,
): [EmployeeFound[], ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.employees || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.employees, errResp];
};
