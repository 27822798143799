import React from 'react';
import styled from 'styled-components';
import { Flex, Heading } from '@zillow/constellation';
import { Banner } from '../../components/Banner';

const BannerTextWrapper = styled(Flex)`
  text-align: center;
`;

export const NotFound: React.FunctionComponent = () => {
  return (
    <>
      <Banner>
        <BannerTextWrapper flexDirection="column" alignItems="center">
          <Heading fontColor="textWhite" level="3">
            Page Not Found
          </Heading>
        </BannerTextWrapper>
      </Banner>
    </>
  );
};
