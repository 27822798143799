import axios from 'axios';
// import { ZALLWEB_API_BASE_URL, GA_MEASUREMENT_ID } from './config.common';
import { ZALLWEB_API_BASE_URL } from './config.common';

const CONFIG_URL = `${ZALLWEB_API_BASE_URL}/configs`;
type Config = {
  ENV: string;
  DEBUG: boolean;

  AUTH_ENABLED: boolean;
  OKTA_OAUTH_URL: string;
  OKTA_CLIENT_ID: string;

  ZALL_ENGINE_HOST: string;
  ZALL_ENGINE_PROXY: string;

  GA_MEASUREMENT_ID: string;
  NEW_BADGE_GUIDELINES_URL: string;
  WORKDAY_PHOTO_CHANGE_URL: string;
  INSIGHTS_PRODUCT_URL: string;
  BADGE_MAKER_URL: string;
  LINKEDIN_DOMAIN: string;
  ZODIAC_DOMAIN: string;
  MAINTENANCE_MODE: boolean;

  INSIGHTS_URL: string;
  KARMA_URL: string;
  BADGE_REQUEST_URL: string;
  PPL_OPS_SERVICE_NOW_URL: string;
  CORPAPPS_SUPPORT_SLACK: string;
  PPL_OPS_INTAKE_FORM: string;
};

class DynamicConfig {
  InitConfig() {
    return axios.get(CONFIG_URL).then((response) => {
      const config: Config = response.data;
      localStorage.setItem('config', JSON.stringify(config));
      return config;
    });
  }

  GetConfig() {
    if (localStorage.getItem('config')) {
      const config = JSON.parse(localStorage.getItem('config') as string) as Config;
      return config;
    }
    // return { GA_MEASUREMENT_ID } as Config;
    return {} as Config;
  }
}

export default new DynamicConfig();
