import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Text,
  TextButton,
  ButtonGroup,
  FormField,
  Select,
  fontSizeMixin,
  Spacer,
} from '@zillow/constellation';
import { RootState, Insights as InsightsType } from '../../store';
import { selectWallOwnerInsights, selectWallOwnerUsername, updateInsights } from '../../store/wall';
import { InsightsImage, Block, Insights } from '../../components/Insights';
import { ZallWallLink } from '../../components/Links';
import { InsightsContainerLogic } from './InsightsContainer.hooks';
import { insightTypes } from '../../store/wallConfig';

interface StateProps {
  /** username of the wall owner */
  username: string;
  /** insights of the wall owner */
  insights: InsightsType;
  /** update insights callback */
  updateInsights: (insights: InsightsType) => Promise<void>;
}

const defaultInsight: InsightsType = {
  colors: 'g-r-y-b',
  isVisible: false,
  type: '',
};

interface OwnProps {
  /** if this is for current user */
  isCurrentUser: boolean;
}

export type InsightsContainerProps = StateProps & OwnProps;

const InsightWrapper = styled.div`
  text-align: center;
`;

const SelectForm = styled(Select)`
  width: 130px;
  padding: 3px 8px;
  font-size: ${fontSizeMixin('bodySmall')};
`;

const InsightsContainerBase: React.FC<InsightsContainerProps> = (props: InsightsContainerProps) => {
  const { username } = props;

  const {
    show,
    setShow,
    insightsList,
    selectedInsightType,
    setSelectedInsightType,
    saveInsights,
    handleDragStart,
    handleDragEnter,
  } = InsightsContainerLogic(props);

  const [editing, setEditing] = useState(false);

  const insightsType =
    editing || selectedInsightType ? (
      <>
        <Text fontType="h6" as="p" marginTop="sm">
          MY INSIGHTS TYPE:
        </Text>
        {editing ? (
          <FormField
            control={
              <SelectForm
                data-testid="insight-type-select"
                value={selectedInsightType}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setSelectedInsightType(e.target.value)
                }
              >
                <option value="">Select one</option>
                {insightTypes.map((type, i) => (
                  <option value={type} key={i}>
                    {type}
                  </option>
                ))}
              </SelectForm>
            }
          />
        ) : (
          <Text data-testid="insight-type-text">{selectedInsightType}</Text>
        )}
      </>
    ) : null;

  return (
    <InsightWrapper>
      {show && (
        <>
          <Text fontType="h6" as="p">
            MY INSIGHTS ORDER:
          </Text>
          <ZallWallLink to={`/insights/twins/${username}`}>See my Insight twins</ZallWallLink>
          <Spacer marginTop="xs"></Spacer>
          {editing ? (
            <>
              {insightsList.map((color, index) => (
                <Block
                  data-testid="insight-type-block"
                  editMode={true}
                  onDragStart={(e) => {
                    if (!editing) e.preventDefault();
                    else handleDragStart(index);
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDragEnter={() => handleDragEnter(index)}
                  key={index}
                >
                  <img src={InsightsImage(color)}></img>
                </Block>
              ))}
              <Text data-testid="insight-type-drag-text" fontType="bodySmall">
                Drag to reorder blocks
              </Text>
            </>
          ) : (
            <Insights blocks={insightsList} clickable />
          )}
          {insightsType}
        </>
      )}

      {props.isCurrentUser && (
        <ButtonGroup direction="column" aria-label="insights controls" marginTop="sm">
          {show && (
            <TextButton
              fontType="bodySmall"
              onClick={() => {
                if (editing) saveInsights();
                setEditing(!editing);
              }}
            >
              {editing ? 'Save' : 'Edit Insights'}
            </TextButton>
          )}
          {!editing && (
            <TextButton fontType="bodySmall" onClick={() => setShow(!show)}>
              Toggle Showing Insights
            </TextButton>
          )}
        </ButtonGroup>
      )}
    </InsightWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  username: selectWallOwnerUsername(state),
  insights: selectWallOwnerInsights(state) || defaultInsight,
});

const mapDispatchToProps = {
  updateInsights: updateInsights,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const InsightsContainer = connect<StateToPropsType, DispatchToPropsType, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(InsightsContainerBase);

export { InsightsContainer as default, InsightsContainerBase };
