import React from 'react';
import {
    Paragraph,
    TextButton,
    Text
  } from '@zillow/constellation';
import styled from 'styled-components';
import { FAQBodyProps } from "./FAQBody";
import DynamicConfig from '../../config/DynamicConfig'; 

const ResponseParagraph = styled(Paragraph)`
margin-bottom: 12px;`

const urlText = (url: string, text: string) => {
    return(
    <TextButton fontType="body" onClick={()=> window.open(url, "_blank")}> 
      {text}
    </TextButton >)
  }

const { KARMA_URL, INSIGHTS_URL, BADGE_REQUEST_URL, NEW_BADGE_GUIDELINES_URL } = DynamicConfig.GetConfig();
const { PPL_OPS_INTAKE_FORM, PPL_OPS_SERVICE_NOW_URL, CORPAPPS_SUPPORT_SLACK } = DynamicConfig.GetConfig();

export const QAs: FAQBodyProps[] = [
    {
       question: "Q: Why do I see snowflakes or spring flowers in the banner?",
       answer: <ResponseParagraph>A: From December 1st  to January 31st, we showcase cascading snowflakes as an ode to the cold winter season. 
       From February 28th to May 31st, we showcase blooming spring flowers to welcome the coming of Spring!</ResponseParagraph> 
    },
    {
        question: "Q: How is the pie chart of 'employees starting before/after' calculated?",
        answer: <ResponseParagraph>A: For the &lsquo;before&rsquo; percentage, we take the current number of employees before your start date to the total 
        current number of employees. For the after, we take the current number of employees after your start date to the total current number of 
        employees. This metric is updated every time New Hires join Zillow. That way, you can see your own evolution at Zillow over time!</ResponseParagraph>
    },
    {
        question: "Q: Where does Zallwall obtain its employee data?",
        answer: <ResponseParagraph>A: PeopleTech’s ZG Employee API provides all formal employee data, indirectly mirroring Workday with limited 
        data. Informal employee data is provided by employee input as they edit their profiles to connect with Zall.</ResponseParagraph>
    },
    {
        question: "Q: Where can I find more details about Insights?",
        answer:<ResponseParagraph>A: By clicking any of your Insight Bricks on the left-hand side (on Desktop) of your profile, you will see 
        not only a summary of what the specific insight represents but also, how many employees at Zillow have that specific insight as their 
        top block. We call these, Insight Leaders! For more information on Insights, {urlText(INSIGHTS_URL, " check out Zallway")}.</ResponseParagraph>
    },
    {
        question:"Q: How many interests can I add to my profile?",
        answer: <ResponseParagraph>A: Users can add as many interests as they’d like! There’s no limit to Zall’s authentic selves.</ResponseParagraph>
    },
    {
        question: "Q: What is Karma? How’s it used?",
        answer:  <ResponseParagraph>A: Karma is a message you post to someone&apos;s ZallWall profile! They&apos;re editable within 24 hours of their creation. 
        For details on how Zall uses Karma to empower employees and how you can give it, {urlText(KARMA_URL, " check out Zallway")}.</ResponseParagraph>
    },
    {
        question: "Q: What are Badges? How do we use them at Zillow? ",
        answer: <ResponseParagraph>A: Badges on ZallWall profiles indicate accomplishments, participation in certain events, or membership in groups/networks 
        at Zillow. For details on how to request an existing badge, see {urlText(BADGE_REQUEST_URL, "Zallway’s tutorial here")}. Need a badge 
        that doesn’t exist? See {urlText(NEW_BADGE_GUIDELINES_URL, " Zallway for details")} on 
        how to create and assign one!</ResponseParagraph>
    },
    {
        question: "Q: Can I edit a badge? ",
        answer: <ResponseParagraph>A: ZallWall, by product design, allows users to edit badges to give people power with the Core Value <Text style={{fontWeight: 'bold'}}>Do the Right Thing</Text> in mind. Even 
        though there are no restrictions to badge editing, we keep track of what and who makes changes to badges in the ZallWall database. To edit a badge, 
        find and click the one that requires editing in the “Badge Library”, then click the “Edit this Badge” button on the right-hand side of the screen (Desktop).</ResponseParagraph>
    },
    {
        question: "Q: How can I generate a Karma report?",
        answer:  <ResponseParagraph>A: This is limited to specific roles at Zillow, but can be done through a ServiceNow request to People 
        Operations, {urlText(PPL_OPS_SERVICE_NOW_URL, "here")}.</ResponseParagraph>
    },
    {
        question: "Q: What should I do if I encounter a bug?",
        answer: <ResponseParagraph>A: Users have the option to submit a {urlText(PPL_OPS_SERVICE_NOW_URL, "ServiceNow ticket to People Operations")} or message our 
        development team at {urlText(CORPAPPS_SUPPORT_SLACK, " #corpapps-engineering-support ")} on Slack!</ResponseParagraph>
    },
    {
        question: "Q: Who should I go to for Enhancement Requests? ",
        answer:  <ResponseParagraph>A: Reach out to the People Product team via the {urlText(PPL_OPS_INTAKE_FORM, "intake form here")}.</ResponseParagraph>
    }
]