import React from 'react';
import styled from 'styled-components';
import {
  Flex,
  Heading,
  Alert,
  LoadingButton,
  FormField,
  Label,
  Input,
  Textarea,
  FormHelp,
  Form,
} from '@zillow/constellation';
import { Controller } from 'react-hook-form';
import { Modal } from '../../components/Modal';
import { EditBadgeDetailsLogic } from './EditBadgeDetails.hooks';
import {
  validateFileDimensions,
  validateFileSize,
} from '../BadgeRequestContainer/BadgeRequestContainer.hooks';
import { Badge as BadgeType } from '../../store';

export interface EditBadgeDetailsModalProps {
  /** is edit badge details modal open */
  isModalOpen: boolean;
  /** setter for is edit badge modal open */
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /** badge detail */
  badge: BadgeType;
  /** update badge detail */
  updateBadgeDetail: (
    id: string,
    name: string,
    description: string,
    iconImage: Uint8Array,
  ) => Promise<void>;
}

const ModalContent = styled(Flex)`
  min-height: 100px;
`;

export const EditBadgeDetailsModal: React.FC<EditBadgeDetailsModalProps> = (
  props: EditBadgeDetailsModalProps,
) => {
  const {
    isModalOpen,
    setIsModalOpen,
    register,
    handleSubmit,
    control,
    errors,
    isDirty,
    isSaving,
    saveError,
    saveBadgeDetails,
  } = EditBadgeDetailsLogic(props);

  return (
    <Modal
      header={<Heading level={6}>Edit badge details</Heading>}
      body={
        <ModalContent padding="sm">
          <Form>
            <FormField
              required
              label={<Label>Badge name</Label>}
              control={
                <Input
                  {...register('name', { required: 'Please enter a badge name' })}
                  data-testid="badge-members-container-edit-name"
                />
              }
              error={errors.name?.message !== undefined}
              description={<FormHelp>{errors.name?.message}</FormHelp>}
            />
            <FormField
              required
              label={<Label>Badge description</Label>}
              control={
                <Textarea
                  {...register('desc', { required: 'Please enter the badge description' })}
                />
              }
              error={errors.desc?.message !== undefined}
              description={<FormHelp>{errors.desc?.message}</FormHelp>}
            />
            <FormField
              label={<Label>Replace badge icon</Label>}
              control={
                <Controller
                  name="icon"
                  control={control}
                  rules={{
                    validate: {
                      fileDimensions: async (v) =>
                        v
                          ? (await validateFileDimensions(v)) || 'Badge icon must be 500x500 px'
                          : true,
                      fileSize: (v) =>
                        v && v ? validateFileSize(v) || 'Badge icon should not exceed 200KB' : true,
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      type="file"
                      accept="image/png"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files && e.target.files.length > 0)
                          field.onChange(e.target.files[0]);
                      }}
                    />
                  )}
                />
              }
              error={errors.icon?.message !== undefined}
              description={
                <FormHelp>
                  {errors.icon?.message
                    ? errors.icon.message
                    : 'Badge icons must be a 500x500 px PNG file and have a max size of 200KB'}
                </FormHelp>
              }
            />

            {saveError && (
              <Alert appearance="error" body="Something went wrong. Please try again later." />
            )}
          </Form>
        </ModalContent>
      }
      footer={
        <LoadingButton
          buttonType="primary"
          onClick={handleSubmit(saveBadgeDetails)}
          disabled={!isDirty}
          loading={isSaving}
          data-testid="badge-members-container-save"
        >
          Save
        </LoadingButton>
      }
      isOpen={isModalOpen}
      handleClose={() => setIsModalOpen(false)}
      shouldCloseOnOutsideClick={false}
      size={'xs'}
    />
  );
};
