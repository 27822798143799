import { useState, useRef, useEffect } from 'react';
import { useCallback } from 'react';
import { InsightsContainerProps } from './InsightsContainer';

export const InsightsContainerLogic = ({ insights, updateInsights }: InsightsContainerProps) => {
  // insights undefined has been checked in container
  const [show, setShow] = useState(insights.isVisible);
  const [selectedInsightType, setSelectedInsightType] = useState(insights.type);
  const [insightsList, setInsightsList] = useState(insights.colors.split('-'));
  const didMountRef = useRef(false);

  const getInsightsString = useCallback(() => {
    return insightsList.join('-');
  }, [insightsList]);

  const updateInsightsCallback = useCallback(() => {
    updateInsights({
      colors: getInsightsString(),
      type: selectedInsightType,
      isVisible: show,
    });
  }, [getInsightsString, selectedInsightType, show, updateInsights]);

  /** save insights if insight colors or type changed */
  const saveInsights = useCallback(() => {
    const insightsString = getInsightsString();

    // eslint-disable-next-line
    if (insights!.type !== selectedInsightType || insights!.colors !== insightsString) {
      updateInsightsCallback();
    }
  }, [getInsightsString, selectedInsightType, updateInsightsCallback]);

  /** update insights when show is toggled */
  useEffect(() => {
    if (didMountRef.current) {
      updateInsightsCallback();
    } else {
      didMountRef.current = true;
    }
  }, [show]);

  const draggedItemIdx = useRef(-1);

  const handleDragStart = (position: number) => {
    draggedItemIdx.current = position;
  };

  const handleDragEnter = (position: number) => {
    const listCopy = [...insightsList];
    const draggedItemContent = listCopy[draggedItemIdx.current];
    listCopy.splice(draggedItemIdx.current, 1);
    listCopy.splice(position, 0, draggedItemContent);

    draggedItemIdx.current = position;
    setInsightsList(listCopy);
  };

  return {
    show,
    setShow,
    insightsList,
    selectedInsightType,
    setSelectedInsightType,
    saveInsights,
    handleDragStart,
    handleDragEnter,
  };
};
