/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Employee } from './zallengine_employee';
import { WorkInfo } from './zallengine_employee_work_info';
import { Karma } from './zallengine_karma';
import { PersonalInfo } from './zallengine_personal_info';

export const protobufPackage = 'zallengine_contract';

export interface WallDetail {
  employee: Employee | undefined;
  /** work related information such start date, working arrangement ... */
  workInfo: WorkInfo | undefined;
  /** personal info such as pronouns, fun facts, badges ... */
  personalInfo: PersonalInfo | undefined;
  manager: Employee | undefined;
  assistant: Employee | undefined;
  reports: Employee[];
  recentReceivedKarmas: Karma[];
  totalReceivedKarmasCount: string;
  totalSentKarmasCount: string;
  /** true when employee and current user are the same person */
  isCurrentUser: boolean;
  /** execs that the employee supports */
  assistantTo: Employee[];
}

function createBaseWallDetail(): WallDetail {
  return {
    employee: undefined,
    workInfo: undefined,
    personalInfo: undefined,
    manager: undefined,
    assistant: undefined,
    reports: [],
    recentReceivedKarmas: [],
    totalReceivedKarmasCount: '0',
    totalSentKarmasCount: '0',
    isCurrentUser: false,
    assistantTo: [],
  };
}

export const WallDetail = {
  encode(message: WallDetail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.employee !== undefined) {
      Employee.encode(message.employee, writer.uint32(10).fork()).ldelim();
    }
    if (message.workInfo !== undefined) {
      WorkInfo.encode(message.workInfo, writer.uint32(18).fork()).ldelim();
    }
    if (message.personalInfo !== undefined) {
      PersonalInfo.encode(message.personalInfo, writer.uint32(26).fork()).ldelim();
    }
    if (message.manager !== undefined) {
      Employee.encode(message.manager, writer.uint32(34).fork()).ldelim();
    }
    if (message.assistant !== undefined) {
      Employee.encode(message.assistant, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.reports) {
      Employee.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.recentReceivedKarmas) {
      Karma.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.totalReceivedKarmasCount !== '0') {
      writer.uint32(64).int64(message.totalReceivedKarmasCount);
    }
    if (message.totalSentKarmasCount !== '0') {
      writer.uint32(72).int64(message.totalSentKarmasCount);
    }
    if (message.isCurrentUser === true) {
      writer.uint32(80).bool(message.isCurrentUser);
    }
    for (const v of message.assistantTo) {
      Employee.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WallDetail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWallDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.employee = Employee.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.workInfo = WorkInfo.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.personalInfo = PersonalInfo.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.manager = Employee.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.assistant = Employee.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.reports.push(Employee.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.recentReceivedKarmas.push(Karma.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.totalReceivedKarmasCount = longToString(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.totalSentKarmasCount = longToString(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isCurrentUser = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.assistantTo.push(Employee.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WallDetail {
    return {
      employee: isSet(object.employee) ? Employee.fromJSON(object.employee) : undefined,
      workInfo: isSet(object.workInfo) ? WorkInfo.fromJSON(object.workInfo) : undefined,
      personalInfo: isSet(object.personalInfo)
        ? PersonalInfo.fromJSON(object.personalInfo)
        : undefined,
      manager: isSet(object.manager) ? Employee.fromJSON(object.manager) : undefined,
      assistant: isSet(object.assistant) ? Employee.fromJSON(object.assistant) : undefined,
      reports: globalThis.Array.isArray(object?.reports)
        ? object.reports.map((e: any) => Employee.fromJSON(e))
        : [],
      recentReceivedKarmas: globalThis.Array.isArray(object?.recentReceivedKarmas)
        ? object.recentReceivedKarmas.map((e: any) => Karma.fromJSON(e))
        : [],
      totalReceivedKarmasCount: isSet(object.totalReceivedKarmasCount)
        ? globalThis.String(object.totalReceivedKarmasCount)
        : '0',
      totalSentKarmasCount: isSet(object.totalSentKarmasCount)
        ? globalThis.String(object.totalSentKarmasCount)
        : '0',
      isCurrentUser: isSet(object.isCurrentUser) ? globalThis.Boolean(object.isCurrentUser) : false,
      assistantTo: globalThis.Array.isArray(object?.assistantTo)
        ? object.assistantTo.map((e: any) => Employee.fromJSON(e))
        : [],
    };
  },

  toJSON(message: WallDetail): unknown {
    const obj: any = {};
    if (message.employee !== undefined) {
      obj.employee = Employee.toJSON(message.employee);
    }
    if (message.workInfo !== undefined) {
      obj.workInfo = WorkInfo.toJSON(message.workInfo);
    }
    if (message.personalInfo !== undefined) {
      obj.personalInfo = PersonalInfo.toJSON(message.personalInfo);
    }
    if (message.manager !== undefined) {
      obj.manager = Employee.toJSON(message.manager);
    }
    if (message.assistant !== undefined) {
      obj.assistant = Employee.toJSON(message.assistant);
    }
    if (message.reports?.length) {
      obj.reports = message.reports.map((e) => Employee.toJSON(e));
    }
    if (message.recentReceivedKarmas?.length) {
      obj.recentReceivedKarmas = message.recentReceivedKarmas.map((e) => Karma.toJSON(e));
    }
    if (message.totalReceivedKarmasCount !== '0') {
      obj.totalReceivedKarmasCount = message.totalReceivedKarmasCount;
    }
    if (message.totalSentKarmasCount !== '0') {
      obj.totalSentKarmasCount = message.totalSentKarmasCount;
    }
    if (message.isCurrentUser === true) {
      obj.isCurrentUser = message.isCurrentUser;
    }
    if (message.assistantTo?.length) {
      obj.assistantTo = message.assistantTo.map((e) => Employee.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WallDetail>, I>>(base?: I): WallDetail {
    return WallDetail.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WallDetail>, I>>(object: I): WallDetail {
    const message = createBaseWallDetail();
    message.employee =
      object.employee !== undefined && object.employee !== null
        ? Employee.fromPartial(object.employee)
        : undefined;
    message.workInfo =
      object.workInfo !== undefined && object.workInfo !== null
        ? WorkInfo.fromPartial(object.workInfo)
        : undefined;
    message.personalInfo =
      object.personalInfo !== undefined && object.personalInfo !== null
        ? PersonalInfo.fromPartial(object.personalInfo)
        : undefined;
    message.manager =
      object.manager !== undefined && object.manager !== null
        ? Employee.fromPartial(object.manager)
        : undefined;
    message.assistant =
      object.assistant !== undefined && object.assistant !== null
        ? Employee.fromPartial(object.assistant)
        : undefined;
    message.reports = object.reports?.map((e) => Employee.fromPartial(e)) || [];
    message.recentReceivedKarmas =
      object.recentReceivedKarmas?.map((e) => Karma.fromPartial(e)) || [];
    message.totalReceivedKarmasCount = object.totalReceivedKarmasCount ?? '0';
    message.totalSentKarmasCount = object.totalSentKarmasCount ?? '0';
    message.isCurrentUser = object.isCurrentUser ?? false;
    message.assistantTo = object.assistantTo?.map((e) => Employee.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
