import React from 'react';
import { useParams } from 'react-router-dom';

import ProfileContainer from '../../containers/ProfileContainer';

export const Profile: React.FunctionComponent = () => {
  const { username } = useParams<{ username: string }>();

  return <ProfileContainer username={username || ''} />;
};
