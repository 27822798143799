import { useState } from 'react';
import ReactGA from 'react-ga4';
import { NumberFormatValues } from 'react-number-format';
import { PhoneNumberFieldProps } from './PhoneNumberField';

export const PhoneNumberFieldLogic = ({ phoneNumber, onSave }: PhoneNumberFieldProps) => {
  const [inputValue, setInputValue] = useState(phoneNumber || '');
  const [isInputValid, setIsInputValid] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const validateInput = (phoneNumber: string) => {
    setIsInputValid(phoneNumber.length === 10 || phoneNumber.length === 0);
  };

  const onInputChange = (values: NumberFormatValues) => {
    validateInput(values.value);
    setInputValue(values.formattedValue);
  };

  const handleSave = () => {
    if (onSave && inputValue !== (phoneNumber || '')) {
      onSave(inputValue);
    }
  };

  const handleButtonClick = () => {
    if (!isEditing) ReactGA.event({ category: 'Profile', action: 'Edit Phone Number' });

    if (isEditing) {
      handleSave();
    }
    setIsEditing(!isEditing);
  };

  return {
    inputValue,
    isInputValid,
    isEditing,
    onInputChange,
    handleButtonClick,
    setIsEditing,
  };
};
