import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ConditionalWrapper } from '../ConditionalWrapper';
import greenBlockImage from './g.png';
import redBlockImage from './r.png';
import yellowBlockImage from './y.png';
import blueBlockImage from './b.png';

export const InsightsImage = (color: string): string | undefined => {
  switch (color) {
    case 'r':
      return redBlockImage;
    case 'g':
      return greenBlockImage;
    case 'b':
      return blueBlockImage;
    case 'y':
      return yellowBlockImage;
  }
  return undefined;
};

export interface InsightsProps {
  /** list of block images */
  blocks: string[];
  /** clickable nav or not  */
  clickable?: boolean;
  /** space between blocks */
  space?: string;
  /** is in editMode */
  editMode?: boolean;
}

const InsightWrapper = styled.div`
  text-align: center;
`;

export const Block = styled.div<{ space?: string; editMode?: boolean }>`
  margin: 0 auto;
  width: fit-content;
  margin-bottom: ${(props) => props.space};
  cursor: ${(props) => (props.editMode ? 'move' : 'inherit')};
`;

export const Insights: React.FC<InsightsProps> & {
  defaultProps: Partial<InsightsProps>;
} = ({ blocks, clickable, space, editMode }: InsightsProps) => {
  return (
    <InsightWrapper data-testid='render-insights-test'>
      {blocks &&
        blocks.map((color, i) => (
          <ConditionalWrapper 
            key={i}
            condition={clickable && !editMode}
            wrapper={(children) => <Link data-testid='render-link-test' to={`/insights/${color}`}>{children}</Link>}
          >
            <Block data-testid='render-block-test' space={space}>
              <img alt={`Insights ${color} block`} src={InsightsImage(color)}></img>
            </Block>
          </ConditionalWrapper>
        ))}
    </InsightWrapper>
  );
};

Insights.defaultProps = {
  blocks: ['g', 'r', 'b', 'y'],
};
