/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Timestamp } from './google/protobuf/timestamp';
import { StringValue } from './google/protobuf/wrappers';

export const protobufPackage = 'zallengine_contract';

export interface WorkInfo {
  brand: string | undefined;
  businessUnit: string | undefined;
  companyEntity: string | undefined;
  /** FTE, PTE, null */
  employeeType: string | undefined;
  /** Employee, Contingent Worker, null */
  workerType: string | undefined;
  /** Full time, Part time, null */
  positionTimeType: string | undefined;
  /** Office, Field, Remote, Hybrid, null */
  jobDesignation: string | undefined;
  /** Office, Field, Remote, Hybrid, null */
  workingArrangement: string | undefined;
  hireDate: Date | undefined;
  terminationDate: Date | undefined;
  contactNumber: WorkInfo_ContactNumber | undefined;
  office: WorkInfo_Office | undefined;
  tenure: WorkInfo_Tenure | undefined;
  workerLocationTimezone: string | undefined;
  workAddress: WorkInfo_WorkAddress | undefined;
}

export interface WorkInfo_ContactNumber {
  officePhone: string;
  mobilePhone: string;
  extPhone: string;
}

export interface WorkInfo_Office {
  location: string | undefined;
  floorDesk: string | undefined;
  city: string | undefined;
  state: string | undefined;
  country: string | undefined;
  postalCode: string | undefined;
}

export interface WorkInfo_Tenure {
  /** @deprecated */
  beforePercentage: number;
  /** @deprecated */
  afterPercentage: number;
  beforeCount: string;
  afterCount: string;
}

export interface WorkInfo_WorkAddress {
  city: string | undefined;
  state: string | undefined;
  country: string | undefined;
}

function createBaseWorkInfo(): WorkInfo {
  return {
    brand: undefined,
    businessUnit: undefined,
    companyEntity: undefined,
    employeeType: undefined,
    workerType: undefined,
    positionTimeType: undefined,
    jobDesignation: undefined,
    workingArrangement: undefined,
    hireDate: undefined,
    terminationDate: undefined,
    contactNumber: undefined,
    office: undefined,
    tenure: undefined,
    workerLocationTimezone: undefined,
    workAddress: undefined,
  };
}

export const WorkInfo = {
  encode(message: WorkInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brand !== undefined) {
      StringValue.encode({ value: message.brand! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.businessUnit !== undefined) {
      StringValue.encode({ value: message.businessUnit! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.companyEntity !== undefined) {
      StringValue.encode({ value: message.companyEntity! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.employeeType !== undefined) {
      StringValue.encode({ value: message.employeeType! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.workerType !== undefined) {
      StringValue.encode({ value: message.workerType! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.positionTimeType !== undefined) {
      StringValue.encode({ value: message.positionTimeType! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.jobDesignation !== undefined) {
      StringValue.encode({ value: message.jobDesignation! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.workingArrangement !== undefined) {
      StringValue.encode({ value: message.workingArrangement! }, writer.uint32(66).fork()).ldelim();
    }
    if (message.hireDate !== undefined) {
      Timestamp.encode(toTimestamp(message.hireDate), writer.uint32(74).fork()).ldelim();
    }
    if (message.terminationDate !== undefined) {
      Timestamp.encode(toTimestamp(message.terminationDate), writer.uint32(82).fork()).ldelim();
    }
    if (message.contactNumber !== undefined) {
      WorkInfo_ContactNumber.encode(message.contactNumber, writer.uint32(90).fork()).ldelim();
    }
    if (message.office !== undefined) {
      WorkInfo_Office.encode(message.office, writer.uint32(98).fork()).ldelim();
    }
    if (message.tenure !== undefined) {
      WorkInfo_Tenure.encode(message.tenure, writer.uint32(106).fork()).ldelim();
    }
    if (message.workerLocationTimezone !== undefined) {
      StringValue.encode(
        { value: message.workerLocationTimezone! },
        writer.uint32(114).fork(),
      ).ldelim();
    }
    if (message.workAddress !== undefined) {
      WorkInfo_WorkAddress.encode(message.workAddress, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brand = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.businessUnit = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.companyEntity = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.employeeType = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.workerType = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.positionTimeType = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.jobDesignation = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.workingArrangement = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.hireDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.terminationDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.contactNumber = WorkInfo_ContactNumber.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.office = WorkInfo_Office.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.tenure = WorkInfo_Tenure.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.workerLocationTimezone = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.workAddress = WorkInfo_WorkAddress.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkInfo {
    return {
      brand: isSet(object.brand) ? String(object.brand) : undefined,
      businessUnit: isSet(object.businessUnit) ? String(object.businessUnit) : undefined,
      companyEntity: isSet(object.companyEntity) ? String(object.companyEntity) : undefined,
      employeeType: isSet(object.employeeType) ? String(object.employeeType) : undefined,
      workerType: isSet(object.workerType) ? String(object.workerType) : undefined,
      positionTimeType: isSet(object.positionTimeType)
        ? String(object.positionTimeType)
        : undefined,
      jobDesignation: isSet(object.jobDesignation) ? String(object.jobDesignation) : undefined,
      workingArrangement: isSet(object.workingArrangement)
        ? String(object.workingArrangement)
        : undefined,
      hireDate: isSet(object.hireDate) ? fromJsonTimestamp(object.hireDate) : undefined,
      terminationDate: isSet(object.terminationDate)
        ? fromJsonTimestamp(object.terminationDate)
        : undefined,
      contactNumber: isSet(object.contactNumber)
        ? WorkInfo_ContactNumber.fromJSON(object.contactNumber)
        : undefined,
      office: isSet(object.office) ? WorkInfo_Office.fromJSON(object.office) : undefined,
      tenure: isSet(object.tenure) ? WorkInfo_Tenure.fromJSON(object.tenure) : undefined,
      workerLocationTimezone: isSet(object.workerLocationTimezone)
        ? String(object.workerLocationTimezone)
        : undefined,
      workAddress: isSet(object.workAddress)
        ? WorkInfo_WorkAddress.fromJSON(object.workAddress)
        : undefined,
    };
  },

  toJSON(message: WorkInfo): unknown {
    const obj: any = {};
    if (message.brand !== undefined) {
      obj.brand = message.brand;
    }
    if (message.businessUnit !== undefined) {
      obj.businessUnit = message.businessUnit;
    }
    if (message.companyEntity !== undefined) {
      obj.companyEntity = message.companyEntity;
    }
    if (message.employeeType !== undefined) {
      obj.employeeType = message.employeeType;
    }
    if (message.workerType !== undefined) {
      obj.workerType = message.workerType;
    }
    if (message.positionTimeType !== undefined) {
      obj.positionTimeType = message.positionTimeType;
    }
    if (message.jobDesignation !== undefined) {
      obj.jobDesignation = message.jobDesignation;
    }
    if (message.workingArrangement !== undefined) {
      obj.workingArrangement = message.workingArrangement;
    }
    if (message.hireDate !== undefined) {
      obj.hireDate = message.hireDate.toISOString();
    }
    if (message.terminationDate !== undefined) {
      obj.terminationDate = message.terminationDate.toISOString();
    }
    if (message.contactNumber !== undefined) {
      obj.contactNumber = WorkInfo_ContactNumber.toJSON(message.contactNumber);
    }
    if (message.office !== undefined) {
      obj.office = WorkInfo_Office.toJSON(message.office);
    }
    if (message.tenure !== undefined) {
      obj.tenure = WorkInfo_Tenure.toJSON(message.tenure);
    }
    if (message.workerLocationTimezone !== undefined) {
      obj.workerLocationTimezone = message.workerLocationTimezone;
    }
    if (message.workAddress !== undefined) {
      obj.workAddress = WorkInfo_WorkAddress.toJSON(message.workAddress);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkInfo>, I>>(base?: I): WorkInfo {
    return WorkInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkInfo>, I>>(object: I): WorkInfo {
    const message = createBaseWorkInfo();
    message.brand = object.brand ?? undefined;
    message.businessUnit = object.businessUnit ?? undefined;
    message.companyEntity = object.companyEntity ?? undefined;
    message.employeeType = object.employeeType ?? undefined;
    message.workerType = object.workerType ?? undefined;
    message.positionTimeType = object.positionTimeType ?? undefined;
    message.jobDesignation = object.jobDesignation ?? undefined;
    message.workingArrangement = object.workingArrangement ?? undefined;
    message.hireDate = object.hireDate ?? undefined;
    message.terminationDate = object.terminationDate ?? undefined;
    message.contactNumber =
      object.contactNumber !== undefined && object.contactNumber !== null
        ? WorkInfo_ContactNumber.fromPartial(object.contactNumber)
        : undefined;
    message.office =
      object.office !== undefined && object.office !== null
        ? WorkInfo_Office.fromPartial(object.office)
        : undefined;
    message.tenure =
      object.tenure !== undefined && object.tenure !== null
        ? WorkInfo_Tenure.fromPartial(object.tenure)
        : undefined;
    message.workerLocationTimezone = object.workerLocationTimezone ?? undefined;
    message.workAddress =
      object.workAddress !== undefined && object.workAddress !== null
        ? WorkInfo_WorkAddress.fromPartial(object.workAddress)
        : undefined;
    return message;
  },
};

function createBaseWorkInfo_ContactNumber(): WorkInfo_ContactNumber {
  return { officePhone: '', mobilePhone: '', extPhone: '' };
}

export const WorkInfo_ContactNumber = {
  encode(message: WorkInfo_ContactNumber, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.officePhone !== '') {
      writer.uint32(10).string(message.officePhone);
    }
    if (message.mobilePhone !== '') {
      writer.uint32(18).string(message.mobilePhone);
    }
    if (message.extPhone !== '') {
      writer.uint32(26).string(message.extPhone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkInfo_ContactNumber {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkInfo_ContactNumber();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.officePhone = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mobilePhone = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.extPhone = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkInfo_ContactNumber {
    return {
      officePhone: isSet(object.officePhone) ? globalThis.String(object.officePhone) : '',
      mobilePhone: isSet(object.mobilePhone) ? globalThis.String(object.mobilePhone) : '',
      extPhone: isSet(object.extPhone) ? globalThis.String(object.extPhone) : '',
    };
  },

  toJSON(message: WorkInfo_ContactNumber): unknown {
    const obj: any = {};
    if (message.officePhone !== '') {
      obj.officePhone = message.officePhone;
    }
    if (message.mobilePhone !== '') {
      obj.mobilePhone = message.mobilePhone;
    }
    if (message.extPhone !== '') {
      obj.extPhone = message.extPhone;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkInfo_ContactNumber>, I>>(
    base?: I,
  ): WorkInfo_ContactNumber {
    return WorkInfo_ContactNumber.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkInfo_ContactNumber>, I>>(
    object: I,
  ): WorkInfo_ContactNumber {
    const message = createBaseWorkInfo_ContactNumber();
    message.officePhone = object.officePhone ?? '';
    message.mobilePhone = object.mobilePhone ?? '';
    message.extPhone = object.extPhone ?? '';
    return message;
  },
};

function createBaseWorkInfo_Office(): WorkInfo_Office {
  return {
    location: undefined,
    floorDesk: undefined,
    city: undefined,
    state: undefined,
    country: undefined,
    postalCode: undefined,
  };
}

export const WorkInfo_Office = {
  encode(message: WorkInfo_Office, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.location !== undefined) {
      StringValue.encode({ value: message.location! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.floorDesk !== undefined) {
      StringValue.encode({ value: message.floorDesk! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.city !== undefined) {
      StringValue.encode({ value: message.city! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.state !== undefined) {
      StringValue.encode({ value: message.state! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.country !== undefined) {
      StringValue.encode({ value: message.country! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.postalCode !== undefined) {
      StringValue.encode({ value: message.postalCode! }, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkInfo_Office {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkInfo_Office();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.location = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.floorDesk = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.city = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.state = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.country = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.postalCode = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkInfo_Office {
    return {
      location: isSet(object.location) ? String(object.location) : undefined,
      floorDesk: isSet(object.floorDesk) ? String(object.floorDesk) : undefined,
      city: isSet(object.city) ? String(object.city) : undefined,
      state: isSet(object.state) ? String(object.state) : undefined,
      country: isSet(object.country) ? String(object.country) : undefined,
      postalCode: isSet(object.postalCode) ? String(object.postalCode) : undefined,
    };
  },

  toJSON(message: WorkInfo_Office): unknown {
    const obj: any = {};
    if (message.location !== undefined) {
      obj.location = message.location;
    }
    if (message.floorDesk !== undefined) {
      obj.floorDesk = message.floorDesk;
    }
    if (message.city !== undefined) {
      obj.city = message.city;
    }
    if (message.state !== undefined) {
      obj.state = message.state;
    }
    if (message.country !== undefined) {
      obj.country = message.country;
    }
    if (message.postalCode !== undefined) {
      obj.postalCode = message.postalCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkInfo_Office>, I>>(base?: I): WorkInfo_Office {
    return WorkInfo_Office.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkInfo_Office>, I>>(object: I): WorkInfo_Office {
    const message = createBaseWorkInfo_Office();
    message.location = object.location ?? undefined;
    message.floorDesk = object.floorDesk ?? undefined;
    message.city = object.city ?? undefined;
    message.state = object.state ?? undefined;
    message.country = object.country ?? undefined;
    message.postalCode = object.postalCode ?? undefined;
    return message;
  },
};

function createBaseWorkInfo_Tenure(): WorkInfo_Tenure {
  return { beforePercentage: 0, afterPercentage: 0, beforeCount: '0', afterCount: '0' };
}

export const WorkInfo_Tenure = {
  encode(message: WorkInfo_Tenure, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.beforePercentage !== 0) {
      writer.uint32(13).float(message.beforePercentage);
    }
    if (message.afterPercentage !== 0) {
      writer.uint32(21).float(message.afterPercentage);
    }
    if (message.beforeCount !== '0') {
      writer.uint32(24).int64(message.beforeCount);
    }
    if (message.afterCount !== '0') {
      writer.uint32(32).int64(message.afterCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkInfo_Tenure {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkInfo_Tenure();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.beforePercentage = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.afterPercentage = reader.float();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.beforeCount = longToString(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.afterCount = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkInfo_Tenure {
    return {
      beforePercentage: isSet(object.beforePercentage)
        ? globalThis.Number(object.beforePercentage)
        : 0,
      afterPercentage: isSet(object.afterPercentage)
        ? globalThis.Number(object.afterPercentage)
        : 0,
      beforeCount: isSet(object.beforeCount) ? globalThis.String(object.beforeCount) : '0',
      afterCount: isSet(object.afterCount) ? globalThis.String(object.afterCount) : '0',
    };
  },

  toJSON(message: WorkInfo_Tenure): unknown {
    const obj: any = {};
    if (message.beforePercentage !== 0) {
      obj.beforePercentage = message.beforePercentage;
    }
    if (message.afterPercentage !== 0) {
      obj.afterPercentage = message.afterPercentage;
    }
    if (message.beforeCount !== '0') {
      obj.beforeCount = message.beforeCount;
    }
    if (message.afterCount !== '0') {
      obj.afterCount = message.afterCount;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkInfo_Tenure>, I>>(base?: I): WorkInfo_Tenure {
    return WorkInfo_Tenure.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkInfo_Tenure>, I>>(object: I): WorkInfo_Tenure {
    const message = createBaseWorkInfo_Tenure();
    message.beforePercentage = object.beforePercentage ?? 0;
    message.afterPercentage = object.afterPercentage ?? 0;
    message.beforeCount = object.beforeCount ?? '0';
    message.afterCount = object.afterCount ?? '0';
    return message;
  },
};

function createBaseWorkInfo_WorkAddress(): WorkInfo_WorkAddress {
  return { city: undefined, state: undefined, country: undefined };
}

export const WorkInfo_WorkAddress = {
  encode(message: WorkInfo_WorkAddress, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.city !== undefined) {
      StringValue.encode({ value: message.city! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.state !== undefined) {
      StringValue.encode({ value: message.state! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.country !== undefined) {
      StringValue.encode({ value: message.country! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkInfo_WorkAddress {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkInfo_WorkAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.city = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.state = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.country = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkInfo_WorkAddress {
    return {
      city: isSet(object.city) ? String(object.city) : undefined,
      state: isSet(object.state) ? String(object.state) : undefined,
      country: isSet(object.country) ? String(object.country) : undefined,
    };
  },

  toJSON(message: WorkInfo_WorkAddress): unknown {
    const obj: any = {};
    if (message.city !== undefined) {
      obj.city = message.city;
    }
    if (message.state !== undefined) {
      obj.state = message.state;
    }
    if (message.country !== undefined) {
      obj.country = message.country;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkInfo_WorkAddress>, I>>(base?: I): WorkInfo_WorkAddress {
    return WorkInfo_WorkAddress.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkInfo_WorkAddress>, I>>(
    object: I,
  ): WorkInfo_WorkAddress {
    const message = createBaseWorkInfo_WorkAddress();
    message.city = object.city ?? undefined;
    message.state = object.state ?? undefined;
    message.country = object.country ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === 'string') {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
