import React from 'react';
import styled from 'styled-components';
import { Spinner } from '@zillow/constellation';

interface LoaderProps {
  /** content is loading or not */
  loading: boolean;
  /** standard children prop */
  children?: React.ReactNode;
  /** spinner size */
  size?: string;
}

const SpinnerIcon = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Loader: React.FC<LoaderProps> & {
  defaultProps: Partial<LoaderProps>;
} = ({ loading, children, size }: LoaderProps) => {
  return loading ? <SpinnerIcon size={size} /> : <>{children}</>;
};

Loader.defaultProps = {
  size: 'lg',
};
