import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RpcImpl } from '../../contract/rpc';
import {
  ZallWebClientImpl,
  GetWallDetailRequest,
  GetWallDetailResponse,
  UpdateEmployeeInsightsRequest,
  UpdateEmployeeInsightsResponse,
  GiveKarmaRequest,
  GiveKarmaResponse,
  GetEmployeeKarmasRequest,
  GetEmployeeKarmasResponse,
  GetEmployeeKarmasRequest_KarmaType,
  DeleteEmployeeKarmaRequest,
  DeleteEmployeeKarmaResponse,
  UpdateEmployeeFunFactRequest,
  UpdateEmployeeFunFactResponse,
  UpdateEmployeeFavoriteCoreValueRequest,
  UpdateEmployeeFavoriteCoreValueResponse,
  UpdateEmployeePronounRequest,
  UpdateEmployeePronounResponse,
  UpdateEmployeeNamePronunciationRequest,
  UpdateEmployeeNamePronunciationResponse,
  UpdateEmployeePrimaryContactNumberRequest,
  UpdateEmployeePrimaryContactNumberResponse,
  UpdateEmployeeLinkedInProfileUrlRequest,
  UpdateEmployeeLinkedInProfileUrlResponse,
} from '../../contract/zallengine_zallweb';
import {
  WallState,
  WallAction,
  WallDetail,
  LoadWallSuccess,
  LoadWallError,
  LoadMockMyWall,
  LoadMockProfile,
  ResetWall,
  FetchReceivedKarmasSuccess,
  FetchReceivedKarmasError,
  FetchSentKarmasSuccess,
  FetchSentKarmasError,
  DeleteKarmaSuccess,
  UpdateInsightsSuccess,
  UpdateFunFactSuccess,
  UpdateFavoriteCoreValueSuccess,
  UpdatePronounSuccess,
  GiveKarmaSuccess,
  GiveKarmaError,
  UpdateNamePronunciationSuccess,
  UpdatePrimaryContactNumberSuccess,
  UpdateLinkedinProfileUrlSuccess,
} from './types';
import { selectUserEmployeeInfo } from '../user';
import {
  validateGetWallDetailResp,
  validateUpdateEmployeeNamePronunciationResp,
  validateUpdateEmployeeInsightsResp,
  validateGiveKarmaResp,
  validateGetEmployeeKarmasResp,
  validateDeleteKarmaResp,
  validateUpdateEmployeeFunFactResp,
  validateUpdateEmployeePronounResp,
  validateUpdateEmployeeFavoriteCoreValueResp,
  validateUpdateEmployeePrimaryContactNumberResp,
  validateUpdateEmployeeLinkedinProfileUrlResp,
} from './validators';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { Insights, Karma, FunFactAnswer, RootState } from '../index';
import { CoreValue, Pronoun, selectCoreValueMap, selectLeadershipBPMap } from '../wallConfig';
import { GetDisplayError } from '../errorHelpers';
import { NamePronunciation } from '../../contract/zallengine_name_pronunciation';
import DynamicConfig from '../../config/DynamicConfig';

/**
 *
export type ThunkAction<R, S, E, A extends Action> = (
  dispatch: ThunkDispatch<S, E, A>,
  getState: () => S,
  extraArgument: E
) => R;
 */

export const loadWallSuccess = (detail: WallDetail): LoadWallSuccess => ({
  type: 'LOAD_WALL_SUCCESS',
  detail: detail,
});

export const loadWallError = (msg: string): LoadWallError => ({
  type: 'LOAD_WALL_ERROR',
  msg,
});

export const loadMockMyWall = (): LoadMockMyWall => ({
  type: 'LOAD_MOCK_MY_WALL',
});

export const loadMockProfile = (): LoadMockProfile => ({
  type: 'LOAD_MOCK_PROFILE',
});

export const loadWallDetail = (
  username: string | undefined,
): ThunkAction<Promise<void>, WallState, null, WallAction> => async (
  dispatch: ThunkDispatch<WallState, null, WallAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req = {} as GetWallDetailRequest;
  if (username === undefined) {
    req.username = '';
  } else {
    req.username = username;
  }

  return client
    .GetWallDetail(req)
    .then((resp: GetWallDetailResponse) => {
      const [wd, err] = validateGetWallDetailResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(loadWallSuccess(wd));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(loadWallError(errResp.message));
      return Promise.reject(errResp);
    });
};

export const resetWall = (): ResetWall => ({
  type: 'RESET_WALL',
});

export const updateNamePronunciationSuccess = (
  namePronunciation: NamePronunciation,
): UpdateNamePronunciationSuccess => ({
  type: 'UPDATE_NAME_PRONUNCIATION_SUCCESS',
  namePronunciation: namePronunciation,
});

export const updateNamePronunciation = (
  audio: Uint8Array,
  description: string,
  deleteAudio: boolean,
): ThunkAction<Promise<void>, WallState, null, WallAction> => async (
  dispatch: ThunkDispatch<WallState, null, WallAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: UpdateEmployeeNamePronunciationRequest = {
    audio: audio,
    description: description,
    deleteAudio: deleteAudio,
  };

  return client
    .UpdateEmployeeNamePronunciation(req)
    .then((resp: UpdateEmployeeNamePronunciationResponse) => {
      const [namePronunciation, err] = validateUpdateEmployeeNamePronunciationResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(updateNamePronunciationSuccess(namePronunciation));
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const updateInsightsSuccess = (insights: Insights): UpdateInsightsSuccess => ({
  type: 'UPDATE_INSIGHTS_SUCCESS',
  insights: insights,
});

export const updateInsights = (
  insights: Insights,
): ThunkAction<Promise<void>, WallState, null, WallAction> => async (
  dispatch: ThunkDispatch<WallState, null, WallAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: UpdateEmployeeInsightsRequest = {
    insights: insights,
  };

  return client
    .UpdateEmployeeInsights(req)
    .then((resp: UpdateEmployeeInsightsResponse) => {
      const err = validateUpdateEmployeeInsightsResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(updateInsightsSuccess(insights));
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      // TODO error handling
      return Promise.reject(err);
    });
};

export const giveKarmaSuccess = (karma: Karma): GiveKarmaSuccess => ({
  type: 'GIVE_KARMA_SUCCESS',
  karma: karma,
});

export const giveKarmaError = (msg: string): GiveKarmaError => ({
  type: 'GIVE_KARMA_ERROR',
  msg: msg,
});

export const giveKarma = (
  receiverId: string,
  message: string,
  coreValueId?: string,
  leadershipBlueprintId?: string,
): ThunkAction<Promise<void>, RootState, null, WallAction> => async (
  dispatch: ThunkDispatch<RootState, null, WallAction>,
  getState,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: GiveKarmaRequest = {
    receiverId: receiverId,
    message: message,
    coreValueId: coreValueId,
    leadershipBlueprintId: leadershipBlueprintId,
  };

  return client
    .GiveKarma(req)
    .then((resp: GiveKarmaResponse) => {
      const [karmaId, err] = validateGiveKarmaResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        const karmaSent: Karma = {
          id: karmaId,
          message: message,
          coreValue: undefined,
          leadershipBlueprint: undefined,
          sender: undefined,
          receiver: undefined,
          sentDate: new Date(),
        };
        const rootState = getState();
        if (coreValueId) {
          const coreValueMap = selectCoreValueMap(rootState);
          karmaSent.coreValue = {
            id: coreValueId,
            // eslint-disable-next-line
            name: coreValueMap.get(coreValueId)!,
          };
        } else if (leadershipBlueprintId) {
          const leadershipBPMap = selectLeadershipBPMap(rootState);
          karmaSent.leadershipBlueprint = {
            id: leadershipBlueprintId,
            // eslint-disable-next-line
            name: leadershipBPMap.get(leadershipBlueprintId)!,
          };
        }
        karmaSent.sender = selectUserEmployeeInfo(rootState);

        dispatch(giveKarmaSuccess(karmaSent));
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      // TODO error handling
      return Promise.reject(err);
    });
};

export const fetchReceivedKarmasSuccess = (karmas: Karma[]): FetchReceivedKarmasSuccess => ({
  type: 'FETCH_RECEIVED_KARMAS_SUCCESS',
  karmas: karmas,
});

export const fetchReceivedKarmasError = (msg: string): FetchReceivedKarmasError => ({
  type: 'FETCH_RECEIVED_KARMAS_ERROR',
  msg,
});

export const fetchSentKarmasSuccess = (karmas: Karma[]): FetchSentKarmasSuccess => ({
  type: 'FETCH_SENT_KARMAS_SUCCESS',
  karmas: karmas,
});

export const fetchSentKarmasError = (msg: string): FetchSentKarmasError => ({
  type: 'FETCH_SENT_KARMAS_ERROR',
  msg,
});

export const fetchMoreKarmas = (
  employeeId: string,
  type: 'sent' | 'received',
  start: string,
  size: string,
): ThunkAction<Promise<void>, WallState, null, WallAction> => async (
  dispatch: ThunkDispatch<WallState, null, WallAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: GetEmployeeKarmasRequest = {
    employeeId: employeeId,
    karmaType:
      type === 'sent'
        ? GetEmployeeKarmasRequest_KarmaType.KARMA_TYPE_SEND
        : GetEmployeeKarmasRequest_KarmaType.KARMA_TYPE_RECEIVE,
    start: start,
    size: size,
  };

  return client
    .GetEmployeeKarmas(req)
    .then((resp: GetEmployeeKarmasResponse) => {
      const [karmas, err] = validateGetEmployeeKarmasResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        if (type === 'sent') dispatch(fetchSentKarmasSuccess(karmas));
        else dispatch(fetchReceivedKarmasSuccess(karmas));
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      // TODO error handling
      return Promise.reject(err);
    });
};

export const deleteKarmaSuccess = (karmaId: string): DeleteKarmaSuccess => ({
  type: 'DELETE_KARMA_SUCCESS',
  karmaId: karmaId,
});

export const deleteKarma = (
  karmaId: string,
): ThunkAction<Promise<void>, WallState, null, WallAction> => async (
  dispatch: ThunkDispatch<WallState, null, WallAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: DeleteEmployeeKarmaRequest = {
    karmaId: karmaId,
  };

  return client
    .DeleteEmployeeKarma(req)
    .then((resp: DeleteEmployeeKarmaResponse) => {
      const err = validateDeleteKarmaResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(deleteKarmaSuccess(karmaId));
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      // TODO error handling
      return Promise.reject(err);
    });
};

export const updateFunFactSuccess = (funFact: FunFactAnswer): UpdateFunFactSuccess => ({
  type: 'UPDATE_FUN_FACT_SUCCESS',
  funFact: funFact,
});

export const updateFunFactAnswer = (
  funFactId: string,
  answer: string,
): ThunkAction<Promise<void>, WallState, null, WallAction> => async (
  dispatch: ThunkDispatch<WallState, null, WallAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: UpdateEmployeeFunFactRequest = {
    funFactId: funFactId,
    input: answer,
  };

  return client
    .UpdateEmployeeFunFact(req)
    .then((resp: UpdateEmployeeFunFactResponse) => {
      const err = validateUpdateEmployeeFunFactResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(
          updateFunFactSuccess({
            funFactId: funFactId,
            input: answer,
          }),
        );
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      // TODO error handling
      return Promise.reject(err);
    });
};

export const updateFavoriteCoreValueSuccess = (
  coreValue: CoreValue,
): UpdateFavoriteCoreValueSuccess => ({
  type: 'UPDATE_FAVORITE_CORE_VALUE_SUCCESS',
  coreValue: coreValue,
});

export const updateFavoriteCoreValue = (
  coreValueId: string,
  coreValueName: string,
): ThunkAction<Promise<void>, WallState, null, WallAction> => async (
  dispatch: ThunkDispatch<WallState, null, WallAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: UpdateEmployeeFavoriteCoreValueRequest = {
    coreValueId: coreValueId,
  };

  return client
    .UpdateEmployeeFavoriteCoreValue(req)
    .then((resp: UpdateEmployeeFavoriteCoreValueResponse) => {
      const err = validateUpdateEmployeeFavoriteCoreValueResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(
          updateFavoriteCoreValueSuccess({
            id: coreValueId,
            name: coreValueName,
          }),
        );
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      // TODO error handling
      return Promise.reject(err);
    });
};

export const updatePronounSuccess = (pronoun: Pronoun): UpdatePronounSuccess => ({
  type: 'UPDATE_PRONOUN_SUCCESS',
  pronoun: pronoun,
});

export const updatePronoun = (
  pronounId: string,
  pronounName: string,
): ThunkAction<Promise<void>, WallState, null, WallAction> => async (
  dispatch: ThunkDispatch<WallState, null, WallAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: UpdateEmployeePronounRequest = {
    pronounId: pronounId,
  };

  return client
    .UpdateEmployeePronoun(req)
    .then((resp: UpdateEmployeePronounResponse) => {
      const err = validateUpdateEmployeePronounResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(
          updatePronounSuccess({
            id: pronounId,
            name: pronounName,
          }),
        );
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      // TODO error handling
      return Promise.reject(err);
    });
};

export const updatePrimaryContactNumberSuccess = (
  primaryContactNumber: string,
): UpdatePrimaryContactNumberSuccess => ({
  type: 'UPDATE_PRIMARY_CONTACT_NUMBER_SUCCESS',
  primaryContactNumber: primaryContactNumber,
});

export const updatePrimaryContactNumber = (
  primaryContactNumber: string,
): ThunkAction<Promise<void>, WallState, null, WallAction> => async (
  dispatch: ThunkDispatch<WallState, null, WallAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: UpdateEmployeePrimaryContactNumberRequest = {
    primaryContactNumber: primaryContactNumber,
  };

  return client
    .UpdateEmployeePrimaryContactNumber(req)
    .then((resp: UpdateEmployeePrimaryContactNumberResponse) => {
      const err = validateUpdateEmployeePrimaryContactNumberResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(updatePrimaryContactNumberSuccess(primaryContactNumber));
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const updateLinkedinProfileUrlSuccess = (url: string): UpdateLinkedinProfileUrlSuccess => ({
  type: 'UPDATE_LINKEDIN_PROFILE_URL_SUCCESS',
  linkedinProfileUrl: url,
});

export const updateLinkedinProfileUrl = (
  url: string,
): ThunkAction<Promise<void>, WallState, null, WallAction> => async (
  dispatch: ThunkDispatch<WallState, null, WallAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: UpdateEmployeeLinkedInProfileUrlRequest = {
    linkedinProfileUrl: url,
  };

  return client
    .UpdateEmployeeLinkedInProfileUrl(req)
    .then((resp: UpdateEmployeeLinkedInProfileUrlResponse) => {
      const err = validateUpdateEmployeeLinkedinProfileUrlResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(updateLinkedinProfileUrlSuccess(url));
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
