import { useEffect, useCallback, useState } from 'react';
import ReactGA from 'react-ga4';
import { NamePronunciationProps, MAX_AUDIO_SECONDS } from './NamePronunciationContainer';
import { AudioRecorder } from './AudioRecorder';

export const NamePronunciationContainerLogic = ({
  pronunciationAudio,
  pronunciationText,
  onSave,
}: NamePronunciationProps) => {
  const [audioRecorder] = useState(new AudioRecorder());
  const [isMicAccessDenied, setIsMicAccessDenied] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const [audioURL, setAudioURL] = useState(pronunciationAudio);
  const [pronunText, setPronunText] = useState(pronunciationText || '');
  const [isAudioDeleted, setIsAudioDeleted] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [secondsCount, setSecondsCount] = useState('00');

  const openModal = useCallback(() => {
    setAudioURL(pronunciationAudio);
    setPronunText(pronunciationText || '');
    setUploadError(false);
    setIsUploading(false);
    setIsAudioDeleted(false);
    setIsModalOpen(true);
    ReactGA.event({ category: 'Profile', action: 'Clicked Add/Edit Name Pronunciation button' });
  }, [pronunciationAudio, pronunciationText]);

  const closeModal = useCallback(() => {
    if (audioRecorder.IsActive()) {
      stopRecorder();
      deleteAudio();
    }

    setIsModalOpen(false);
  }, []);

  /** for Chrome: check if mic access is allowed */
  useEffect(() => {
    audioRecorder.CheckMicPermissions(
      () => setIsMicAccessDenied(true),
      () => setIsMicAccessDenied(false),
    );
  }, []);

  /** setup & start audio recorder */
  const startRecorder = useCallback(() => {
    audioRecorder
      .SetupRecorder()
      .then(() => {
        setIsRecording(true);
        audioRecorder.StartRecording();
        setSecondsCount('00');

        // stop recording if longer than MAX_AUDIO_SECONDS
        const timeout = setTimeout(() => {
          if (audioRecorder.IsActive()) {
            stopRecorder();
          }
        }, MAX_AUDIO_SECONDS * 1000);

        // start timer
        let elapsedSeconds = 0;
        const secondsCounter = setInterval(() => {
          if (++elapsedSeconds > MAX_AUDIO_SECONDS) {
            clearInterval(secondsCounter);
          } else {
            setSecondsCount(elapsedSeconds.toLocaleString('en-US', { minimumIntegerDigits: 2 }));
          }
        }, 1000);

        audioRecorder.onStop = () => {
          setAudioURL(audioRecorder.GetAudioURL());
          clearTimeout(timeout);
          clearInterval(secondsCounter);
        };
      })
      .catch(() => null);
  }, []);

  const stopRecorder = useCallback(() => {
    setIsRecording(false);
    audioRecorder.StopRecording();
    setIsAudioDeleted(false);
  }, []);

  const deleteAudio = useCallback(() => {
    setAudioURL(undefined);
    // only update isAudioDeleted flag if there is existing saved audio
    if (pronunciationAudio) {
      setIsAudioDeleted(true);
    }
  }, [pronunciationAudio]);

  const checkNeedsSave = useCallback(() => {
    if (
      audioURL !== pronunciationAudio ||
      pronunText !== pronunciationText ||
      (isAudioDeleted && pronunciationAudio)
    ) {
      return true;
    }
    return false;
  }, [audioURL, isAudioDeleted, pronunText, pronunciationAudio, pronunciationText]);

  const getAudioBuffer = useCallback(async () => {
    const audioBlob = audioRecorder.GetAudioBlob();
    const audioBuffer = await audioBlob?.arrayBuffer().then((buf) => new Uint8Array(buf));
    return audioBuffer || new Uint8Array();
  }, [audioURL]);

  const savePronunciation = useCallback(async () => {
    if (!checkNeedsSave()) {
      closeModal();
      return;
    }

    setIsUploading(true);

    let audioBuffer = new Uint8Array();
    if (audioURL) {
      audioBuffer = await getAudioBuffer();
    }

    onSave(audioBuffer, pronunText, isAudioDeleted)
      .then(() => closeModal())
      .catch(() => setUploadError(true))
      .finally(() => setIsUploading(false));
  }, [audioURL, pronunText, isAudioDeleted]);

  return {
    isModalOpen,
    openModal,
    closeModal,
    isMicAccessDenied,
    startRecorder,
    stopRecorder,
    deleteAudio,
    audioURL,
    isRecording,
    savePronunciation,
    pronunText,
    setPronunText,
    uploadError,
    isUploading,
    secondsCount,
  };
};
