import { combineReducers } from 'redux';
import { UserState, userReducer } from './user';
import { WallState, wallReducer } from './wall';
import { BadgeState, badgeReducer } from './badge';
import { InsightsState, insightsReducer } from './insights';
import { InterestState, interestReducer } from './interest';
import { WallConfigState, wallConfigReducer } from './wallConfig';
import { AdminState, adminReducer } from './admin';
import { ImpersonationState, impersonationReducer } from './impersonation';

export interface ApplicationState {
  user: UserState;
  wall: WallState;
  badge: BadgeState;
  insights: InsightsState;
  interest: InterestState;
  wallConfig: WallConfigState;
  admin: AdminState;
  impersonation: ImpersonationState;
}

const rootReducer = combineReducers({
  user: userReducer,
  wall: wallReducer,
  badge: badgeReducer,
  insights: insightsReducer,
  interest: interestReducer,
  wallConfig: wallConfigReducer,
  admin: adminReducer,
  impersonation: impersonationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export * from './user/types';
export * from './wall/types';
export * from './badge/types';
export * from './insights/types';
export * from './interest/types';
export * from './admin/types';
export * from './impersonation/types';
export default rootReducer;
