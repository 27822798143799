import { WallState, WallDetail, WallAction } from './types';
import { mockOthersWallDetail } from './mock';

export const initialState: WallState = {
  loaded: false,
  detail: {} as WallDetail,
  loadWallError: '',
};

export const wallReducer = (state = initialState, action: WallAction): WallState => {
  switch (action.type) {
    case 'LOAD_WALL_SUCCESS':
      return {
        ...state,
        loaded: true,
        detail: action.detail,
      };
    case 'LOAD_WALL_ERROR':
      return {
        ...state,
        loadWallError: action.msg,
      };
    case 'LOAD_MOCK_PROFILE':
      return {
        ...state,
        loaded: true,
        detail: mockOthersWallDetail,
      };
    case 'GIVE_KARMA_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          recentReceivedKarmas: [action.karma, ...state.detail.recentReceivedKarmas],
          totalReceivedKarmasCount: isNaN(parseInt(state.detail.totalReceivedKarmasCount))
            ? '1'
            : (parseInt(state.detail.totalReceivedKarmasCount) + 1).toString(),
        },
      };
    case 'FETCH_RECEIVED_KARMAS_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          recentReceivedKarmas: [...state.detail.recentReceivedKarmas, ...action.karmas],
        },
      };
    case 'FETCH_RECEIVED_KARMAS_ERROR':
      return {
        ...state,
        loadWallError: action.msg,
      };
    case 'FETCH_SENT_KARMAS_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          sentKarmas: state.detail.sentKarmas
            ? [...state.detail.sentKarmas, ...action.karmas]
            : [...action.karmas],
        },
      };
    case 'FETCH_SENT_KARMAS_ERROR':
      return {
        ...state,
        loadWallError: action.msg,
      };
    case 'DELETE_KARMA_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          totalReceivedKarmasCount:
            state.detail.recentReceivedKarmas &&
            state.detail.recentReceivedKarmas.some((karma) => karma.id === action.karmaId) &&
            !isNaN(parseInt(state.detail.totalReceivedKarmasCount))
              ? (parseInt(state.detail.totalReceivedKarmasCount) - 1).toString()
              : state.detail.totalReceivedKarmasCount || '0',
          totalSentKarmasCount:
            state.detail.sentKarmas &&
            state.detail.sentKarmas.some((karma) => karma.id === action.karmaId) &&
            !isNaN(parseInt(state.detail.totalSentKarmasCount))
              ? (parseInt(state.detail.totalSentKarmasCount) - 1).toString()
              : state.detail.totalSentKarmasCount || '0',
          recentReceivedKarmas: state.detail.recentReceivedKarmas
            ? [...state.detail.recentReceivedKarmas.filter((karma) => karma.id !== action.karmaId)]
            : [],
          sentKarmas: state.detail.sentKarmas
            ? [...state.detail.sentKarmas.filter((karma) => karma.id !== action.karmaId)]
            : [],
        },
      };
    case 'UPDATE_INSIGHTS_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          employee: {
            ...state.detail.employee,
            insights: action.insights,
          },
        },
      };
    case 'UPDATE_FUN_FACT_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          personalInfo: {
            ...state.detail.personalInfo,
            funFacts: [
              ...state.detail.personalInfo.funFacts.filter(
                (ff) => ff.funFactId !== action.funFact.funFactId,
              ),
              action.funFact,
            ],
          },
        },
      };
    case 'UPDATE_FAVORITE_CORE_VALUE_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          personalInfo: {
            ...state.detail.personalInfo,
            favoriteCoreValue: action.coreValue,
          },
        },
      };
    case 'UPDATE_PRONOUN_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          personalInfo: {
            ...state.detail.personalInfo,
            pronoun: action.pronoun,
          },
        },
      };
    case 'UPDATE_NAME_PRONUNCIATION_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          personalInfo: {
            ...state.detail.personalInfo,
            namePronunciation: action.namePronunciation,
          },
        },
      };
    case 'UPDATE_PRIMARY_CONTACT_NUMBER_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          personalInfo: {
            ...state.detail.personalInfo,
            primaryContactNumber: action.primaryContactNumber,
          },
        },
      };
    case 'UPDATE_LINKEDIN_PROFILE_URL_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          personalInfo: {
            ...state.detail.personalInfo,
            linkedinProfileUrl: action.linkedinProfileUrl,
          },
        },
      };
    case 'RESET_WALL':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
