import { useState, useEffect, useCallback } from 'react';
import { EditBadgeDetailsModalProps } from './EditBadgeDetailsModal';
import { useForm } from 'react-hook-form';

export type BadgeDetailFormValues = {
  name: string;
  desc: string;
  icon: File | undefined;
};

export const EditBadgeDetailsLogic = ({
  isModalOpen,
  setIsModalOpen,
  badge,
  updateBadgeDetail,
}: EditBadgeDetailsModalProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm<BadgeDetailFormValues>();

  /** reset form when modal is opened */
  useEffect(() => {
    if (isModalOpen) {
      reset();
      setValue('name', badge.name);
      setValue('desc', badge.description);
    }
  }, [isModalOpen]);

  const getBadgeIconBuffer = useCallback(async (iconImg: File) => {
    const iconBuffer = await iconImg.arrayBuffer().then((buf) => new Uint8Array(buf));
    return iconBuffer;
  }, []);

  const saveBadgeDetails = useCallback(
    async (data: BadgeDetailFormValues) => {
      setSaveError(false);
      setIsSaving(true);

      let iconBuffer = new Uint8Array();
      if (data.icon) {
        iconBuffer = await getBadgeIconBuffer(data.icon);
      }

      updateBadgeDetail(badge.id, data.name, data.desc, iconBuffer)
        .then(() => setIsModalOpen(false))
        .catch(() => setSaveError(true))
        .finally(() => setIsSaving(false));
    },
    [badge],
  );

  return {
    isModalOpen,
    setIsModalOpen,
    register,
    handleSubmit,
    control,
    errors,
    isDirty,
    isSaving,
    saveError,
    saveBadgeDetails,
  };
};
