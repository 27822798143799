import React from 'react';

interface ConditionalWrapperProps {
  /** condition to check */
  condition: boolean | undefined;
  /** wrapper element */
  wrapper: (c: React.ReactNode) => React.ReactElement;
  /** wrapped children */
  children?: React.ReactNode;
}

export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) => {
  return condition ? wrapper(children) : <>{children}</>;
};
