import { createSelector, Selector } from 'reselect';
import { RootState, UserInfo, Employee, UserState, Insights, Badge, Interest } from '../index';

export const selectUserState = (state: RootState): UserState => state.user;

export const selectLoggedIn = (state: RootState): boolean => selectUserState(state).loggedIn;

export const selectUserLoaded = (state: RootState): boolean => selectUserState(state).loaded;

export const selectUserInfo: Selector<RootState, UserInfo> = createSelector(
  [selectUserState],
  (userState) => {
    return userState.userInfo;
  },
);

export const selectIsImpersonationAllowed: Selector<RootState, boolean> = createSelector(
  [selectUserInfo],
  (userInfo) => {
    return userInfo.isImpersonationAllowed;
  },
);

export const selectIsBadgeAdmin: Selector<RootState, boolean> = createSelector(
  [selectUserInfo],
  (userInfo) => {
    return userInfo.isBadgeAdmin;
  },
);

export const selectUserEmployeeInfo: Selector<RootState, Employee> = createSelector(
  [selectUserInfo],
  (userInfo) => {
    return userInfo.employee;
  },
);

export const selectUserUsername: Selector<RootState, string> = createSelector(
  [selectUserEmployeeInfo],
  (userEmployeeInfo) => {
    return userEmployeeInfo.username;
  },
);

export const selectUserFullName: Selector<RootState, string> = createSelector(
  [selectUserEmployeeInfo],
  (userEmployeeInfo) => {
    return userEmployeeInfo.fullName;
  },
);

export const selectUserPhoto: Selector<RootState, string> = createSelector(
  [selectUserEmployeeInfo],
  (userEmployeeInfo) => {
    return userEmployeeInfo.photoUrl;
  },
);

export const selectUserInsights: Selector<RootState, Insights | undefined> = createSelector(
  [selectUserEmployeeInfo],
  (userEmployeeInfo) => {
    return userEmployeeInfo.insights;
  },
);

export const selectUserBadges: Selector<RootState, Badge[]> = createSelector(
  [selectUserState],
  (userState) => {
    return userState.badges;
  },
);

export const selectUserInterests: Selector<RootState, Interest[]> = createSelector(
  [selectUserState],
  (userState) => {
    return userState.interests;
  },
);
