export class AudioRecorder {
  mediaRecorder: MediaRecorder | null;
  recordedChunks: Blob[];
  audioURL: string;
  audioBlob?: Blob | null;
  onStop?: () => void;

  constructor() {
    this.mediaRecorder = null;
    this.recordedChunks = [];
    this.audioURL = '';
    this.audioBlob = null;
  }

  handleStop = () => {
    this.audioBlob = new Blob(this.recordedChunks, { type: 'audio/mpeg' });
    this.audioURL = window.URL.createObjectURL(this.audioBlob);
    this.recordedChunks = [];

    // to fully stop media recorder
    this.mediaRecorder?.stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());

    if (this.onStop) this.onStop();
  };

  handleDataAvailable = (e: BlobEvent) => {
    this.recordedChunks.push(e.data);
  };

  public GetAudioURL = (): string => {
    return this.audioURL || '';
  };

  public GetAudioBlob = (): Blob | null => {
    return this.audioBlob || null;
  };

  public GetStatus = (): string => {
    if (!this.mediaRecorder) return '';

    return this.mediaRecorder.state;
  };

  public IsActive(): boolean {
    return this.GetStatus() !== 'inactive';
  }

  public SetupRecorder = (): Promise<void> => {
    return navigator.mediaDevices.getUserMedia({ audio: true }).then(
      (stream) => {
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.onstop = this.handleStop;
        this.mediaRecorder.ondataavailable = this.handleDataAvailable;

        return Promise.resolve();
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  };

  public CheckMicPermissions = (onMicAccessDenied: () => void, onMicAccessGranted: () => void) => {
    if (!navigator || !navigator.permissions || !navigator.permissions.query) {
      return;
    }

    navigator.permissions
      .query({ name: 'microphone' as PermissionName })
      .then((permissionStatus) => {
        if (permissionStatus.state == 'denied') {
          onMicAccessDenied();
        }

        permissionStatus.onchange = function () {
          if (this.state == 'denied') {
            onMicAccessDenied();
          } else if (this.state == 'granted') {
            onMicAccessGranted();
          }
        };
      })
      .catch(() => null);
  };

  public StartRecording = () => {
    this.mediaRecorder?.start();
  };

  public StopRecording = () => {
    this.mediaRecorder?.stop();
  };
}
