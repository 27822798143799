import { useState, useCallback } from 'react';
import { CreateInterestContainerProps } from './CreateInterestContainer';
import { FileUtils } from '../../utilities/fileUtils';

export const MAX_INTEREST_IMG_KB = 1500;
export const INTEREST_IMG_HEIGHT_PX = 800;
export const INTEREST_IMG_WIDTH_PX = 800;

export const CreateInterestContainerLogic = ({
  history,
  createInterest,
}: CreateInterestContainerProps) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState({} as File);

  const [nameError, setNameError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [fileError, setFileError] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const validateName = useCallback((): boolean => {
    if (!name) {
      setNameError('Please enter a name');
      return false;
    }
    setNameError('');
    return true;
  }, [name]);

  const validateDescription = useCallback((): boolean => {
    if (!description) {
      setDescriptionError('Please enter a description');
      return false;
    }
    setDescriptionError('');
    return true;
  }, [description]);

  const validateFileDimensions = async () => {
    const dimensions = await FileUtils.GetImageFileDimensions(file);
    return dimensions.width <= INTEREST_IMG_WIDTH_PX && dimensions.height <= INTEREST_IMG_HEIGHT_PX;
  };

  const validateFileSize = () => {
    return file.size <= MAX_INTEREST_IMG_KB * 1000;
  };

  const validateFile = useCallback(async (): Promise<boolean> => {
    if (!file.size) {
      setFileError('Please upload an icon for the interest');
      return false;
    }

    const isValidDimensions = await validateFileDimensions();
    if (!isValidDimensions) {
      setFileError('Interest image must be at most 800x800 px');
      return false;
    }

    if (!validateFileSize()) {
      setFileError('Interest image should not exceed 1.5MB');
      return false;
    }

    setFileError('');
    return true;
  }, [file]);

  const resetForm = () => {
    setName('');
    setDescription('');
    setFile({} as File);
    setNameError('');
    setDescriptionError('');
    setFileError('');
  };

  const createInterestCallback = useCallback((): Promise<void> => {
    return file
      .arrayBuffer()
      .then((buffer) => {
        return createInterest(name, description, new Uint8Array(buffer))
          .then((interestId) => {
            history.push(`/interests/${interestId}`);
            return Promise.resolve();
          })
          .catch((err) => Promise.reject(err));
      })
      .catch((err) => Promise.reject(err));
  }, [name, description, file, createInterest]);

  const submitInterestCallback = useCallback(async () => {
    if (validateName() && validateDescription() && (await validateFile())) {
      setIsUploading(true);
      createInterestCallback()
        .then(() => {
          setIsModalOpen(false);
        })
        .catch(() => setSubmitError(true))
        .finally(() => {
          resetForm();
          setIsUploading(false);
        });
    }
  }, [createInterestCallback]);

  return {
    setName,
    setDescription,
    setFile,
    nameError,
    descriptionError,
    fileError,
    isModalOpen,
    isUploading,
    submitError,
    setIsModalOpen,
    setSubmitError,
    submitInterestCallback,
  };
};
