import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import {
  Flex,
  Text,
  TextButton,
  Spacer,
  token,
  spaceMixin,
  mediaBreakpointMixin,
} from '@zillow/constellation';
import { ProfileContainerLogic } from './ProfileContainer.hooks';
import { RootState } from '../../store';
import {
  WallDetail,
  selectIsWallDetailLoaded,
  selectWallDetail,
  selectIsCurrentUser,
  loadWallDetail,
  resetWall,
  selectIsContingentWorker,
} from '../../store/wall';
import { inTech } from '../../store/user';
import EmployeeInfoContainer from '../EmployeeInfoContainer';
import InsightsContainer from '../InsightsContainer';
import FunFactsContainer from '../FunFactsContainer';
import KarmaContainer from '../KarmaContainer';
import { InterestWrapper } from '../../pages/Interests';
import { Banner } from '../../components/Banner';
import { AvatarDisk } from '../../components/AvatarDisk';
import { ManagerCard } from '../../components/PersonCard';
import { Badge } from '../../components/Badge';
import { Interest } from '../../components/Interest';
import { TeamMemberCard } from '../../components/PersonCard/TeamMemberCard';
import { Tenure } from '../../components/Tenure';
import { Loader } from '../../components/Loader';
import { HtmlTitle } from '../../components/HtmlTitle';
import DynamicConfig from '../../config/DynamicConfig';
import { AnchorLink, ZallWallLink, NoStyleLink } from '../../components/Links';
import { stringToInteger } from '../../utilities/numUtils';

interface StateProps {
  /** detail has been loaded or not */
  loaded: boolean;
  /** person to show on profile */
  wall: WallDetail;
  /** whether employee is the current user or not */
  isCurrentUser: boolean;
  /** whether employee is contingent worker or not */
  isContingentWorker: boolean;
  /** load wall details action */
  loadWallDetail: (username: string) => Promise<void>;
  /** reset wall */
  resetWall: () => void;
  /** whether employee is in tech or not */
  getIsInTech: (employeeID: string) => Promise<boolean>;
}

interface OwnProps {
  /** person's username to query */
  username: string;
}

export type ProfileContainerProps = StateProps & OwnProps & RouteComponentProps;

const ProfileWrapper = styled.div``;

const MainWrapper = styled(Flex)`
  @media ${mediaBreakpointMixin('sm')} {
    padding-left: ${spaceMixin('xs')};
    padding-right: ${spaceMixin('xs')};
  }

  @media ${mediaBreakpointMixin('lg')} {
    padding-left: ${spaceMixin('xl')};
    padding-right: ${spaceMixin('xl')};
  }
`;

const AvatarWrapper = styled.div`
  /* higher than banner */
  z-index: 1;
`;

const LeftPanel = styled(Flex)`
  flex: 20%;
  text-align: center;
`;

const MiddlePanel = styled(Flex)`
  margin: ${spaceMixin('md')} 0;
  flex: 55%;
`;

const RightPanel = styled(Flex)`
  margin: ${spaceMixin('md')};
  flex: 20%;
  text-align: center;
`;

const BottomPanel = styled(Flex)`
  background-color: ${token('colors.gray200')};
`;

const LineBreak = styled.hr`
  margin-top: ${spaceMixin('xs')};
  width: 100%;
`;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const ProfileContainerBase: React.FC<ProfileContainerProps> = (props: ProfileContainerProps) => {
  const { loaded, wall, isCurrentUser, isContingentWorker, getIsInTech } = props;
  const { ZALL_ENGINE_PROXY, WORKDAY_PHOTO_CHANGE_URL } = DynamicConfig.GetConfig();

  const [inTech, setInTech] = useState(false);

// gets the value of isInTech Action to set the state of inTech
  const isInTech = (): boolean => {
    getIsInTech(wall.employee.id)
    .then((chain)=>{
      if (wall.employee.title === 'Chief Technology Officer') {
        setInTech(true);
      } else {
        setInTech(chain);
      }
      return chain;
    }).catch(()=>{
      setInTech(false);
      return inTech;
      });

    return inTech;
  };
    
  ProfileContainerLogic(props);

  return (
    <Loader loading={!loaded}>
      {loaded && (
        <ProfileWrapper>
          <HtmlTitle>
            <title>{`${wall.employee.fullName} - Profile - ZallWall`}</title>
          </HtmlTitle>

          <Banner brand={wall.workInfo?.brand}>
            {/* TF-1013 Lloyd's manager in Workday is himself */}
            {wall.manager && wall.manager.id !== wall.employee.id && (
              <ManagerCard
                fullName={wall.manager.fullName}
                username={wall.manager.username}
                title={wall.manager.title}
                totalReportsCount={wall.manager.totalReportsCount}
                photoUrl={wall.manager.photoUrl && `${ZALL_ENGINE_PROXY}${wall.manager.photoUrl}`}
              />
            )}
          </Banner>

          <MainWrapper paddingY="lg">
            <Flex display="flex" flexWrap="wrap">
              <LeftPanel display="flex" flexDirection="column" alignItems="center">
                <AvatarWrapper>
                  <AvatarDisk
                    fullName={wall.employee.fullName}
                    photoUrl={
                      wall.employee.photoUrl && `${ZALL_ENGINE_PROXY}${wall.employee.photoUrl}`
                    }
                    size={'xxl'}
                  />
                </AvatarWrapper>
                {isCurrentUser ? (
                  <AnchorLink
                    href={WORKDAY_PHOTO_CHANGE_URL}
                    onClick={() =>
                      ReactGA.event({
                        category: 'Profile',
                        action: 'Clicked Change Profile Photo link',
                      })
                    }
                    target="_blank"
                    marginY="xs"
                  >
                    Change Photo
                  </AnchorLink>
                ) : (
                  <Spacer marginTop="sm"></Spacer>
                )}

                {!isContingentWorker && (
                  <>
                    <InsightsContainer isCurrentUser={isCurrentUser} />

                    <Text fontType="h6" marginTop="md">
                      MY INTERESTS:
                    </Text>
                    {isCurrentUser && (
                      <ZallWallLink
                        to={'/interests'}
                        onClick={() =>
                          ReactGA.event({
                            category: 'Profile',
                            action: 'Clicked Edit Interests button',
                          })
                        }
                        data-testid="edit-interests-link"
                      >
                        Edit
                      </ZallWallLink>
                    )}
                    {wall.personalInfo && (
                      <Spacer marginTop="xs">
                        {wall.personalInfo.interests.map((interest, i) => (
                          <InterestWrapper
                            key={i}
                            onClick={() =>
                              ReactGA.event({
                                category: 'Profile',
                                action: 'Clicked My Interest icon',
                              })
                            }
                            data-testid="my-interest-icon"
                          >
                            <Interest
                              name={interest.name}
                              iconUrl={`${ZALL_ENGINE_PROXY}${interest.iconUrl}`}
                              link={`/interests/${interest.id}`}
                            />
                          </InterestWrapper>
                        ))}
                      </Spacer>
                    )}
                  </>
                )}
              </LeftPanel>

              <MiddlePanel display="flex" flexDirection="column">
                {isInTech()}
                <EmployeeInfoContainer
                  inTechOrg={inTech}
                  isCurrentUser={isCurrentUser}
                  isContingentWorker={isContingentWorker}
                />
                <LineBreak />
                <FunFactsContainer
                  isCurrentUser={isCurrentUser}
                  isContingentWorker={isContingentWorker}
                />
                <LineBreak />
                <KarmaContainer isCurrentUserPage={isCurrentUser} />
              </MiddlePanel>

              <RightPanel>
                {!isContingentWorker && (
                  <>
                    <Flex>
                      {wall.personalInfo.badges.map((badge, i) => (
                        <span
                          key={i}
                          onClick={() =>
                            ReactGA.event({ category: 'Profile', action: 'Clicked My Badge icon' })
                          }
                          data-testid="my-badge-icon"
                        >
                          <Badge
                            name={badge.name}
                            iconUrl={`${ZALL_ENGINE_PROXY}${badge.iconUrl}?ts=${badge.updatedDate}`}
                            link={`/badges/${badge.id}`}
                          />
                        </span>
                      ))}
                    </Flex>
                    <NoStyleLink to="/badges">
                      <TextButton
                        marginTop="xs"
                        onClick={() =>
                          ReactGA.event({
                            category: 'Profile',
                            action: 'Clicked Badge Library link',
                          })
                        }
                      >
                        Badge Library
                      </TextButton>
                    </NoStyleLink>
                  </>
                )}
                {wall.workInfo.tenure && (
                  <Flex marginTop="md" marginBottom="lg">
                    <Tenure
                      fullName={wall.employee.fullName}
                      before={stringToInteger(wall.workInfo.tenure.beforeCount)}
                      after={stringToInteger(wall.workInfo.tenure.afterCount)}
                    />
                  </Flex>
                )}
              </RightPanel>
            </Flex>
          </MainWrapper>
          {wall.reports && wall.reports.length > 0 && (
            <BottomPanel display="flex" flexDirection="column" alignItems="center" padding="md">
              {/* TF-1013 Lloyd's manager in Workday is himself */}
              {wall.reports
                .filter((reportee) => reportee.id !== wall.employee.id)
                .map((report, i) => (
                  <TeamMemberCard
                    key={i}
                    fullName={report.fullName}
                    username={report.username}
                    title={report.title}
                    email={report.email}
                    totalReportsCount={report.totalReportsCount}
                    photoUrl={report.photoUrl && `${ZALL_ENGINE_PROXY}${report.photoUrl}`}
                    insightColor={report.insights?.colors?.split('-')[0]}
                  />
                ))}
            </BottomPanel>
          )}
        </ProfileWrapper>
      )}
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => ({
  loaded: selectIsWallDetailLoaded(state),
  wall: selectWallDetail(state),
  isCurrentUser: selectIsCurrentUser(state),
  isContingentWorker: selectIsContingentWorker(state),
});

const mapDispatchToProps = {
  loadWallDetail: loadWallDetail,
  resetWall: resetWall,
  getIsInTech: inTech,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const ProfileContainer = withRouter(
  connect<StateToPropsType, DispatchToPropsType, OwnProps, RootState>(
    mapStateToProps,
    mapDispatchToProps,
  )(ProfileContainerBase),
);

export { ProfileContainer as default, ProfileContainerBase };
