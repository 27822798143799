import { createSelector, Selector } from 'reselect';
import {
  RootState,
  WallDetail,
  WallState,
  FunFactAnswer,
  Karma,
  Insights,
  Employee,
  NamePronunciation,
} from '../index';
import { Pronoun, CoreValue } from '../wallConfig';
import { mapData } from '../../utilities/mapDataUtils';
import moment from 'moment-timezone';

export const selectWallState = (state: RootState): WallState => state.wall;

export const selectIsWallDetailLoaded: Selector<RootState, boolean> = createSelector(
  [selectWallState],
  (wallState) => {
    return wallState.loaded;
  },
);

export const selectWallDetail: Selector<RootState, WallDetail> = createSelector(
  [selectWallState],
  (wallState) => {
    return wallState.detail;
  },
);

export const selectIsCurrentUser: Selector<RootState, boolean> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.isCurrentUser;
  },
);

export const selectIsContingentWorker: Selector<RootState, boolean> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.workInfo?.workerType === 'Contingent Worker';
  },
);

export const selectWallOwnerEmployeeId: Selector<RootState, string> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.employee.id;
  },
);

export const selectWallOwnerUsername: Selector<RootState, string> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.employee.username;
  },
);

export const selectWallOwnerFullName: Selector<RootState, string> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.employee.fullName;
  },
);

export const selectWallOwnerTitle: Selector<RootState, string> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.employee.title;
  },
);

export const selectWallOwnerEmail: Selector<RootState, string> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.employee.email;
  },
);

export const selectWallOwnerInsights: Selector<RootState, Insights | undefined> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.employee.insights;
  },
);

export const selectWallOwnerWorkingLocation: Selector<RootState, string> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    const workInfo = wallDetail.workInfo;
    const workingArrangement = workInfo.workingArrangement || '';

    /*
      The full name when it's United States of America is too long to display on the UI. For all other countries, we
      want to display the full name.
    */
    const fixCountry = (country: string) => {
      if (country === 'United States of America') return 'USA';
      return country;
    };

    const address = workingArrangement === 'Office' ? workInfo.office : workInfo.workAddress;

    return address?.state && address?.country
      ? `${address?.state}, ${fixCountry(address?.country)}`
      : '';
  },
);

export const selectWallOwnerHireDate: Selector<RootState, Date | undefined> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.workInfo.hireDate;
  },
);

export const selectWallOwnerOfficePhone: Selector<RootState, string | undefined> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.workInfo.contactNumber?.officePhone;
  },
);

export const selectWallOwnerAssistant: Selector<RootState, Employee | undefined> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.assistant;
  },
);

export const selectWallOwnerAssistantTo: Selector<RootState, Employee[] | undefined> =
  createSelector([selectWallDetail], (wallDetail) => {
    return wallDetail.assistantTo;
  });

export const selectWallOwnerAssistantToAlphabetical: Selector<RootState, Employee[] | undefined> =
  createSelector([selectWallOwnerAssistantTo], (assistantTos) => {
    return assistantTos?.sort((a, b) => a.fullName.localeCompare(b.fullName));
  });

export const selectWallOwnerLocalTime: Selector<RootState, string | undefined> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    if (wallDetail.workInfo?.workerLocationTimezone) {
      return moment()
        .tz(wallDetail.workInfo?.workerLocationTimezone)
        .format('LT z');
    }
    return undefined;
  },
);

export const selectWallOwnerNamePronunciation: Selector<RootState, NamePronunciation | undefined> =
  createSelector([selectWallDetail], (wallDetail) => {
    return wallDetail.personalInfo.namePronunciation;
  });

export const selectWallOwnerPronoun: Selector<RootState, Pronoun | undefined> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.personalInfo.pronoun;
  },
);

export const selectWallOwnerPrimaryContactNumber: Selector<RootState, string | undefined> =
  createSelector([selectWallDetail], (wallDetail) => {
    return wallDetail.personalInfo.primaryContactNumber;
  });

export const selectEmployeeFunFacts: Selector<RootState, FunFactAnswer[]> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.personalInfo.funFacts;
  },
);

export const selectEmployeeFunFactMap: Selector<RootState, Map<string, string>> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return mapData(wallDetail.personalInfo.funFacts, 'funFactId', 'input');
  },
);

export const selectWallOwnerLinkedinProfileUrl: Selector<RootState, string | undefined> =
  createSelector([selectWallDetail], (wallDetail) => {
    return wallDetail.personalInfo.linkedinProfileUrl;
  });

export const selectFavoriteCoreValue: Selector<RootState, CoreValue | undefined> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.personalInfo.favoriteCoreValue;
  },
);

export const selectRecentReceivedKarmas: Selector<RootState, Karma[]> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.recentReceivedKarmas;
  },
);

export const selectTotalReceivedKarmasCount: Selector<RootState, number> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    const parsed = parseInt(wallDetail.totalReceivedKarmasCount);
    return isNaN(parsed) ? 0 : parsed;
  },
);

export const selectRecentSentKarmas: Selector<RootState, Karma[] | undefined> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    return wallDetail.sentKarmas;
  },
);

export const selectTotalSentKarmasCount: Selector<RootState, number> = createSelector(
  [selectWallDetail],
  (wallDetail) => {
    const parsed = parseInt(wallDetail.totalSentKarmasCount);
    return isNaN(parsed) ? 0 : parsed;
  },
);
