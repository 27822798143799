import { UserInfo } from '../types';
import { Badge, Interest } from '../../wall/types';
import tenYearServiceBadge from '../../../assets/badges/10_year_service.png';
import grandSlamBadge from '../../../assets/badges/grand_slam.png';
import iHelpedAlanBadge from '../../../assets/badges/i_helped_alan.png';
import stanfordInterest from '../../../assets/interests/stanford.jpg';
import seahawksInterest from '../../../assets/interests/seahawks.jpg';
import skiingInterest from '../../../assets/interests/skiing.png';

export const mockBadges: Badge[] = [
  {
    id: '1',
    name: '10 Years of Service',
    iconUrl: tenYearServiceBadge,
    description: 'Employees who have been Employees for 10 or more years',
    updatedDate: new Date(2022, 2, 10),
  },
  {
    id: '2',
    name: 'Grand Slam',
    iconUrl: grandSlamBadge,
    description: 'Employees who win the Grand Slam award',
    updatedDate: new Date(2022, 2, 10),
  },
  {
    id: '3',
    name: 'I Helped Alan',
    iconUrl: iHelpedAlanBadge,
    description: 'Employees who participated in the I Helped Alan Challenge!',
    updatedDate: new Date(2022, 2, 10),
  },
];

export const mockInterests: Interest[] = [
  {
    id: '1',
    name: 'Stanford',
    iconUrl: stanfordInterest,
    description: 'A page for people who went to or just like Stanford.',
  },
  {
    id: '2',
    name: 'Seahawks',
    iconUrl: seahawksInterest,
    description: '12th Man for life',
  },
  {
    id: '3',
    name: 'Skiing',
    iconUrl: skiingInterest,
    description:
      'Skiing / Snowboarding. Yes I put them together cause I think we should all be friends.',
  },
];

export const mockUser: UserInfo = {
  employee: {
    id: '',
    username: 'rich',
    email: 'rich@zillowgroup.com',
    firstName: 'Rich',
    lastName: 'Barton',
    fullName: 'Rich Barton',
    title: 'Chief Executive Officer',
    photoUrl: 'https://wp.zillowstatic.com/zillowgroup/1/Rich-2-00bd1e-683x1024.jpg',
    totalReportsCount: '',
    insights: { colors: 'r-g-b-y', type: '', isVisible: true },
    isActive: true,
  },
  isImpersonationAllowed: false,
  isBadgeAdmin: false,
};
