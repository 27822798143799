import React, { useCallback, useState } from 'react';
import {
  Flex,
  ComboboxOption,
  AdornedInput,
  Input,
  IconSearch,
  Adornment,
  Combobox,
  Text,
  MediaObject,
  LoadingButton,
} from '@zillow/constellation';
import { EmployeeFound } from '../../store/search';
import { AvatarDisk } from '../../components/AvatarDisk';
import DynamicConfig from '../../config/DynamicConfig';

export interface BadgeAdminSearchboxProps {
  /** search options to display */
  searchOptions: SearchOption[];
  /** are search options loading */
  isLoadingOptions: boolean;
  /** handle searchbox input change */
  handleOnInputChange: (keyword: string) => void;
  /** handle search option CTA click */
  handleSearchOptionCTA: (props: SearchOption) => void;
}

export type ButtonTextType = 'Add' | 'Added' | 'Remove' | 'Removed' | 'Error';

export type SearchOption = {
  value: string;
  label: string;
  employee: EmployeeFound;
  loading: boolean;
  buttonText: ButtonTextType;
};

export const BadgeAdminSearchbox: React.FC<BadgeAdminSearchboxProps> = ({
  searchOptions,
  isLoadingOptions,
  handleOnInputChange,
  handleSearchOptionCTA,
}: BadgeAdminSearchboxProps) => {
  const [searchInput, setSearchInput] = useState('');

  const renderInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ onClear, onTagClose, ...rest }) => {
      return (
        <AdornedInput
          input={
            <Input
              placeholder="Search to add/remove badge members"
              {...rest}
              data-testid="badge-admin-searchbox-input"
            />
          }
          leftAdornment={
            <Adornment>
              <IconSearch />
            </Adornment>
          }
        />
      );
    },
    [],
  );

  const renderOption = useCallback(
    (props: SearchOption) => {
      const { value, label, employee, loading, buttonText } = props;
      const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();

      return (
        <ComboboxOption key={value} value={value} label={label}>
          <Flex display="flex" flexDirection="row" justifyContent="space-between">
            <MediaObject
              media={
                <AvatarDisk
                  fullName={label}
                  photoUrl={employee.photoUrl && `${ZALL_ENGINE_PROXY}${employee.photoUrl}`}
                  size="sm"
                  shape={'square'}
                />
              }
              align="center"
            >
              <Text>{label}</Text>
            </MediaObject>
            <LoadingButton
              buttonType="primary"
              marginLeft="auto"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                handleSearchOptionCTA(props);
              }}
              loading={loading}
              disabled={
                buttonText === 'Added' || buttonText === 'Removed' || buttonText === 'Error'
              }
              data-testid="badge-admin-searchbox-button"
            >
              {buttonText}
            </LoadingButton>
          </Flex>
        </ComboboxOption>
      );
    },
    [searchOptions],
  );

  return (
    <Combobox
      freeForm
      autoCompleteBehavior="none"
      appearance="input"
      onChange={(keyword: string) => {
        setSearchInput(keyword);
        handleOnInputChange(keyword);
      }}
      renderInput={renderInput}
      loading={isLoadingOptions}
      options={searchInput.length ? searchOptions : []}
      renderOption={renderOption}
      data-testid="badge-admin-searchbox-combobox"
    />
  );
};
