import React from 'react';
import { Helmet } from 'react-helmet';

interface TitleProps {
  children: React.ReactNode;
}

/**
 * HtmlTitle
 * Used to dynamically update the HTML title tag.
 * @param {React.ReactNode} children
 * @returns {React.ReactNode}
 */
export const HtmlTitle: React.FC<TitleProps> = ({ children }: TitleProps) => {
  return <Helmet>{children}</Helmet>;
};

export default HtmlTitle;
