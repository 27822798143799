import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { debounce } from 'lodash';
import {
  Flex,
  Paragraph,
  MediaObject,
  TextButton,
  spaceMixin,
  mediaBreakpointMixin,
  AdornedInput,
  Input,
  IconSearch,
  Adornment,
  Spacer,
} from '@zillow/constellation';
import { RootState, Employee } from '../../store';
import {
  loadInsightsTwins,
  selectInsightsTwins,
  selectInsightsTwinsTargetEmployee,
} from '../../store/insights';
import { InsightsTwinsContainerLogic } from './InsightsTwinsContainer.hooks';
import { Insights } from '../../components/Insights';
import { AvatarDisk } from '../../components/AvatarDisk';
import { Loader } from '../../components/Loader';
import DynamicConfig from '../../config/DynamicConfig';
import { PaginationSelect } from '../../components/PaginationSelect';

interface StateProps {
  /** insights twins of whom */
  employee: Employee;
  /** list of insights twins */
  twins: Employee[];
  loadInsightsTwins: (username: string) => Promise<void>;
}
interface OwnProps {
  /** username in path */
  username: string;
}

export type InsightsTwinsContainerProps = StateProps & OwnProps;

export const BannerTextWrapper = styled(Flex)`
  text-align: center;
`;

export const MainWrapper = styled(Flex)`
  margin-top: ${spaceMixin('lg')};
  padding: ${spaceMixin('xl')} 0;
`;

export const LeftPanel = styled(Flex)`
  @media ${mediaBreakpointMixin('md')} {
    width: 200px;
    border-right: 2px solid #ccc;
    border-bottom: none;
    min-height: 100vh;
  }

  border-right: none;
  border-bottom: 2px solid #ccc;
  width: 100%;
  text-align: center;
`;

export const RightPanel = styled(Flex)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
`;

export const LineBreak = styled.hr`
  width: 100%;
  border-top: 2px solid #ccc;
`;

export const UserCard = styled.div`
  width: 130px;
  height: 120px;
  margin-top: 8px;
`;

export function getTwinsForPage(
  twins: Employee[],
  currentPage: number,
  twinsPerPage: number,
): Employee[] {
  return twins.slice(currentPage * twinsPerPage, (currentPage + 1) * twinsPerPage);
}

const writeToGA = debounce(() => {
  ReactGA.event({ category: 'Insights', action: 'Filter Insight Twins' });
}, 1000);

const InsightsTwinsContainerBase: React.FC<InsightsTwinsContainerProps> = (
  props: InsightsTwinsContainerProps,
) => {
  const [searchInput, setSearchInput] = useState('');
  const { employee, twins } = props;
  const [twinsPerPage, setTwinsPerPage] = useState(10);

  const searchTwins = (keyword: string) => {
    const regex = new RegExp(keyword, 'i'); // 'i' for case-insensitive
    return twins.filter((twin) => {
      return regex.test(twin.fullName);
    });
  };

  const { fullName, photoUrl, insights } = employee;
  const { INSIGHTS_PRODUCT_URL, ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const { isLoading } = InsightsTwinsContainerLogic(props);
  const filteredTwins = searchInput ? searchTwins(searchInput) : twins;

  const totalPages = Math.ceil(filteredTwins.length / twinsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const twinsForPage = getTwinsForPage(filteredTwins, currentPage, twinsPerPage);

  return (
    <Loader loading={isLoading}>
      <Flex display="flex">
        <Flex flex="1">{insights && <Insights blocks={insights.colors.split('-')} />}</Flex>
        <Flex flex="2">
          <MediaObject
            media={
              <AvatarDisk
                fullName={fullName}
                photoUrl={photoUrl && `${ZALL_ENGINE_PROXY}${photoUrl}`}
                size="xl"
              />
            }
            direction="column"
            align="start"
          >
            <Paragraph>People with the same exact Insight colors as me.</Paragraph>
            <TextButton as="a" href={INSIGHTS_PRODUCT_URL} marginTop="xs">
              Learn more here
            </TextButton>
          </MediaObject>
        </Flex>
      </Flex>
      <LineBreak />
      {twins && twins.length > 0 && (
        <React.Fragment>
          <Flex display="flex" justifyContent="center" style={{ width: '100%' }}>
            <Flex
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              style={{ width: '50%' }}
            >
              <AdornedInput
                style={{ width: '300px' }}
                input={
                  <Input
                    placeholder="Filter twins"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCurrentPage(0);
                      setSearchInput(event.target.value);
                      writeToGA();
                    }}
                    data-testid="insight-twins-searchbox-input"
                  />
                }
                leftAdornment={
                  <Adornment>
                    <IconSearch />
                  </Adornment>
                }
              />
            </Flex>
          </Flex>
          <Spacer margin="sm" />
          <Flex display="flex" justifyContent="center" flexWrap="wrap" style={{ width: '100%' }}>
            {twinsForPage.map((twin, idx) => (
              <UserCard key={idx} data-testid="insight-twins-card">
                <AvatarDisk
                  fullName={twin.fullName}
                  photoUrl={twin.photoUrl && `${ZALL_ENGINE_PROXY}${twin.photoUrl}`}
                  size="lg"
                  link={twin.username ? `/${twin.username}` : '/'}
                  showName
                  gaCategory="Insights"
                  gaAction="Clicked on Insights Twins"
                />
              </UserCard>
            ))}
          </Flex>
          <Spacer margin="sm" />
          <Flex display="flex" justifyContent="center" style={{ width: '100%' }}>
            <PaginationSelect
              perPage={twinsPerPage}
              setItemsPerPage={setTwinsPerPage}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalItems={twins.length}
            />
          </Flex>
        </React.Fragment>
      )}
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => ({
  employee: selectInsightsTwinsTargetEmployee(state),
  twins: selectInsightsTwins(state),
});

const mapDispatchToProps = {
  loadInsightsTwins: loadInsightsTwins,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const InsightsTwinsContainer = connect<StateToPropsType, DispatchToPropsType, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(InsightsTwinsContainerBase);

export { InsightsTwinsContainer as default, InsightsTwinsContainerBase };
