import React, { useState, useEffect } from 'react';
import { Image, IconUser } from '@zillow/constellation';

export interface ProfileImageProps {
  /** person's full name */
  fullName?: string;
  /** person's photo url */
  photoUrl?: string;
}

export const ProfileImage: React.FC<ProfileImageProps> = ({
  fullName,
  photoUrl,
}: ProfileImageProps) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
  }, [photoUrl]);

  return !photoUrl || hasError ? (
    <IconUser />
  ) : (
    <Image alt={fullName} src={photoUrl} onError={() => setHasError(true)} />
  );
};
