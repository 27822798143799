/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'zallengine_contract';

export interface FunFact {
  id: string;
  definition: string;
}

function createBaseFunFact(): FunFact {
  return { id: '0', definition: '' };
}

export const FunFact = {
  encode(message: FunFact, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.definition !== '') {
      writer.uint32(18).string(message.definition);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FunFact {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFunFact();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.definition = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FunFact {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : '0',
      definition: isSet(object.definition) ? globalThis.String(object.definition) : '',
    };
  },

  toJSON(message: FunFact): unknown {
    const obj: any = {};
    if (message.id !== '0') {
      obj.id = message.id;
    }
    if (message.definition !== '') {
      obj.definition = message.definition;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FunFact>, I>>(base?: I): FunFact {
    return FunFact.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FunFact>, I>>(object: I): FunFact {
    const message = createBaseFunFact();
    message.id = object.id ?? '0';
    message.definition = object.definition ?? '';
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
