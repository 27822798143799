import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ThemeConstellation, ToastProvider } from '@zillow/constellation';
import { defaultTheme } from './themes/default';

import App from './pages/ZallApp';
import reportWebVitals from './reportWebVitals';
import './index.css';
import configureStore from './store/configureStore';
import AuthMiddleware from './middleware/auth';
import DynamicConfig from './config/DynamicConfig';

// https://reactrouter.com/web/guides/scroll-restoration
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const store = configureStore();

DynamicConfig.InitConfig().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={ThemeConstellation}>
        <ThemeProvider theme={defaultTheme}>
          <Provider store={store}>
            <ToastProvider>
              <BrowserRouter>
                <ScrollToTop />
                <AuthMiddleware>
                  <App />
                </AuthMiddleware>
              </BrowserRouter>
            </ToastProvider>
          </Provider>
        </ThemeProvider>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
