import React from 'react';
import { ModalDialog } from '@zillow/constellation';

export interface ModalProps {
  /** if modal is open */
  isOpen: boolean;
  /** modal header */
  header?: React.ReactNode;
  /** modal children */
  body: React.ReactNode;
  /** modal footer */
  footer?: React.ReactNode;
  /** function to handle close */
  handleClose: () => void;
  /** should the modal close on outside click */
  shouldCloseOnOutsideClick?: boolean;
  /** wide sized modal */
  wide?: boolean;
  /** modal size */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  header,
  body,
  footer,
  handleClose,
  shouldCloseOnOutsideClick,
  wide,
  size,
}: ModalProps) => {
  return (
    <ModalDialog
      isOpen={isOpen}
      onClose={handleClose}
      header={header}
      body={body}
      footer={footer}
      shouldCloseOnOutsideClick={shouldCloseOnOutsideClick}
      wide={wide}
      size={size}
    />
  );
};
