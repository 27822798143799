import {
  GetInterestWallDetailResponse,
  GetAllInterestsResponse,
  CreateInterestResponse,
} from '../../contract/zallengine_zallweb';
import { Employee, Interest } from '../index';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { ZallEngineErrorResponse, GetErrorStatusType } from '../errorHelpers';

export const validateGetInterestWallDetailResp = (
  resp: GetInterestWallDetailResponse,
): [Employee[], Interest, ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (
    !resp ||
    !resp.assignees ||
    !resp.interest ||
    resp.status !== StatusType.STATUS_TYPE_SUCCESS
  ) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], {} as Interest, errResp];
  }

  return [resp.assignees, resp.interest, errResp];
};

export const validateGetAllInterestsResp = (
  resp: GetAllInterestsResponse,
): [Interest[], ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.interests || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.interests, errResp];
};

export const validateCreateInterestResp = (
  resp: CreateInterestResponse,
): [string, ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.interestId || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return ['', errResp];
  }

  return [resp.interestId, errResp];
};
