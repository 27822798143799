import { useState, useEffect, useCallback } from 'react';
import { InsightsLeadersContainerProps } from './InsightsLeadersContainer';

export const InsightsLeadersContainerLogic = ({
  color,
  loadInsightsLeaders,
}: InsightsLeadersContainerProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const loadInsightsLeadersCallback = useCallback(
    (color: string): Promise<void> => {
      return loadInsightsLeaders(color);
    },
    [loadInsightsLeaders],
  );

  useEffect(() => {
    if (color) {
      setIsLoading(true);
      loadInsightsLeadersCallback(color).then(() => setIsLoading(false));
    }
  }, [color]);

  return { isLoading };
};
