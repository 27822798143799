import { AdminState, AdminAction } from './types';

export const initialState: AdminState = {
  pendingBadge: [],
  loadPendingBadgeError: '',
  submitBadgeError: '',
};

export const adminReducer = (state = initialState, action: AdminAction): AdminState => {
  switch (action.type) {
    case 'LOAD_PENDING_BADGES_SUCCESS':
      return {
        ...state,
        pendingBadge: action.badges,
      };
    case 'LOAD_PENDING_BADGES_ERROR':
      return {
        ...state,
        loadPendingBadgeError: action.msg,
      };
    case 'SUBMIT_BADGE_DECISION_SUCCESS':
      return {
        ...state,
        pendingBadge: state.pendingBadge.map((pb) =>
          pb.badge?.id === action.badgeId
            ? {
                ...pb,
                processed: true,
              }
            : { ...pb },
        ),
      };
    case 'SUBMIT_BADGE_DECISION_ERROR':
      return {
        ...state,
        submitBadgeError: action.msg,
      };
    default:
      return state;
  }
};
