import { createStore, applyMiddleware, compose, Store } from 'redux';
import thunk from 'redux-thunk';
// import { createLogger } from 'redux-logger';

import rootReducer from './index';
import DynamicConfig from '../config/DynamicConfig';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const APPNAME = 'zallwall:*';
localStorage.setItem('debug', APPNAME);

const getMiddlewares = () => {
  const { ENV } = DynamicConfig.GetConfig();
  if (ENV === 'prod') {
    return applyMiddleware(thunk);
  }
  // return applyMiddleware(thunk, createLogger());
  return applyMiddleware(thunk);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const configureStore = (preloadedState?: any): Store => {
  const store = createStore(rootReducer, preloadedState, composeEnhancers(getMiddlewares()));

  return store;
};

export default configureStore;
