import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import { Card, Text, Flex } from '@zillow/constellation';
import { AvatarDisk } from '../AvatarDisk';
import { NoStyleLink } from '../Links';

export interface PersonCardProps {
  /** person's full name */
  fullName: string;
  /** person's username */
  username: string;
  /** person's photo url */
  photoUrl?: string;
  /** person info in upper right-hand corner */
  cardLabel: React.ReactNode;
  /** person info under name */
  cardBody: React.ReactNode;
  /** GA props */
  gaCategory: string;
  gaAction: string;
}

const Name = styled(Text)`
  width: fit-content;
  max-width: 78%;
`;

const Label = styled.div`
  text-align: right;
  min-height: 16px;
  margin-bottom: -16px;
`;

export const PersonCard: React.FC<PersonCardProps> = ({
  fullName,
  username,
  photoUrl,
  cardLabel,
  cardBody,
  gaCategory,
  gaAction,
}: PersonCardProps) => {
  const handleClick = () => {
    ReactGA.event({ category: gaCategory, action: gaAction });
  };
  return (
    <Card
      data-testid="render-person-card-test"
      cardType="secondary"
      cardElevation={0}
      paddingY="xs"
    >
      <Flex display="flex" flexDirection="row" alignItems="center">
        <AvatarDisk
          fullName={fullName}
          photoUrl={photoUrl}
          size="md"
          link={`/${username}`}
          gaCategory={gaCategory}
          gaAction={gaAction}
        />

        <Flex display="flex" flexDirection="column" marginLeft="sm">
          <Label data-testid="render-label-test">{cardLabel}</Label>
          <NoStyleLink
            to={`/${username}`}
            aria-label={`link to ${fullName} profile`}
            onClick={handleClick}
          >
            <Name data-testid="render-name-test" fontType="h6" fontColor="blue400">
              {fullName}
            </Name>
          </NoStyleLink>
          {cardBody}
        </Flex>
      </Flex>
    </Card>
  );
};
