/* eslint-disable */
import axios from 'axios';

export class RpcImpl {
  readonly baseUrl: string;
  readonly headers: any;

  constructor(baseUrl: string, headers?: any) {
    this.baseUrl = baseUrl;
    if (headers) this.headers = headers;
  }

  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array> {
    const url = this.baseUrl + '/' + service + '/' + method;
    // slice() to remove extra buffer length that causes trailing zeros in decoding
    return axios
      .post(url, data.slice(), {
        withCredentials: true,
        headers: {
          ...this.headers,
          'Content-Type': 'application/protobuf',
        },
        responseType: 'arraybuffer',
      })
      .then((resp) => {
        return Promise.resolve(new Uint8Array(resp.data));
      });
  }
}
