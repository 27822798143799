import React, { Fragment } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Text,
  Button,
  TextButton,
  ButtonGroup,
  MediaObject,
  Paragraph,
  FormField,
  Textarea,
  Select,
  token,
} from '@zillow/constellation';
import { RootState, Karma } from '../../store';
import { selectUserUsername } from '../../store/user';
import {
  giveKarma,
  fetchMoreKarmas,
  deleteKarma,
  selectRecentReceivedKarmas,
  selectTotalReceivedKarmasCount,
  selectTotalSentKarmasCount,
  selectWallOwnerFullName,
  selectWallOwnerEmployeeId,
  selectRecentSentKarmas,
} from '../../store/wall';
import {
  selectCoreValueArray,
  selectLeadershipBPArray,
  CoreValue,
  LeadershipBlueprint,
} from '../../store/wallConfig';
import bannerImage from '../../assets/karmabanner.png';
import { KarmaContainerLogic } from './KarmaContainer.hooks';
import { AvatarDisk } from '../../components/AvatarDisk';
import { DateUtils } from '../../utilities/dateutils';
import DynamicConfig from '../../config/DynamicConfig';

interface StateProps {
  /** current user's username */
  currentUserUsername: string;
  /** page owner's name */
  fullName: string;
  /** page owner's employee id */
  employeeId: string;
  /** karma received to show */
  karmasReceived: Karma[];
  /** karma received count */
  totalKarmasReceived: number;
  /** karma sent count */
  totalKarmasSent: number;
  /** list of core value options */
  coreValues: CoreValue[];
  /** list of leadership blueprint options */
  leadershipBP: LeadershipBlueprint[];
  /** karma sent to show */
  karmasSent?: Karma[];
  /** give karma */
  giveKarma: (
    receiverId: string,
    message: string,
    coreValueId?: string,
    leadershipBlueprintId?: string,
  ) => Promise<void>;
  /** fetch karmas */
  fetchKarmas: (
    employeeId: string,
    type: 'sent' | 'received',
    start: string,
    size: string,
  ) => Promise<void>;
  /** delete karma */
  deleteKarma: (karmaId: string) => Promise<void>;
}

interface OwnProps {
  /** is current user's page */
  isCurrentUserPage: boolean;
}

export type KarmaContainerProps = StateProps & OwnProps;

const KarmaWrapper = styled.div``;

const KarmaBannerWrapper = styled.div`
  background-image: url(${bannerImage});
  background-repeat: no-repeat;
  margin-bottom: 5px;
  padding: 5px;
  width: 200px;
  display: inline-block;
`;

const KarmaDetail = styled(MediaObject)<{ visible?: boolean }>`
  margin: 10px 0;
  display: ${(props) => (props.visible === true || props.visible === undefined ? 'flex' : 'none')};
`;

const KarmaContent = styled(Paragraph)`
  background-color: #eee;
  border: 1px solid #ddd;
  padding: 5px;
`;

const CoreValueText = styled(Text)`
  display: block;
`;

const Signature = styled(Text)`
  display: block;
  color: #b8b8b8;
`;

const DeleteKarmaButton = styled(TextButton)`
  padding: 0 !important;
  margin-top: 0 !important;
  color: ${token('colors.red500')};
`;

const KarmaContainerBase: React.FC<KarmaContainerProps> = (props: KarmaContainerProps) => {
  const {
    currentUserUsername,
    fullName,
    coreValues,
    leadershipBP,
    karmasReceived,
    totalKarmasReceived,
    totalKarmasSent,
    karmasSent,
    isCurrentUserPage,
  } = props;

  const {
    isGivingKarma,
    setIsGivingKarma,
    numKarmaReceivedShow,
    setNumKarmaReceivedShow,
    numKarmaSentShow,
    setNumKarmaSentShow,
    karmaSubmissionText,
    setKarmaSubmissionText,
    karmaSubmissionCVorLB,
    setKarmaSubmissionCVorLB,
    giveKarmasCallback,
    fetchKarmasCallback,
    deleteKarmaCallback,
  } = KarmaContainerLogic(props);
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();

  return (
    <KarmaWrapper data-testid="render-karma-container-test">
      <KarmaBannerWrapper>
        <Text fontColor="textWhite">KARMA POINTS: {totalKarmasReceived}</Text>
      </KarmaBannerWrapper>
      {!isCurrentUserPage && (
        <>
          {!isGivingKarma ? (
            <Button
              buttonType="primary"
              onClick={() => {
                setIsGivingKarma(true);
                ReactGA.event({ category: 'Profile', action: 'Clicked Give Karma button' });
              }}
              data-testid="give-karma-button"
            >
              Give Karma!
            </Button>
          ) : (
            <>
              <FormField
                marginTop="xs"
                control={
                  <>
                    <Textarea
                      defaultValue={karmaSubmissionText}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setKarmaSubmissionText(e.target.value)
                      }
                      maxLength={'1024'}
                      resize={'vertical'}
                      data-testid="karma-submission-text"
                    />
                    <Select
                      value={karmaSubmissionCVorLB || 'DEFAULT'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setKarmaSubmissionCVorLB(e.target.value)
                      }
                      marginTop="xs"
                      data-testid="karma-submission-cv-or-lb"
                    >
                      <option value="DEFAULT" disabled>
                        Core Value/Leadership Blueprint (optional)
                      </option>
                      <optgroup label="Core Values">
                        {coreValues.map((cv, i) => (
                          <option key={i} value={`cv:${cv.id}`}>
                            {cv.name}
                          </option>
                        ))}
                      </optgroup>
                      <optgroup label="Leadership Blueprint">
                        {leadershipBP.map((lbp, i) => (
                          <option key={i} value={`lb:${lbp.id}`}>
                            {lbp.name}
                          </option>
                        ))}
                      </optgroup>
                    </Select>
                  </>
                }
              />
              <Button
                buttonType="primary"
                onClick={() => {
                  giveKarmasCallback();
                  setIsGivingKarma(false);
                }}
                marginY="xs"
                data-testid="submit-karma-button"
              >
                Submit
              </Button>
            </>
          )}
        </>
      )}
      {karmasReceived.map((k, i) => (
        <Fragment key={i}>
          <KarmaDetail
            visible={i < numKarmaReceivedShow}
            media={
              <AvatarDisk
                fullName={k.sender?.fullName}
                photoUrl={
                  k.sender?.isActive && k.sender?.photoUrl
                    ? `${ZALL_ENGINE_PROXY}${k.sender.photoUrl}`
                    : ''
                }
                size={'md'}
                link={
                  k.sender?.isActive && k.sender?.username ? `/${k.sender?.username}` : undefined
                }
                gaCategory="Karma"
                gaAction="Clicked on Karma Received From Avatar"
              />
            }
          >
            <KarmaContent data-testid="karma-received-content">
              {k.message}
              {k.coreValue && <CoreValueText>{k.coreValue.name}</CoreValueText>}
              {k.leadershipBlueprint && <CoreValueText>{k.leadershipBlueprint.name}</CoreValueText>}
              <Signature as="i">
                {k.sender?.fullName && `${k.sender?.fullName}, `}
                {k.sentDate && DateUtils.FormatDateToISOString(k.sentDate)}
              </Signature>
              {k.sender?.username === currentUserUsername &&
                k.sentDate &&
                DateUtils.DaysDiff(k.sentDate, new Date()) < 1 && (
                  <DeleteKarmaButton fontType="bodySmall" onClick={() => deleteKarmaCallback(k.id)}>
                    Delete this karma
                  </DeleteKarmaButton>
                )}
            </KarmaContent>
          </KarmaDetail>
        </Fragment>
      ))}
      <ButtonGroup aria-label="show more karma" direction="column">
        {numKarmaReceivedShow < totalKarmasReceived && (
          <TextButton
            fontType="body"
            data-testid="see-more-karma-received-button"
            onClick={() => {
              fetchKarmasCallback('received', karmasReceived.length);
              setNumKarmaReceivedShow(numKarmaReceivedShow + 6);
            }}
          >
            See more of {totalKarmasReceived} karma received by {fullName}
          </TextButton>
        )}
        {totalKarmasSent > 0 && numKarmaSentShow > 0 && karmasSent && (
          <>
            <KarmaBannerWrapper>
              <Text fontColor="textWhite">KARMA SENT: {totalKarmasSent}</Text>
            </KarmaBannerWrapper>
            {karmasSent.map((k, i) => (
              <Fragment key={i}>
                <KarmaDetail
                  visible={i < numKarmaSentShow}
                  media={
                    <AvatarDisk
                      fullName={k.receiver?.fullName}
                      photoUrl={
                        k.receiver?.isActive && k.receiver?.photoUrl
                          ? `${ZALL_ENGINE_PROXY}${k.receiver.photoUrl}`
                          : ''
                      }
                      size={'md'}
                      link={
                        k.receiver?.isActive && k.receiver?.username
                          ? `/${k.receiver?.username}`
                          : undefined
                      }
                      gaCategory="Karma"
                      gaAction="Clicked on Karma Sent To Avatar"
                    />
                  }
                >
                  <KarmaContent data-testid="karma-sent-content">
                    {k.message}
                    {k.coreValue && <CoreValueText>{k.coreValue.name}</CoreValueText>}
                    {k.leadershipBlueprint && (
                      <CoreValueText>{k.leadershipBlueprint.name}</CoreValueText>
                    )}
                    <Signature as="i">
                      {k.receiver?.fullName && `${k.receiver?.fullName}, `}
                      {k.sentDate && DateUtils.FormatDateToISOString(k.sentDate)}
                    </Signature>
                    {k.sender?.username === currentUserUsername &&
                      k.sentDate &&
                      DateUtils.DaysDiff(k.sentDate, new Date()) < 1 && (
                        <DeleteKarmaButton
                          fontType="bodySmall"
                          onClick={() => deleteKarmaCallback(k.id)}
                        >
                          Delete this karma
                        </DeleteKarmaButton>
                      )}
                  </KarmaContent>
                </KarmaDetail>
              </Fragment>
            ))}
          </>
        )}
        {numKarmaSentShow < totalKarmasSent && (
          <TextButton
            fontType="body"
            data-testid="see-more-karma-sent-button"
            onClick={() => {
              fetchKarmasCallback('sent', karmasSent ? karmasSent.length : 0);
              setNumKarmaSentShow(numKarmaSentShow + 6);
            }}
          >
            Show karma sent by {fullName}
          </TextButton>
        )}
      </ButtonGroup>
    </KarmaWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUserUsername: selectUserUsername(state),
  fullName: selectWallOwnerFullName(state),
  employeeId: selectWallOwnerEmployeeId(state),
  karmasReceived: selectRecentReceivedKarmas(state),
  totalKarmasReceived: selectTotalReceivedKarmasCount(state),
  totalKarmasSent: selectTotalSentKarmasCount(state),
  karmasSent: selectRecentSentKarmas(state),
  coreValues: selectCoreValueArray(state),
  leadershipBP: selectLeadershipBPArray(state),
});

const mapDispatchToProps = {
  giveKarma: giveKarma,
  fetchKarmas: fetchMoreKarmas,
  deleteKarma: deleteKarma,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const KarmaContainer = connect<StateToPropsType, DispatchToPropsType, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(KarmaContainerBase);

export { KarmaContainer as default, KarmaContainerBase };
