/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import { FunFact } from './zallengine_funfact';
import { CoreValue, LeadershipBlueprint, Pronoun } from './zallengine_zg101';

export const protobufPackage = 'zallengine_contract';

/**
 * WallConfig contains static config data of the application
 * such as Core Values, Leadership Blueprint ...
 */
export interface WallConfig {
  coreValues: CoreValue[];
  leadershipBlueprints: LeadershipBlueprint[];
  pronouns: Pronoun[];
  funfacts: FunFact[];
}

function createBaseWallConfig(): WallConfig {
  return { coreValues: [], leadershipBlueprints: [], pronouns: [], funfacts: [] };
}

export const WallConfig = {
  encode(message: WallConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.coreValues) {
      CoreValue.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.leadershipBlueprints) {
      LeadershipBlueprint.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.pronouns) {
      Pronoun.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.funfacts) {
      FunFact.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WallConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWallConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.coreValues.push(CoreValue.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.leadershipBlueprints.push(LeadershipBlueprint.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pronouns.push(Pronoun.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.funfacts.push(FunFact.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WallConfig {
    return {
      coreValues: globalThis.Array.isArray(object?.coreValues)
        ? object.coreValues.map((e: any) => CoreValue.fromJSON(e))
        : [],
      leadershipBlueprints: globalThis.Array.isArray(object?.leadershipBlueprints)
        ? object.leadershipBlueprints.map((e: any) => LeadershipBlueprint.fromJSON(e))
        : [],
      pronouns: globalThis.Array.isArray(object?.pronouns)
        ? object.pronouns.map((e: any) => Pronoun.fromJSON(e))
        : [],
      funfacts: globalThis.Array.isArray(object?.funfacts)
        ? object.funfacts.map((e: any) => FunFact.fromJSON(e))
        : [],
    };
  },

  toJSON(message: WallConfig): unknown {
    const obj: any = {};
    if (message.coreValues?.length) {
      obj.coreValues = message.coreValues.map((e) => CoreValue.toJSON(e));
    }
    if (message.leadershipBlueprints?.length) {
      obj.leadershipBlueprints = message.leadershipBlueprints.map((e) =>
        LeadershipBlueprint.toJSON(e),
      );
    }
    if (message.pronouns?.length) {
      obj.pronouns = message.pronouns.map((e) => Pronoun.toJSON(e));
    }
    if (message.funfacts?.length) {
      obj.funfacts = message.funfacts.map((e) => FunFact.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WallConfig>, I>>(base?: I): WallConfig {
    return WallConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WallConfig>, I>>(object: I): WallConfig {
    const message = createBaseWallConfig();
    message.coreValues = object.coreValues?.map((e) => CoreValue.fromPartial(e)) || [];
    message.leadershipBlueprints =
      object.leadershipBlueprints?.map((e) => LeadershipBlueprint.fromPartial(e)) || [];
    message.pronouns = object.pronouns?.map((e) => Pronoun.fromPartial(e)) || [];
    message.funfacts = object.funfacts?.map((e) => FunFact.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
