import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { token } from '@zillow/constellation';

export const ZallWallLink = styled(Link)`
  width: fit-content;
  color: ${token('colors.brand')};
  text-decoration: none;
  &:hover {
    color: rgb(0, 23, 81);
  }
`;
