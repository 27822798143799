import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';
import { Text, TextButton, Select } from '@zillow/constellation';
import { Pronoun } from '../../store/wallConfig';

export interface PronounsProps {
  /** person's preferred pronoun */
  preferredPronouns?: Pronoun;
  /** pronoun options */
  pronounOptions: Map<string, string>;
  /** if pronouns are editable */
  editable?: boolean;
  /** on submission callback */
  onSubmit?: (ans: string) => void;
}

const PronounWrapper = styled.div``;

export const Pronouns: React.FC<PronounsProps> = ({
  preferredPronouns,
  pronounOptions,
  editable,
  onSubmit,
}: PronounsProps) => {
  const [editing, setEditing] = useState(false);
  const [selectedPronounId, setSelectedPronounId] = useState(preferredPronouns?.id);

  return (
    <PronounWrapper>
      <Text fontType="bodySmall">My pronouns are: </Text>
      {editing ? (
        <Select
          value={selectedPronounId || 'DEFAULT'}
          fluid={false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSelectedPronounId(e.target.value)
          }
          data-testid="render-pronouns-select-test"
        >
          <option value="DEFAULT" disabled>
            Select one
          </option>
          {Array.from(pronounOptions.keys()).map((pid, i) => (
            <option value={pid} key={i} data-testid="render-pronouns-option-test">
              {pronounOptions.get(pid)}
            </option>
          ))}
        </Select>
      ) : (
        selectedPronounId && (
          <Text fontType="bodySmall" marginRight="xs" data-testid="render-pronouns-text-test">
            {pronounOptions.get(selectedPronounId)}
          </Text>
        )
      )}
      {editable && (
        <TextButton
          fontType="bodySmallHeading"
          onClick={() => {
            if (!editing) ReactGA.event({ category: 'Profile', action: 'Edit My Pronouns' });
            if (editing && onSubmit && selectedPronounId) onSubmit(selectedPronounId);
            setEditing(!editing);
          }}
          marginLeft="xs"
          data-testid="render-pronouns-button-test"
        >
          {editing ? 'Save' : 'Edit'}
        </TextButton>
      )}
    </PronounWrapper>
  );
};
