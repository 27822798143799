import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RpcImpl } from '../../contract/rpc';
import {
  ZallWebClientImpl,
  GetPendingBadgesRequest,
  GetPendingBadgesResponse,
  SubmitBadgeDecisionRequest,
  SubmitBadgeDecisionResponse,
} from '../../contract/zallengine_zallweb';
import {
  AdminState,
  AdminAction,
  PendingBadge,
  BadgeDecisionType,
  LoadPendingBadgesSuccess,
  LoadPendingBadgesError,
  SubmitBadgeDecisionSuccess,
  SubmitBadgeDecisionError,
} from './types';
import { validateGetPendingBadgesResp, validateSubmitBadgeDecisionResp } from './validators';
import DynamicConfig from '../../config/DynamicConfig';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { GetDisplayError } from '../errorHelpers';

/**
 *
export type ThunkAction<R, S, E, A extends Action> = (
  dispatch: ThunkDispatch<S, E, A>,
  getState: () => S,
  extraArgument: E
) => R;
 */

export const loadPendingBadgesSuccess = (badges: PendingBadge[]): LoadPendingBadgesSuccess => ({
  type: 'LOAD_PENDING_BADGES_SUCCESS',
  badges: badges,
});

export const loadPendingBadgesError = (msg: string): LoadPendingBadgesError => ({
  type: 'LOAD_PENDING_BADGES_ERROR',
  msg,
});

export const submitBadgeDecisionSuccess = (badgeId: string): SubmitBadgeDecisionSuccess => ({
  type: 'SUBMIT_BADGE_DECISION_SUCCESS',
  badgeId,
});

export const submitBadgeDecisionError = (msg: string): SubmitBadgeDecisionError => ({
  type: 'SUBMIT_BADGE_DECISION_ERROR',
  msg,
});

export const loadPendingBadges = (): ThunkAction<
  Promise<void>,
  AdminState,
  null,
  AdminAction
> => async (dispatch: ThunkDispatch<AdminState, null, AdminAction>): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: GetPendingBadgesRequest = {};

  return client
    .GetPendingBadges(req)
    .then((resp: GetPendingBadgesResponse) => {
      const [pendingBadges, err] = validateGetPendingBadgesResp(resp);
      if (
        err.code === StatusType.STATUS_TYPE_SUCCESS ||
        err.code === StatusType.STATUS_TYPE_NOT_FOUND
      ) {
        dispatch(loadPendingBadgesSuccess(pendingBadges));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(loadPendingBadgesError(errResp.message));
      return Promise.reject(errResp);
    });
};

export const submitBadgeDecision = (
  badgeId: string,
  decision: BadgeDecisionType,
  reason: string,
): ThunkAction<Promise<void>, AdminState, null, AdminAction> => async (
  dispatch: ThunkDispatch<AdminState, null, AdminAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: SubmitBadgeDecisionRequest = {
    badgeId: badgeId,
    decision: decision,
    reason: reason,
  };

  return client
    .SubmitBadgeDecision(req)
    .then((resp: SubmitBadgeDecisionResponse) => {
      const err = validateSubmitBadgeDecisionResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(submitBadgeDecisionSuccess(badgeId));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(submitBadgeDecisionError(errResp.message));
      return Promise.reject(errResp);
    });
};
