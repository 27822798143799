import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Heading, Spacer, Paragraph, IconChevronLeftOutline } from '@zillow/constellation';
import { InterestPagesLogic } from './InterestPages.hooks';
import { RootState, Interest as InterestType } from '../../store';
import { loadMyInterests, selectUserInterests } from '../../store/user';
import { InterestMembers } from './InterestMembers';
import { AllInterests } from './AllInterests';
import CreateInterestContainer from '../../containers/CreateInterestContainer';
import {
  BannerTextWrapper,
  MainWrapper,
  LeftPanel,
  RightPanel,
} from '../../containers/InsightsTwinsContainer';
import { Banner } from '../../components/Banner';
import { Interest } from '../../components/Interest';
import { NoStyleLink } from '../../components/Links';
import DynamicConfig from '../../config/DynamicConfig';

export interface InterestPagesProps {
  /** user's own interest */
  myInterests: InterestType[];
  loadMyInterests: () => Promise<void>;
}

export const InterestWrapper = styled.div`
  height: 75px;
  width: 75px;
  margin: 5px auto;
`;

const InterestPagesBase: React.FunctionComponent<InterestPagesProps> = (
  props: InterestPagesProps,
) => {
  const location = useLocation();
  const isAllInterestPage =
    location.pathname === '/interests' || location.pathname === '/interests/';
  const { myInterests } = props;
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  InterestPagesLogic(props);

  return (
    <>
      <Banner>
        <BannerTextWrapper flexDirection="column" alignItems="center">
          <Heading fontColor="textWhite" level="3">
            Zillow Group Interests
          </Heading>
          <Spacer margin="md" />
          <Paragraph fontColor="textWhite" fontType="body">
            The things we like
          </Paragraph>
        </BannerTextWrapper>
      </Banner>
      <MainWrapper display="flex" flexWrap="wrap">
        <LeftPanel>
          {!isAllInterestPage && (
            <NoStyleLink to={`/interests`}>
              <Paragraph fontType="h6" fontColor="brand">
                <IconChevronLeftOutline /> All Interests
              </Paragraph>
            </NoStyleLink>
          )}
          <Heading level="4" marginTop="lg">
            My Interests
          </Heading>
          <CreateInterestContainer />
          <>
            {myInterests.map((interest, i) => (
              <InterestWrapper
                key={i}
                onClick={() =>
                  ReactGA.event({ category: 'Interests', action: 'Clicked Interest icon' })
                }
                data-testid="interests-page-my-interest-item"
              >
                <Interest
                  name={interest.name}
                  iconUrl={`${ZALL_ENGINE_PROXY}${interest.iconUrl}`}
                  link={'/interests/' + interest.id}
                />
              </InterestWrapper>
            ))}
          </>
        </LeftPanel>

        <RightPanel>
          <Switch>
            <Route path="/interests/:interestId" component={InterestMembers} />
            <Route path="/interests" component={AllInterests} />
          </Switch>
        </RightPanel>
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  myInterests: selectUserInterests(state),
});

const mapDispatchToProps = {
  loadMyInterests: loadMyInterests,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const InterestPages = connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(InterestPagesBase);

export { InterestPages as default, InterestPagesBase };
