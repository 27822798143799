import {
  GetBadgeWallDetailResponse,
  GetAllBadgesResponse,
  SubmitNewBadgeResponse,
  AddBadgeAssigneeResponse,
  RemoveBadgeAssigneeResponse,
  UpdateBadgeDetailResponse,
} from '../../contract/zallengine_zallweb';
import { Employee, Badge } from '../index';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { ZallEngineErrorResponse, GetErrorStatusType } from '../errorHelpers';

export const validateGetBadgeWallDetailResp = (
  resp: GetBadgeWallDetailResponse,
): [Badge, Employee | undefined, Employee[], ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.assignees || !resp.badge || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [{} as Badge, undefined, [], errResp];
  }

  return [resp.badge, resp.owner, resp.assignees, errResp];
};

export const validateGetAllBadgesResp = (
  resp: GetAllBadgesResponse,
): [Badge[], ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.badges || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.badges, errResp];
};

export const validateSubmitNewBadgeResp = (
  resp: SubmitNewBadgeResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateAddBadgeAssigneeResp = (
  resp: AddBadgeAssigneeResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateRemoveBadgeAssigneeResp = (
  resp: RemoveBadgeAssigneeResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateUpdateBadgeDetailResp = (
  resp: UpdateBadgeDetailResponse,
): [Badge, ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS || !resp.badge) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [{} as Badge, errResp];
  }

  return [resp.badge, errResp];
};
