import React, { useState } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import {
  Paragraph,
  Text,
  TextButton,
  FormField,
  Textarea,
  spaceMixin,
} from '@zillow/constellation';

export interface TextQuestionProps {
  /** label of a question */
  label: string;
  /** answer of a question */
  answer?: string;
  /** if text question is editable */
  editable?: boolean;
  /** on submission callback */
  onSubmit?: (ans: string) => void;
  /** GA props */
  gaCategory: string;
  gaAction: string;
}

const QuestionWrapper = styled.div`
  margin-bottom: ${spaceMixin('xs')};
`;

export const TextQuestion: React.FC<TextQuestionProps> = ({
  label,
  answer,
  editable,
  onSubmit,
  gaCategory,
  gaAction,
}: TextQuestionProps) => {
  const [editing, setEditing] = useState(false);
  const [ans, setAns] = useState(answer);

  return (
    <QuestionWrapper data-testid="render-text-question-test">
      <Text fontType="bodySmallHeading">{label}</Text>
      {editable && (
        <>
          <Text fontType="bodySmallHeading"> - </Text>
          <TextButton
            data-testid="render-text-button-test"
            fontType="bodySmallHeading"
            onClick={() => {
              if (!editing) ReactGA.event({ category: gaCategory, action: gaAction });

              if (editing && onSubmit && ans !== undefined) onSubmit(ans);
              setEditing(!editing);
            }}
          >
            {editing ? 'Save' : 'Edit'}
          </TextButton>
        </>
      )}
      {editing ? (
        <FormField
          data-testid="render-form-test"
          control={
            <Textarea
              defaultValue={ans}
              readOnly={!editing}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAns(e.target.value)}
              data-testid="text-question-textarea"
            />
          }
        />
      ) : (
        <Paragraph>{ans}</Paragraph>
      )}
    </QuestionWrapper>
  );
};
