import { createSelector, Selector } from 'reselect';
import { RootState, InterestState, Employee, Interest } from '../index';

export const selectInterestState = (state: RootState): InterestState => state.interest;

export const selectAllInterests: Selector<RootState, Interest[]> = createSelector(
  [selectInterestState],
  (interestState) => {
    return interestState.allInterests;
  },
);

export const selectTargetInterest: Selector<RootState, Interest> = createSelector(
  [selectInterestState],
  (interestState) => {
    return interestState.targetInterest;
  },
);

export const selectInterestFans: Selector<RootState, Employee[]> = createSelector(
  [selectInterestState],
  (interestState) => {
    return interestState.interestFans;
  },
);
