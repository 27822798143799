import {
  GetInsightsTwinsResponse,
  GetEmployeesWithInsightsColorResponse,
} from '../../contract/zallengine_zallweb';
import { Employee } from '../index';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { ZallEngineErrorResponse, GetErrorStatusType } from '../errorHelpers';

export const validateGetInsightsTwinsResp = (
  resp: GetInsightsTwinsResponse,
): [Employee[], Employee, ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (
    !resp ||
    !resp.insightTwins ||
    !resp.employee ||
    resp.status !== StatusType.STATUS_TYPE_SUCCESS
  ) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], {} as Employee, errResp];
  }

  return [resp.insightTwins, resp.employee, errResp];
};

export const validateGetEmployeesWithInsightsColorResp = (
  resp: GetEmployeesWithInsightsColorResponse,
): [Employee[], ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.employees || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.employees, errResp];
};
