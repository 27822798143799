import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  Alert,
  Flex,
  Heading,
  LoadingButton,
  Form,
  FormField,
  FormHelp,
  Label,
  Input,
  Textarea,
  TextButton,
  IconPlus,
} from '@zillow/constellation';
import { Modal } from '../../components/Modal';
import { RootState } from '../../store';
import { createInterest } from '../../store/interest';
import { CreateInterestContainerLogic } from './CreateInterestContainer.hooks';

export interface CreateInterestContainerProps extends RouteComponentProps {
  createInterest: (name: string, description: string, iconImage: Uint8Array) => Promise<string>;
}

const FormLabel = styled(Label)`
  cursor: default;
`;

const ModalHeader = styled(Heading)`
  text-align: left;
`;

const PlusIcon = styled(IconPlus)`
  margin-right: -5px;
  height: 10px !important;
`;

const CreateInterestContainerBase: React.FC<CreateInterestContainerProps> = (
  props: CreateInterestContainerProps,
) => {
  const {
    setName,
    setDescription,
    setFile,
    nameError,
    descriptionError,
    fileError,
    isModalOpen,
    isUploading,
    submitError,
    setIsModalOpen,
    setSubmitError,
    submitInterestCallback,
  } = CreateInterestContainerLogic(props);

  const interestDimension =
    'Interest images must be at most 800x800 px PNG file and have a max size of 1.5MB';

  const createInterestForm = (
    <Flex marginX="lg" marginY="sm">
      <Form>
        <FormField
          label={<FormLabel>Name</FormLabel>}
          control={
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              data-testid="create-interest-name"
            />
          }
          error={nameError !== ''}
          description={nameError && <FormHelp>{nameError}</FormHelp>}
        />
        <FormField
          label={<FormLabel>Description</FormLabel>}
          control={
            <Textarea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
              data-testid="create-interest-desc"
            />
          }
          error={descriptionError !== ''}
          description={descriptionError && <FormHelp>{descriptionError}</FormHelp>}
        />
        <FormField
          label={<FormLabel>Icon</FormLabel>}
          control={
            <Input
              type="file"
              accept="image/*"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files && e.target.files.length > 0) setFile(e.target.files[0]);
              }}
              data-testid="create-interest-file"
            />
          }
          error={fileError !== ''}
          description={
            <FormHelp>{interestDimension}</FormHelp> ||
            (fileError && <FormHelp>{fileError}</FormHelp>)
          }
        />
        {submitError && (
          <Alert appearance="error" body="Something went wrong. Please try again later." />
        )}
      </Form>
    </Flex>
  );

  return (
    <>
      <Modal
        header={<ModalHeader level={6}>Create Interest</ModalHeader>}
        body={createInterestForm}
        footer={
          <LoadingButton
            buttonType="primary"
            loading={isUploading}
            onClick={submitInterestCallback}
            data-testid="create-interest-loading-button"
          >
            Create Interest
          </LoadingButton>
        }
        isOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
          setSubmitError(false);
        }}
        wide
        shouldCloseOnOutsideClick={false}
      />

      <TextButton
        icon={<PlusIcon />}
        marginTop="xs"
        marginBottom="md"
        onClick={() => setIsModalOpen(true)}
        data-testid="create-interest-button"
      >
        Create interest
      </TextButton>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createInterest: createInterest,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const CreateInterestContainer = withRouter(
  connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
    mapStateToProps,
    mapDispatchToProps,
  )(CreateInterestContainerBase),
);

export { CreateInterestContainer as default, CreateInterestContainerBase };
