import React from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';
import {
  token,
  mediaBreakpointMixin,
  ClearFix,
  PageContent,
  Paragraph,
  Image,
  Spacer
} from '@zillow/constellation';
import { AnchorLink } from '../Links';
import ZillowGroupLogoImage from './ZillowGroupDarkBlue.svg';
import DynamicConfig from '../../config/DynamicConfig';
import { FAQ } from './FAQ';

const FooterWrapper = styled(ClearFix)`
  @media ${mediaBreakpointMixin('lg')} {
    text-align: right;
  }
  background-color: ${token('colors.backgroundWhite')};
  text-align: center;
  max-width: none;
`;

const FooterContent = styled(PageContent)`
  max-width: 1280px;
  margin: auto;
`;

const ZillowGroupLogo = styled(Image)`
  height: 25px;
`;

export const Footer = () => {
  const { PPL_OPS_SERVICE_NOW_URL } = DynamicConfig.GetConfig();

  return (
    <FooterWrapper data-testid='render-footer-test' >
      <FooterContent data-testid='render-footer-content-test' paddingY="md">
        <Paragraph data-testid='render-need-help-test' >
        <FAQ/>
          Need help?{' '}
          <AnchorLink data-testid='render-email-link-test'
            href={`${PPL_OPS_SERVICE_NOW_URL}`}
            onClick={() =>
              ReactGA.event({ category: 'Footer', action: 'Clicked People Operations link' })
            }
          >
            Contact us
          </AnchorLink>
        </Paragraph>
        <Spacer margin="xs" />
        <ZillowGroupLogo data-testid='render-ZG-logo-test' src={ZillowGroupLogoImage} alt="Zillow Group Logo" />
        <Paragraph>Powered by CorpApps Engineering</Paragraph>
      </FooterContent>
    </FooterWrapper>
  );
};
