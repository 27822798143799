import ReactGA from 'react-ga4';
import { useEffect, useCallback, useState } from 'react';
import { ZallAppProps } from './ZallApp';
import DynamicConfig from '../config/DynamicConfig';

export const ZallAppLogic = ({ loggedIn, loadWallConfig, history }: ZallAppProps) => {
  const { GA_MEASUREMENT_ID } = DynamicConfig.GetConfig();
  const [isImpersonationModalOpen, setIsImpersonationModalOpen] = useState(false);

  /** init GA and set up page tracking */
  useEffect(() => {
    ReactGA.initialize(GA_MEASUREMENT_ID, { testMode: process.env.NODE_ENV === 'test' });
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: location.pathname + location.search,
    });

    history.listen((location) => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      // Record a pageview for the given page
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname,
        title: location.pathname,
      });
    });
  }, []);

  const getWallConfigCallback = useCallback(() => {
    loadWallConfig();
  }, [loadWallConfig]);

  useEffect(() => {
    if (loggedIn) {
      getWallConfigCallback();
    }
  }, [loggedIn]);

  return { isImpersonationModalOpen, setIsImpersonationModalOpen };
};

// TODO mock page flow, eagerly loads everything
