import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Spacer } from '@zillow/constellation';
import { TextQuestion } from '../../components/TextQuestion';
import { DropdownQuestion } from '../../components/DropdownQuestion';
import { RootState } from '../../store';
import {
  updateFunFactAnswer,
  updateFavoriteCoreValue,
  selectEmployeeFunFactMap,
  selectFavoriteCoreValue,
} from '../../store/wall';
import {
  selectCoreValueMap,
  selectFunFactQuestionArray,
  CoreValue,
  FunFact,
} from '../../store/wallConfig';

interface StateProps {
  /** list of fun fact questions */
  questionsList: FunFact[];
  /** fun fact questions id & answers */
  funFacts: Map<string, string>;
  /** favorite core value */
  favoriteCoreValue?: CoreValue;
  /** list of core value options */
  coreValues: Map<string, string>;
  /** update fun fact answer */
  updateFunFactAnswer: (funFactId: string, answer: string) => Promise<void>;
  /** update favorite core value */
  updateFavoriteCoreValue: (coreValueId: string, coreValueName: string) => Promise<void>;
}
interface OwnProps {
  /** if fun facts are editable */
  isCurrentUser: boolean;
  /** whether employee is contingent worker or not */
  isContingentWorker: boolean;
}

export type FunFactsContainerProps = StateProps & OwnProps;

const FunFactsWrapper = styled.div``;

const FunFactsContainerBase: React.FC<FunFactsContainerProps> = ({
  funFacts,
  favoriteCoreValue,
  questionsList,
  coreValues,
  updateFunFactAnswer,
  updateFavoriteCoreValue,
  isCurrentUser,
}: FunFactsContainerProps) => {
  return (
    <FunFactsWrapper data-testid="render-fun-facts-test">
      {questionsList.map((q, i) => (
        <Fragment key={i}>
          {(isCurrentUser || funFacts.get(q.id)) && (
            <>
              <TextQuestion
                label={q.definition}
                answer={funFacts.get(q.id)}
                editable={isCurrentUser}
                onSubmit={(ans: string) => {
                  if (ans !== funFacts.get(q.id)) updateFunFactAnswer(q.id, ans);
                }}
                gaCategory="Profile"
                gaAction="Edit Fun Facts"
              />
              <Spacer marginTop="xs"></Spacer>
            </>
          )}
        </Fragment>
      ))}
      {(isCurrentUser || favoriteCoreValue) && (
        <DropdownQuestion
          label={'MY FAVORITE CORE VALUE'}
          options={Array.from(coreValues.values())}
          answer={favoriteCoreValue?.name}
          editable={isCurrentUser}
          onSubmit={(ans: string | undefined) => {
            if (ans !== favoriteCoreValue?.name) {
              let cvId = '';
              coreValues.forEach((v, k) => {
                if (v === ans) {
                  cvId = k;
                }
              });
              if (cvId && ans) updateFavoriteCoreValue(cvId, ans);
            }
          }}
          gaCategory="Profile"
          gaAction="Edit Core Values"
        />
      )}
    </FunFactsWrapper>
  );
};

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  funFacts: selectEmployeeFunFactMap(state),
  favoriteCoreValue: selectFavoriteCoreValue(state),
  questionsList: selectFunFactQuestionArray(state).filter(
    (ff) => !(props.isContingentWorker && ff.definition.includes('WHAT I DO')),
  ),
  coreValues: selectCoreValueMap(state),
});

const mapDispatchToProps = {
  updateFunFactAnswer: updateFunFactAnswer,
  updateFavoriteCoreValue: updateFavoriteCoreValue,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const FunFactsContainer = connect<StateToPropsType, DispatchToPropsType, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(FunFactsContainerBase);

export { FunFactsContainer as default, FunFactsContainerBase };
