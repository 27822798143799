import { useCallback, useState, useEffect } from 'react';
import { AudioPlayerIconProps } from './AudioPlayerIcon';

export const AudioPlayerIconLogic = ({ audioURL }: AudioPlayerIconProps) => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [audio, setAudio] = useState(new Audio(audioURL));

  useEffect(() => {
    setAudio(new Audio(audioURL));
    audio.load();
  }, [audioURL]);

  const playAudio = useCallback(() => {
    audio.onended = () => {
      setIsAudioPlaying(false);
    };

    if (audio.paused) {
      audio.play();
      setIsAudioPlaying(true);
    } else {
      audio.pause();
      setIsAudioPlaying(false);
    }
  }, [audio]);

  return {
    playAudio,
    isAudioPlaying,
  };
};
