import React from 'react';
import { Banner, TextButton } from '@zillow/constellation';
import { ErrorType } from '../../store/errorHelpers';

interface LoginErrorProps {
  /** login error type */
  errorType: ErrorType;
  /** function to redirect window to login */
  toOktaLogin: () => void;
}

export const LoginError = ({ errorType, toOktaLogin }: LoginErrorProps) => {
  let errorText: string;
  let actionButton: React.ReactElement | null;

  switch (errorType) {
    case ErrorType.UNAUTHORIZED_USER:
      errorText = 'You are not authorized to use ZallWall. Please request access.';
      actionButton = null;
      break;
    default:
      errorText = 'Something went wrong. Please try again.';
      actionButton = <TextButton onClick={toOktaLogin}>Retry</TextButton>;
  }

  return <Banner actionButton={actionButton} appearance="warning" body={errorText} isOpen={true} />;
};
