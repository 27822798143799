import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { createSelector, Selector } from 'reselect';
import { WallConfig as WallConfigContract } from '../contract/zallengine_wall_config';
import {
  ZallWebClientImpl,
  GetWallConfigRequest,
  GetWallConfigResponse,
} from '../contract/zallengine_zallweb';
import { RpcImpl } from '../contract/rpc';
import {
  CoreValue as CoreValueContract,
  LeadershipBlueprint as LeadershipBlueprintContract,
  Pronoun as PronounContract,
} from '../contract/zallengine_zg101';
import { FunFact as FunFactContract } from '../contract/zallengine_funfact';
import { RootState } from './index';
import { mapData } from '../utilities/mapDataUtils';
import { StatusType } from '../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { ZallEngineErrorResponse, GetErrorStatusType } from './errorHelpers';
import DynamicConfig from '../config/DynamicConfig';

// types
export type WallConfig = WallConfigContract;
export type Pronoun = PronounContract;
export type CoreValue = CoreValueContract;
export type LeadershipBlueprint = LeadershipBlueprintContract;
export type FunFact = FunFactContract;

export type WallConfigState = WallConfigContract & {
  loadWallConfigError: string;
};

export interface LoadWallConfigSuccess extends Action {
  type: 'LOAD_WALL_CONFIG_SUCCESS';
  config: WallConfigContract;
}

export interface LoadWallConfigError extends Action {
  type: 'LOAD_WALL_CONFIG_ERROR';
  msg: string;
}

export type WallConfigAction = LoadWallConfigSuccess | LoadWallConfigError;

// actions
export const loadWallConfigSuccess = (config: WallConfigContract): LoadWallConfigSuccess => ({
  type: 'LOAD_WALL_CONFIG_SUCCESS',
  config,
});

export const loadWallConfigError = (msg: string): LoadWallConfigError => ({
  type: 'LOAD_WALL_CONFIG_ERROR',
  msg,
});

export const loadWallConfig = (): ThunkAction<
  Promise<void>,
  WallConfigState,
  null,
  WallConfigAction
> => async (dispatch: ThunkDispatch<WallConfigState, null, WallConfigAction>): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  return client
    .GetWallConfig({} as GetWallConfigRequest)
    .then((resp: GetWallConfigResponse) => {
      const [wallConfig, err] = validateGetWallConfigResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(loadWallConfigSuccess(wallConfig));
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      dispatch(loadWallConfigError(err));
      return Promise.reject(err);
    });
};

// reducers
const initialState: WallConfigState = {
  coreValues: [],
  leadershipBlueprints: [],
  pronouns: [],
  funfacts: [],
  loadWallConfigError: '',
};

export const wallConfigReducer = (
  state = initialState,
  action: WallConfigAction,
): WallConfigState => {
  switch (action.type) {
    case 'LOAD_WALL_CONFIG_SUCCESS':
      return {
        ...state,
        ...action.config,
      };
    case 'LOAD_WALL_CONFIG_ERROR':
      return {
        ...state,
        loadWallConfigError: action.msg,
      };
    default:
      return state;
  }
};

// validators
export const validateGetWallConfigResp = (
  resp: GetWallConfigResponse,
): [WallConfig, ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.data || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [{} as WallConfig, errResp];
  }

  return [resp.data, errResp];
};

// selectors
export const selectWallConfig = (state: RootState): WallConfig => state.wallConfig;

export const selectCoreValueArray: Selector<RootState, CoreValue[]> = createSelector(
  [selectWallConfig],
  (wallConfig) => {
    return wallConfig.coreValues;
  },
);

export const selectCoreValueMap: Selector<RootState, Map<string, string>> = createSelector(
  [selectCoreValueArray],
  (coreValuesArray) => {
    return mapData(coreValuesArray, 'id', 'name');
  },
);

export const selectLeadershipBPArray: Selector<RootState, LeadershipBlueprint[]> = createSelector(
  [selectWallConfig],
  (wallConfig) => {
    return wallConfig.leadershipBlueprints;
  },
);

export const selectLeadershipBPMap: Selector<RootState, Map<string, string>> = createSelector(
  [selectLeadershipBPArray],
  (leadershipBlueprintsArray) => {
    return mapData(leadershipBlueprintsArray, 'id', 'name');
  },
);

export const selectPronounOptions: Selector<RootState, Pronoun[]> = createSelector(
  [selectWallConfig],
  (wallConfig) => {
    return wallConfig.pronouns;
  },
);

export const selectPronounMap: Selector<RootState, Map<string, string>> = createSelector(
  [selectWallConfig],
  (wallConfig) => {
    return mapData(wallConfig.pronouns, 'id', 'name');
  },
);

export const selectFunFactQuestionArray: Selector<RootState, FunFact[]> = createSelector(
  [selectWallConfig],
  (wallConfig) => {
    const excluded = [
      'MY FAVORITE CORE VALUE',
      'PREFERRED PRONOUNS',
      'PRIMARY CONTACT NUMBER',
      'LINKEDIN PROFILE LINK',
    ];

    return wallConfig.funfacts.filter((ff) => excluded.indexOf(ff.definition) < 0);
  },
);

export const selectFunFactQuestionMap: Selector<RootState, Map<string, string>> = createSelector(
  [selectFunFactQuestionArray],
  (funFactsArray) => {
    return mapData(funFactsArray, 'id', 'definition');
  },
);

// mock
export const mockPronounOptions: Pronoun[] = [
  {
    id: '1',
    name: 'He/him/his',
  },
  {
    id: '2',
    name: 'She/her/hers',
  },
  {
    id: '3',
    name: 'They/them/theirs',
  },
];

export const mockPersonalQuestions: FunFact[] = [
  {
    id: '1',
    definition: 'WHERE I GREW UP',
  },
  {
    id: '2',
    definition: 'WHAT I DO',
  },
  {
    id: '3',
    definition: 'WORDS OF WISDOM',
  },
  {
    id: '4',
    definition: 'WHAT HOME MEANS TO ME',
  },
];

export const mockCoreValuesList: CoreValue[] = [
  {
    id: '1',
    name: 'Own It',
  },
  {
    id: '2',
    name: 'Turn On the Lights',
  },
  {
    id: '4',
    name: 'ZG is a Team Sport',
  },
];

export const mockLeadershipBlueprint: LeadershipBlueprint[] = [
  {
    id: '1',
    name: 'Show You Care',
  },
  {
    id: '2',
    name: 'Empower Your Team',
  },
];

/** Hardcoded for Insights */
export const insightTypes = [
  'Coordinator',
  'Director',
  'Helper',
  'Inspirer',
  'Observer',
  'Motivator',
  'Reformer',
  'Supporter',
];

export const insightsColorExplanation: Record<string, { pros: string[]; cons: string[] }> = {
  r: {
    pros: [
      'Is strongly task-focused and always in motion',
      'Focuses on results, clear on what they want and how they intend to get it',
      'Creates a clear plan and being firm in stating their intentions',
      'Rises to a challenge and determined in seeing it through',
      'Responds quickly and effectively to whatever is happening',
      'Is eager to move on to the next step',
    ],
    cons: ['Perception of overdrive and intolerance', 'Impatient'],
  },
  g: {
    pros: [
      'Builds trust easily',
      'Listens attentively and patiently',
      'Responds warmly and respectfully',
      'Is considerate and sensitive',
      "Accommodates others' needs",
      'Offers empathy and loyalty',
    ],
    cons: [
      'Perception of "people pleasing"',
      'Stubborn resistance to actions/choices that do not align with values',
    ],
  },
  b: {
    pros: [
      'Uses an observing and assessing approach',
      'Has a strong desire to know and understand the world around them',
      'Takes time to think things through before forming any conclusions',
      'Maintains a detached and objective viewpoint until all the information is processed',
      'Thinks through a problem carefully and assessing the implications of possible solutions',
      'Works quietly and diligently with carefulness and persistence',
    ],
    cons: ['Perception of slow decision making', 'Analysis Paralysis'],
  },
  y: {
    pros: [
      'Engages in interaction with others and skilled at relationship building',
      'Thinks "out of the box"',
      'Is highly influential without seeking to dominate others',
      'Gets involved in whatever is going on',
      'Puts others at ease',
      'Offers a positive and optimistic approach',
    ],
    cons: [
      'Perception of being frantic under stress',
      'Overcommits, loses focus and becomes overwhelmed',
    ],
  },
};
