/* eslint-disable */

export const protobufPackage = 'commoncontract';

export enum StatusType {
  STATUS_TYPE_UNSPECIFIED = 0,
  STATUS_TYPE_SUCCESS = 1,
  STATUS_TYPE_UNAUTHENTICATED = 3,
  STATUS_TYPE_UNAUTHORIZED = 4,
  STATUS_TYPE_BAD_REQUEST = 5,
  STATUS_TYPE_NOT_FOUND = 6,
  STATUS_TYPE_INTERNAL_ERROR = 7,
  STATUS_TYPE_NOT_IMPLEMENTED = 8,
  STATUS_TYPE_DEPRECATED = 9,
  STATUS_TYPE_CONFLICT = 10,
  STATUS_TYPE_FORBIDDEN = 11,
  STATUS_TYPE_UNSUPPORTED_MEDIA_TYPE = 12,
  STATUS_TYPE_UNPROCESSABLE_ENTITY = 13,
  STATUS_TYPE_IM_A_TEAPOT = 14,
  UNRECOGNIZED = -1,
}

export function statusTypeFromJSON(object: any): StatusType {
  switch (object) {
    case 0:
    case 'STATUS_TYPE_UNSPECIFIED':
      return StatusType.STATUS_TYPE_UNSPECIFIED;
    case 1:
    case 'STATUS_TYPE_SUCCESS':
      return StatusType.STATUS_TYPE_SUCCESS;
    case 3:
    case 'STATUS_TYPE_UNAUTHENTICATED':
      return StatusType.STATUS_TYPE_UNAUTHENTICATED;
    case 4:
    case 'STATUS_TYPE_UNAUTHORIZED':
      return StatusType.STATUS_TYPE_UNAUTHORIZED;
    case 5:
    case 'STATUS_TYPE_BAD_REQUEST':
      return StatusType.STATUS_TYPE_BAD_REQUEST;
    case 6:
    case 'STATUS_TYPE_NOT_FOUND':
      return StatusType.STATUS_TYPE_NOT_FOUND;
    case 7:
    case 'STATUS_TYPE_INTERNAL_ERROR':
      return StatusType.STATUS_TYPE_INTERNAL_ERROR;
    case 8:
    case 'STATUS_TYPE_NOT_IMPLEMENTED':
      return StatusType.STATUS_TYPE_NOT_IMPLEMENTED;
    case 9:
    case 'STATUS_TYPE_DEPRECATED':
      return StatusType.STATUS_TYPE_DEPRECATED;
    case 10:
    case 'STATUS_TYPE_CONFLICT':
      return StatusType.STATUS_TYPE_CONFLICT;
    case 11:
    case 'STATUS_TYPE_FORBIDDEN':
      return StatusType.STATUS_TYPE_FORBIDDEN;
    case 12:
    case 'STATUS_TYPE_UNSUPPORTED_MEDIA_TYPE':
      return StatusType.STATUS_TYPE_UNSUPPORTED_MEDIA_TYPE;
    case 13:
    case 'STATUS_TYPE_UNPROCESSABLE_ENTITY':
      return StatusType.STATUS_TYPE_UNPROCESSABLE_ENTITY;
    case 14:
    case 'STATUS_TYPE_IM_A_TEAPOT':
      return StatusType.STATUS_TYPE_IM_A_TEAPOT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return StatusType.UNRECOGNIZED;
  }
}

export function statusTypeToJSON(object: StatusType): string {
  switch (object) {
    case StatusType.STATUS_TYPE_UNSPECIFIED:
      return 'STATUS_TYPE_UNSPECIFIED';
    case StatusType.STATUS_TYPE_SUCCESS:
      return 'STATUS_TYPE_SUCCESS';
    case StatusType.STATUS_TYPE_UNAUTHENTICATED:
      return 'STATUS_TYPE_UNAUTHENTICATED';
    case StatusType.STATUS_TYPE_UNAUTHORIZED:
      return 'STATUS_TYPE_UNAUTHORIZED';
    case StatusType.STATUS_TYPE_BAD_REQUEST:
      return 'STATUS_TYPE_BAD_REQUEST';
    case StatusType.STATUS_TYPE_NOT_FOUND:
      return 'STATUS_TYPE_NOT_FOUND';
    case StatusType.STATUS_TYPE_INTERNAL_ERROR:
      return 'STATUS_TYPE_INTERNAL_ERROR';
    case StatusType.STATUS_TYPE_NOT_IMPLEMENTED:
      return 'STATUS_TYPE_NOT_IMPLEMENTED';
    case StatusType.STATUS_TYPE_DEPRECATED:
      return 'STATUS_TYPE_DEPRECATED';
    case StatusType.STATUS_TYPE_CONFLICT:
      return 'STATUS_TYPE_CONFLICT';
    case StatusType.STATUS_TYPE_FORBIDDEN:
      return 'STATUS_TYPE_FORBIDDEN';
    case StatusType.STATUS_TYPE_UNSUPPORTED_MEDIA_TYPE:
      return 'STATUS_TYPE_UNSUPPORTED_MEDIA_TYPE';
    case StatusType.STATUS_TYPE_UNPROCESSABLE_ENTITY:
      return 'STATUS_TYPE_UNPROCESSABLE_ENTITY';
    case StatusType.STATUS_TYPE_IM_A_TEAPOT:
      return 'STATUS_TYPE_IM_A_TEAPOT';
    case StatusType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}
