import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import { Avatar } from '@zillow/constellation';
import { ProfileImage } from './index';
import { ConditionalWrapper } from '../ConditionalWrapper';
import { NoStyleLink } from '../Links';

export interface AvatarDiskProps {
  /** person's full name */
  fullName?: string;
  /** disk size */
  size?: string;
  /** link to */
  link?: string;
  /** person's photo url */
  photoUrl?: string;
  /** show name at the bottom */
  showName?: boolean;
  /** shape */
  shape?: 'circle' | 'square';
  /** GA props */
  gaCategory?: string;
  gaAction?: string;
}

const AvatarDiskWrapper = styled.div`
  text-align: center;
`;

const AvatarImageWrapper = styled(Avatar)<{ shape: 'circle' | 'square' }>`
  border-radius: ${(props) => (props.shape === 'circle' ? '50%' : '0')};
`;

export const AvatarDisk: React.FC<AvatarDiskProps> = ({
  fullName,
  size,
  link,
  photoUrl,
  showName,
  shape,
  gaCategory,
  gaAction,
}: AvatarDiskProps) => {
  const handleClick = () => {
    if (gaCategory && gaAction) {
      ReactGA.event({ category: gaCategory, action: gaAction });
    }
  };

  return (
    <AvatarDiskWrapper>
      <ConditionalWrapper
        condition={link !== undefined}
        wrapper={(children) => (
          <NoStyleLink
            to={link || '#'}
            aria-label={`link to ${fullName} profile`}
            onClick={handleClick}
          >
            {children}
          </NoStyleLink>
        )}
      >
        <AvatarImageWrapper aria-hidden="true" size={size} shape={shape}>
          <ProfileImage {...{ fullName, photoUrl }} />
        </AvatarImageWrapper>
        {showName && fullName && <div>{fullName}</div>}
      </ConditionalWrapper>
    </AvatarDiskWrapper>
  );
};

AvatarDisk.defaultProps = {
  shape: 'circle',
};
