import React from 'react';
import styled from 'styled-components';
import { ConditionalWrapper } from '../ConditionalWrapper';
import { NoStyleLink } from '../Links';
import { ZImage } from '../ZImage';
import fallbackImage from '../../assets/filenotfound.png';

export interface InterestProps {
  /** name of interest */
  name: string;
  /** image url of interest icon */
  iconUrl: string;
  /** link to */
  link?: string;
  /** size of interest (max width/height) */
  size?: string;
}

const InterestIcon = styled(ZImage)<{ size?: string }>`
  max-width: ${(props) => (props.size ? props.size : '75px')};
  max-height: ${(props) => (props.size ? props.size : '75px')};
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25%;
`;

export const Interest: React.FC<InterestProps> = ({ name, iconUrl, size, link }: InterestProps) => {
  return (
    <>
      <ConditionalWrapper
        condition={link !== undefined}
        wrapper={(children) => <NoStyleLink to={link ? link : ''}>{children}</NoStyleLink>}
      >
        <InterestIcon
          imageUrl={iconUrl}
          alt={name}
          title={name}
          fallbackUrl={fallbackImage}
          size={size}
          data-testid="render-interest-image-test"
        />
      </ConditionalWrapper>
    </>
  );
};
