/* eslint-disable */
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'zallengine_contract';

export interface Insights {
  /**
   * string contains four Insights colors separated using dash
   * e.g. "y-r-g-b"
   */
  colors: string;
  /** Insights type, e.g. "COORDINATOR", "HELPER" ... */
  type: string;
  /** whether to show Insights information */
  isVisible: boolean;
}

function createBaseInsights(): Insights {
  return { colors: '', type: '', isVisible: false };
}

export const Insights = {
  encode(message: Insights, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.colors !== '') {
      writer.uint32(10).string(message.colors);
    }
    if (message.type !== '') {
      writer.uint32(18).string(message.type);
    }
    if (message.isVisible === true) {
      writer.uint32(24).bool(message.isVisible);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Insights {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInsights();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.colors = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isVisible = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Insights {
    return {
      colors: isSet(object.colors) ? globalThis.String(object.colors) : '',
      type: isSet(object.type) ? globalThis.String(object.type) : '',
      isVisible: isSet(object.isVisible) ? globalThis.Boolean(object.isVisible) : false,
    };
  },

  toJSON(message: Insights): unknown {
    const obj: any = {};
    if (message.colors !== '') {
      obj.colors = message.colors;
    }
    if (message.type !== '') {
      obj.type = message.type;
    }
    if (message.isVisible === true) {
      obj.isVisible = message.isVisible;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Insights>, I>>(base?: I): Insights {
    return Insights.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Insights>, I>>(object: I): Insights {
    const message = createBaseInsights();
    message.colors = object.colors ?? '';
    message.type = object.type ?? '';
    message.isVisible = object.isVisible ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
