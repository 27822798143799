import ReactGA from 'react-ga4';
import { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { HeaderContainerProps } from './HeaderContainer';
import { EmployeeFound } from '../../store/search';

const searchEmployeesDebounced = debounce((fn: (keyword: string) => void, keyword: string) => fn(keyword), 1000);

export const HeaderContainerLogic = ({
  history,
  searchEmployees,
  setIsImpersonationModalOpen,
}: HeaderContainerProps) => {
  const [searchResults, setSearchResults] = useState<EmployeeFound[]>([]);

  const handleSearchInputChange = useCallback((keyword: string) => {
      if (keyword) {
        ReactGA.event({ category: 'Search', action: 'Search employees' });
        searchEmployees(keyword)
          .then((res) => setSearchResults(res))
          .catch((err) => {
            console.error(err);
            setSearchResults([]);
          });
      }
    },
    [searchEmployees],
  );

  const handleSelectSearchOption = useCallback((employee: EmployeeFound) => {
    setSearchResults([]);
    history.push(`/${employee.username}`);
  }, []);

  const handleImpersonateClick = () => {
    setIsImpersonationModalOpen(true);
    ReactGA.event({ category: 'Impersonation', action: 'Clicked Impersonate menu item on Header' });
  };

  return {
    searchResults,
    handleSearchInputChange: (keyword: string) => searchEmployeesDebounced(handleSearchInputChange, keyword),
    handleSelectSearchOption,
    handleImpersonateClick,
  };
};
