import { useState, useEffect, useCallback, useMemo } from 'react';
import { BadgeMembersContainerProps } from './BadgeMembersContainer';
import ReactGA from 'react-ga4';

export const BadgeMembersContainerLogic = ({
  badge,
  badgeId,
  myBadges,
  loadBadgeWallDetail,
  addToMyBadges,
}: BadgeMembersContainerProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [addToMyBadgesStatus, setAddToMyBadgesStatus] = useState<
    'Idle' | 'Loading' | 'Error' | 'Success'
  >('Idle');
  const [isEditBadgeModalOpen, setIsEditBadgeModalOpen] = useState(false);

  const userHasBadge = useMemo(() => {
    return myBadges.some((b) => b.id === badgeId);
  }, [myBadges, badgeId]);

  const loadBadgeWallDetailCallback = useCallback(
    (badgeId: string): Promise<void> => {
      return loadBadgeWallDetail(badgeId);
    },
    [loadBadgeWallDetail],
  );

  const addToMyBadgesCallback = useCallback(() => {
    setAddToMyBadgesStatus('Loading');
    addToMyBadges(badge)
      .then(() => setAddToMyBadgesStatus('Success'))
      .catch(() => setAddToMyBadgesStatus('Error'));
    ReactGA.event({ category: 'Badges', action: 'Clicked Request this Badge button' });
  }, [badge, addToMyBadges]);

  const handleCloseModal = () => {
    setAddToMyBadgesStatus('Idle');
  };

  const openEditBadgeDetailsModal = () => {
    setIsEditBadgeModalOpen(true);
    ReactGA.event({ category: 'Badges', action: 'Clicked Edit badge details button' });
  };

  useEffect(() => {
    if (badgeId) {
      setIsLoading(true);
      loadBadgeWallDetailCallback(badgeId).then(() => setIsLoading(false));
    }
  }, [badgeId]);

  return {
    isLoading,
    userHasBadge,
    addToMyBadgesStatus,
    isEditBadgeModalOpen,
    openEditBadgeDetailsModal,
    setIsEditBadgeModalOpen,
    addToMyBadgesCallback,
    handleCloseModal,
  };
};
