import React from 'react';
import {
  Grid,
  Pagination,
  Flex
} from '@zillow/constellation';
import { LoadLimitDropdown } from '../LoadLimitDropdown';

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    setCurrentPage: (value: React.SetStateAction<number>) => void;
    perPage: number;
    setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
    totalItems: number;
}

export const PaginationSelect: React.FC<PaginationProps> = ({
    totalPages,
    currentPage,
    setCurrentPage,
    perPage,
    setItemsPerPage,
    totalItems
}: PaginationProps) => {

    const pageLimiter = (limit: number) => {
        setItemsPerPage(limit);
        setCurrentPage(0);
    }
    const gridRender = (totalPages: number) => {
        if (totalPages > 1){
            return (
                <Flex display="flex" alignItems="center">
                 <Flex flexGrow={3} order={1}>
                    <LoadLimitDropdown perPage={perPage} pageLimiter={pageLimiter} />
                </Flex>
                {totalPages > 1 && (
                    <Flex flexGrow={2} order={2} marginLeft="xs">
                        <Pagination
                        currentPageIndex={currentPage}
                        totalPages={totalPages}
                        onPageSelected={(pageIndex: number) => setCurrentPage(pageIndex)}
                        data-testid="pagination-component"
                        style={{ display: 'flex'}}
                        />
                    
                    </Flex>
                )}
             </Flex>)
        } else {
            return (
            <Grid gridColumnEnd="span 4" style={{ textAlign: 'right' }}>
                <LoadLimitDropdown perPage={perPage} pageLimiter={pageLimiter} />
            </Grid>
          )
        }
    }
    
    return(
        <Flex display="flex" justifyContent="center" style={{ width: '100%'}}>
            {totalItems > 0 && gridRender(totalPages)}
         </Flex>
    )
    };