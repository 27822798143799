import React from 'react';
import { Paragraph } from '@zillow/constellation';
import styled from 'styled-components';
import { QAs } from './QAs';

export interface FAQBodyProps {
  question: string;
  answer: React.ReactNode;
}

const QuestionParagraph = styled(Paragraph)`
  font-weight: bold;`

export const FAQbody = () => {
 const q = QAs;
  return (<>
      {q.map((item) => (
        <>
        <QuestionParagraph>{item.question}</QuestionParagraph>
        {item.answer}
        </>
      ))}
        </>
        
  );
};
