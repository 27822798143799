import ReactGA from 'react-ga4';
import { useCallback, useEffect, useState } from 'react';
import { DisplayError, ErrorType } from '../../store/errorHelpers';
import { GetMyImpersonateesPromiseResponse } from '../../store/impersonation';
import { EmployeeFound } from '../../store/search';
import {
  ImpersonationContainerProps,
  SearchOption,
  renderSearchOption,
} from './ImpersonationContainer';

export const ImpersonationContainerLogic = ({
  isImpersonationModalOpen,
  getMyImpersonatees,
  searchEmployees,
  impersonateUser,
  stopImpersonation,
  setIsImpersonationModalOpen,
}: ImpersonationContainerProps) => {
  const [areImpersonateesLoaded, setAreImpersonateesLoaded] = useState(false);
  const [isAllowedAll, setIsAllowedAll] = useState(false);
  const [allowedImpersonatees, setAllowedImpersonatees] = useState<SearchOption[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [searchOptions, setSearchOptions] = useState<SearchOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<SearchOption | undefined>(undefined);
  const [isRequestingImpersonation, setIsRequestingImpersonation] = useState(false);
  const [isStoppingImpersonation, setIsStoppingImpersonation] = useState(false);
  const [startImpersonationErr, setStartImpersonationErr] = useState(ErrorType.NONE);

  const employeeFoundToSearchOption = (employees: EmployeeFound[]): SearchOption[] => {
    /**
     * avoid matching value to label to bypass a Constellation bug where if
     * the search input matches the value completely, the option cannot be selected.
     */
    return employees.map((e) => ({
      id: e.id,
      label: e.fullName,
      value: e.id,
      employee: e,
      children: renderSearchOption(e),
    }));
  };

  /** load impersonatees when modal is first opened */
  useEffect(() => {
    if (isImpersonationModalOpen && !areImpersonateesLoaded) {
      getMyImpersonatees()
        .then((resp: GetMyImpersonateesPromiseResponse) => {
          setIsAllowedAll(resp.isAllowedAll);
          setAllowedImpersonatees(employeeFoundToSearchOption(resp.impersonatees));
        })
        .finally(() => setAreImpersonateesLoaded(true));
    }
  }, [isImpersonationModalOpen]);

  const handleOnInputChange = (keyword: string) => {
    setSearchInput(keyword);
    if (keyword.length > 1) {
      setIsLoadingOptions(true);
      searchEmployees(keyword)
        .then((employees: EmployeeFound[]) => {
          setSearchOptions(employeeFoundToSearchOption(employees));
        })
        .finally(() => setIsLoadingOptions(false));
    } else {
      setSearchOptions([]);
    }
  };

  const handleSelectOption = (option: SearchOption) => {
    setSelectedOption(option);
    setSearchInput('');
  };

  const handleResetSelectedOption = () => {
    setSelectedOption(undefined);
    setStartImpersonationErr(ErrorType.NONE);
  };

  const handleStartImpersonation = useCallback(() => {
    if (!selectedOption) return;
    setIsRequestingImpersonation(true);
    setStartImpersonationErr(ErrorType.NONE);

    impersonateUser(selectedOption.id)
      .then(() => location.reload())
      .catch((err: DisplayError) => setStartImpersonationErr(err.code))
      .finally(() => setIsRequestingImpersonation(false));
    ReactGA.event({ category: 'Impersonation', action: 'Clicked Start impersonation button' });
  }, [selectedOption, impersonateUser]);

  const handleStopImpersonation = () => {
    setIsStoppingImpersonation(true);
    stopImpersonation()
      .then(() => location.reload())
      .catch((err: DisplayError) => {
        // session expired, reload anyway to re-login
        if (err.code === ErrorType.HTTP_STATUS_401) {
          location.reload();
        }
      })
      .finally(() => setIsStoppingImpersonation(false));
    ReactGA.event({ category: 'Impersonation', action: 'Clicked Stop impersonating' });
  };

  const handleImpersonateClick = () => {
    setIsImpersonationModalOpen(true);
    ReactGA.event({
      category: 'Impersonation',
      action: 'Clicked Impersonate on Impersonation Banner',
    });
  };

  const handleCloseModal = () => {
    setIsImpersonationModalOpen(false);
    setSelectedOption(undefined);
    setStartImpersonationErr(ErrorType.NONE);
    setSearchOptions([]);
  };

  return {
    areImpersonateesLoaded,
    isAllowedAll,
    allowedImpersonatees,
    searchInput,
    isLoadingOptions,
    searchOptions,
    selectedOption,
    isRequestingImpersonation,
    isStoppingImpersonation,
    startImpersonationErr,
    handleOnInputChange,
    handleSelectOption,
    handleResetSelectedOption,
    handleStartImpersonation,
    handleStopImpersonation,
    handleImpersonateClick,
    handleCloseModal,
  };
};
