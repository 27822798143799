import {
  GetWallDetailResponse,
  UpdateEmployeeInsightsResponse,
  GiveKarmaResponse,
  GetEmployeeKarmasResponse,
  DeleteEmployeeKarmaResponse,
  UpdateEmployeeFunFactResponse,
  UpdateEmployeeFavoriteCoreValueResponse,
  UpdateEmployeePronounResponse,
  UpdateEmployeeNamePronunciationResponse,
  UpdateEmployeePrimaryContactNumberResponse,
  UpdateEmployeeLinkedInProfileUrlResponse,
} from '../../contract/zallengine_zallweb';
import { WallDetail, Karma, NamePronunciation } from './types';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { ZallEngineErrorResponse, GetErrorStatusType } from '../errorHelpers';

export const validateGetWallDetailResp = (
  resp: GetWallDetailResponse,
): [WallDetail, ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.data || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [{} as WallDetail, errResp];
  }

  // check required information
  const wallData = resp.data;
  if (!wallData.employee || !wallData.workInfo || !wallData.personalInfo) {
    errResp.code = StatusType.STATUS_TYPE_INTERNAL_ERROR;
    errResp.message = 'Missing require information in wall details';
    return [{} as WallDetail, errResp];
  }

  const wd: WallDetail = {
    ...wallData,
    employee: wallData.employee,
    workInfo: wallData.workInfo,
    personalInfo: wallData.personalInfo,
  };
  return [wd, errResp];
};

export const validateUpdateEmployeeNamePronunciationResp = (
  resp: UpdateEmployeeNamePronunciationResponse,
): [NamePronunciation, ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [{} as NamePronunciation, errResp];
  }

  if (!resp.namePronunciation) {
    return [{} as NamePronunciation, errResp];
  }

  return [resp.namePronunciation, errResp];
};

export const validateUpdateEmployeeInsightsResp = (
  resp: UpdateEmployeeInsightsResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateGiveKarmaResp = (
  resp: GiveKarmaResponse,
): [string, ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return ['', errResp];
  }

  return [resp.karmaId, errResp];
};

export const validateGetEmployeeKarmasResp = (
  resp: GetEmployeeKarmasResponse,
): [Karma[], ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.karmas || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.karmas, errResp];
};

export const validateDeleteKarmaResp = (
  resp: DeleteEmployeeKarmaResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateUpdateEmployeeFunFactResp = (
  resp: UpdateEmployeeFunFactResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateUpdateEmployeeFavoriteCoreValueResp = (
  resp: UpdateEmployeeFavoriteCoreValueResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateUpdateEmployeePronounResp = (
  resp: UpdateEmployeePronounResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateUpdateEmployeePrimaryContactNumberResp = (
  resp: UpdateEmployeePrimaryContactNumberResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateUpdateEmployeeLinkedinProfileUrlResp = (
  resp: UpdateEmployeeLinkedInProfileUrlResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};
