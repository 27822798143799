import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { debounce } from 'lodash';
import {
  Flex,
  Heading,
  Paragraph,
  Spacer,
  Button,
  AdornedInput,
  Input,
  IconSearch,
  Adornment
} from '@zillow/constellation';
import { LineBreak, UserCard } from '../InsightsTwinsContainer';
import { Interest } from '../../components/Interest';
import { AvatarDisk } from '../../components/AvatarDisk';
import { RootState, Interest as InterestType, Employee } from '../../store';
import {
  selectUserInterests,
  selectUserEmployeeInfo,
  addToMyInterests,
  removeFromMyInterests,
} from '../../store/user';
import {
  loadInterestWallDetail,
  selectTargetInterest,
  selectInterestFans,
} from '../../store/interest';
import { InterestMembersContainerLogic } from './InterestMembersContainer.hooks';
import { Loader } from '../../components/Loader';
import { HtmlTitle } from '../../components/HtmlTitle';
import DynamicConfig from '../../config/DynamicConfig';
import { PaginationSelect } from '../../components/PaginationSelect';

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface StateProps {
  /** zall wall user */
  myInfo: Employee;
  /** list of current user's interests */
  myInterests: InterestType[];
  /** interest to display */
  interest: InterestType;
  /** list of people who have this interest */
  members: Employee[];
  loadInterestWallDetail: (interestId: string) => Promise<void>;
  addToMyInterests: (interest: InterestType) => Promise<void>;
  removeFromMyInterests: (interest: InterestType) => Promise<void>;
}

interface OwnProps {
  /** interest id in path */
  interestId: string;
}

export type InterestMembersContainerProps = StateProps & OwnProps;

const MainInterestIcon = styled(Flex)`
  text-align: center;
`;

export function getMembersForPage(
  members: Employee[],
  currentPage: number,
  membersPerPage: number,
): Employee[] {
  return members.slice(currentPage * membersPerPage, (currentPage + 1) * membersPerPage);
}

const writeToGA = debounce(() => {
  ReactGA.event({ category: 'Interests', action: 'Filter Interest Members' });
}, 1000);

const InterestMembersContainerBase: React.FC<InterestMembersContainerProps> = (
  props: InterestMembersContainerProps,
) => {
  const [searchInput, setSearchInput] = useState('');
  const { interest } = props;
  const [interestMembersPerPage, setInterestMembersPerPage] = useState(10);
  const {
    isLoading,
    memberList,
    userHasInterest,
    addToMyInterestsCallback,
    removeFromMyInterestsCallback,
  } = InterestMembersContainerLogic(props);
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();

  const searchMembers = (keyword: string) => {
    const regex = new RegExp(keyword, 'i'); // 'i' for case-insensitive
    return memberList.filter((member) => {
      return regex.test(member.fullName);
    });
  };

  const filteredMembers = searchInput ? searchMembers(searchInput) : memberList;
  const totalPages = Math.ceil(filteredMembers.length / interestMembersPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const membersForPage = getMembersForPage(filteredMembers, currentPage, interestMembersPerPage);

  return (
    <Loader loading={isLoading}>
      <HtmlTitle>
        <title>{`${interest.name} - Interest - ZallWall`}</title>
      </HtmlTitle>
      <Flex display="flex" data-testid="interest-members-container">
        <MainInterestIcon flex="1">
          <Interest
            name={interest.name}
            iconUrl={`${ZALL_ENGINE_PROXY}${interest.iconUrl}`}
            size="200px"
          />
        </MainInterestIcon>
        <Flex flex="2">
          <Heading level="3">{interest.name}</Heading>
          <Paragraph marginY="sm">{interest.description}</Paragraph>
          {userHasInterest ? (
            <Button
              buttonType="tertiary"
              marginY="sm"
              onClick={() => removeFromMyInterestsCallback(interest)}
              data-testid="remove-interest-btn"
            >
              Remove interest
            </Button>
          ) : (
            <Button
              buttonType="tertiary"
              marginY="sm"
              onClick={() => addToMyInterestsCallback(interest)}
              data-testid="add-interest-btn"
            >
              Add interest
            </Button>
          )}
        </Flex>
      </Flex>
      <LineBreak />
      <Heading level="4" marginLeft="sm">
        Fans
      </Heading>

      <Flex display="flex" justifyContent="center" style={{ width: '100%' }}>
        <Flex
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{ width: '50%' }}
        >
          {memberList.length > 0 && (
          <AdornedInput
            style={{ width: '300px' }}
            input={
              <Input
                placeholder="Filter fans"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentPage(0);
                  setSearchInput(event.target.value);
                  writeToGA();
                }}
                data-testid="interest-fans-searchbox-input"
              />
            }
            leftAdornment={
              <Adornment>
                <IconSearch />
              </Adornment>
            }
          />
          )}
        </Flex>
      </Flex>
      <Spacer margin="sm" />
        <Flex display="flex" flexWrap="wrap" justifyContent="center" style={{ width: '100%'}}>
          {memberList.length === 0 && (
            <Paragraph>No fans for <strong>{interest.name}</strong> yet!</Paragraph>
          )}
          {membersForPage.map((member, idx) => (
              <UserCard key={idx}>
                <AvatarDisk
                  fullName={member.fullName}
                  size="lg"
                  photoUrl={member.photoUrl && `${ZALL_ENGINE_PROXY}${member.photoUrl}`}
                  link={member.username ? `/${member.username}` : '/'}
                  showName
                  gaCategory="Interests"
                  gaAction="Clicked on Interest Fans"
                />
              </UserCard>
          ))}
          </Flex>
          <Spacer margin="sm" />
          <PaginationSelect 
            perPage={interestMembersPerPage}
            setItemsPerPage={setInterestMembersPerPage} 
            totalPages={totalPages} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            totalItems={memberList.length}
            />

    </Loader>
  );
};

const mapStateToProps = (state: RootState) => ({
  myInfo: selectUserEmployeeInfo(state),
  myInterests: selectUserInterests(state),
  interest: selectTargetInterest(state),
  members: selectInterestFans(state),
});

const mapDispatchToProps = {
  loadInterestWallDetail: loadInterestWallDetail,
  addToMyInterests: addToMyInterests,
  removeFromMyInterests: removeFromMyInterests,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const InterestMembersContainer = connect<
  StateToPropsType,
  DispatchToPropsType,
  OwnProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps,
)(InterestMembersContainerBase);

export { InterestMembersContainer as default, InterestMembersContainerBase };
