import React, { useState, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { Employee } from '../../store';
import { EmployeeFound } from '../../store/search';
import { BadgeMembersContainerProps } from './BadgeMembersContainer';
import { SearchOption, ButtonTextType } from './BadgeAdminSearchbox';
import { Toast } from '@zillow/constellation';

export const BadgeMembersAdminLogic = ({
  username,
  badgeId,
  members,
  searchEmployees,
  addBadgeMember,
  removeBadgeMember,
  enqueueToast,
  loadMyBadges,
}: BadgeMembersContainerProps) => {
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [searchOptions, setSearchOptions] = useState([] as SearchOption[]);
  const [loadingMemberIds, setLoadingMemberIds] = useState([] as string[]);

  const employeeHasBadge = useCallback(
    (employeeId: string) => {
      return members.some((m) => m.id === employeeId);
    },
    [members],
  );

  const handleOnInputChange = useCallback(
    (keyword: string) => {
      if (keyword.length > 1) {
        setIsLoadingOptions(true);

        searchEmployees(keyword).then((employees: EmployeeFound[]) => {
          const res: SearchOption[] = employees.map((employee) => ({
            value: employee.id,
            label: employee.fullName,
            employee: employee,
            loading: false,
            buttonText: employeeHasBadge(employee.id) ? 'Remove' : 'Add',
          }));
          setSearchOptions(res);
          setIsLoadingOptions(false);
        });
      } else {
        setSearchOptions([]);
      }
    },
    [employeeHasBadge],
  );

  /** updates a search option with new values, then updates searchOptions with the new array */
  const updateSearchOptions = useCallback(
    (optValue: string, newButtonText: ButtonTextType, newLoading: boolean) => {
      const options = searchOptions.map((opt) =>
        opt.value == optValue
          ? {
              ...opt,
              buttonText: newButtonText,
              loading: newLoading,
            }
          : {
              ...opt,
            },
      );
      setSearchOptions(options);
    },
    [searchOptions],
  );

  /** updates user badges in store if necessary */
  const updateUserBadges = useCallback(
    (member: Employee) => {
      if (member.username === username) {
        loadMyBadges();
      }
    },
    [username],
  );

  const handleSearchOptionCTA = useCallback(
    (props: SearchOption) => {
      updateSearchOptions(props.value, props.buttonText, true);

      if (props.buttonText === 'Add') {
        ReactGA.event({ category: 'Badges', action: 'Search and Add Badge Member' });
        addBadgeMember(props.employee as Employee, badgeId)
          .then(() => {
            updateSearchOptions(props.value, 'Added', false);
            updateUserBadges(props.employee as Employee);
          })
          .catch(() => updateSearchOptions(props.value, 'Error', false));
      } else if (props.buttonText === 'Remove') {
        ReactGA.event({ category: 'Badges', action: 'Search and Remove Badge Member' });
        removeBadgeMember(props.employee as Employee, badgeId)
          .then(() => {
            updateSearchOptions(props.value, 'Removed', false);
            updateUserBadges(props.employee as Employee);
          })
          .catch(() => updateSearchOptions(props.value, 'Error', false));
      }
    },
    [badgeId, searchOptions],
  );

  const handleRemoveMember = useCallback(
    (member: Employee) => {
      ReactGA.event({ category: 'Badges', action: 'Clicked Remove Badge Member' });
      setLoadingMemberIds([...loadingMemberIds, member.id]);
      removeBadgeMember(member, badgeId)
        .then(() => {
          enqueueToast(
            <Toast
              appearance="success"
              body={`${member.fullName} has been removed from the badge`}
            />,
          );
          updateUserBadges(member);
        })
        .catch(() =>
          enqueueToast(
            <Toast appearance="error" body={`Error removing ${member.fullName} from badge`} />,
          ),
        )
        .finally(() => setLoadingMemberIds([...loadingMemberIds.filter((i) => i !== member.id)]));
    },
    [badgeId, loadingMemberIds],
  );

  return {
    isLoadingOptions,
    searchOptions,
    loadingMemberIds,
    handleOnInputChange,
    handleSearchOptionCTA,
    handleRemoveMember,
  };
};
