import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RpcImpl } from '../../contract/rpc';
import {
  ZallWebClientImpl,
  GetBadgeWallDetailRequest,
  GetBadgeWallDetailResponse,
  GetAllBadgesRequest,
  GetAllBadgesResponse,
  SubmitNewBadgeRequest,
  SubmitNewBadgeResponse,
  AddBadgeAssigneeRequest,
  AddBadgeAssigneeResponse,
  RemoveBadgeAssigneeRequest,
  RemoveBadgeAssigneeResponse,
  UpdateBadgeDetailRequest,
  UpdateBadgeDetailResponse,
} from '../../contract/zallengine_zallweb';
import {
  BadgeState,
  BadgeAction,
  LoadAllBadgesSuccess,
  LoadBadgeWallDetailSuccess,
  LoadBadgeError,
  SubmitNewBadgeAction,
  SubmitNewBadgeSuccess,
  SubmitNewBadgeError,
  EditBadgeMemberAction,
  UpdateBadgeDetailSuccess,
  UpdateBadgeDetailError,
  UpdateBadgeDetailAction,
} from './types';
import {
  validateGetBadgeWallDetailResp,
  validateGetAllBadgesResp,
  validateSubmitNewBadgeResp,
  validateAddBadgeAssigneeResp,
  validateRemoveBadgeAssigneeResp,
  validateUpdateBadgeDetailResp,
} from './validators';
import DynamicConfig from '../../config/DynamicConfig';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { Employee, Badge } from '../index';
import { GetDisplayError } from '../errorHelpers';
import { BadgeApplication } from '../../store/admin';

/**
 *
export type ThunkAction<R, S, E, A extends Action> = (
  dispatch: ThunkDispatch<S, E, A>,
  getState: () => S,
  extraArgument: E
) => R;
 */

export const loadAllBadgesSuccess = (badges: Badge[]): LoadAllBadgesSuccess => ({
  type: 'LOAD_ALL_BADGES_SUCCESS',
  badges: badges,
});

export const loadBadgeWallDetailSuccess = (
  badge: Badge,
  owner: Employee | undefined,
  members: Employee[],
): LoadBadgeWallDetailSuccess => ({
  type: 'LOAD_BADGE_WALL_DETAIL_SUCCESS',
  badge: badge,
  owner: owner,
  members: members,
});

export const loadBadgeError = (msg: string): LoadBadgeError => ({
  type: 'LOAD_BADGE_ERROR',
  msg,
});

export const loadBadgeWallDetail = (
  badgeId: string,
): ThunkAction<Promise<void>, BadgeState, null, BadgeAction> => async (
  dispatch: ThunkDispatch<BadgeState, null, BadgeAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: GetBadgeWallDetailRequest = {
    badgeId: badgeId,
  };

  return client
    .GetBadgeWallDetail(req)
    .then((resp: GetBadgeWallDetailResponse) => {
      const [badge, owner, members, err] = validateGetBadgeWallDetailResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(loadBadgeWallDetailSuccess(badge, owner, members));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(loadBadgeError(errResp.message));
      return Promise.reject(errResp);
    });
};

export const loadAllBadges = (): ThunkAction<
  Promise<void>,
  BadgeState,
  null,
  BadgeAction
> => async (dispatch: ThunkDispatch<BadgeState, null, BadgeAction>): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: GetAllBadgesRequest = {};

  return client
    .GetAllBadges(req)
    .then((resp: GetAllBadgesResponse) => {
      const [badges, err] = validateGetAllBadgesResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(loadAllBadgesSuccess(badges));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(loadBadgeError(errResp.message));
      return Promise.reject(errResp);
    });
};

export const submitNewBadgeSuccess = (): SubmitNewBadgeSuccess => ({
  type: 'SUBMIT_NEW_BADGE_SUCCESS',
});

export const submitNewBadgeError = (msg: string): SubmitNewBadgeError => ({
  type: 'SUBMIT_NEW_BADGE_ERROR',
  msg: msg,
});

export const submitNewBadge = (
  application: BadgeApplication,
): ThunkAction<Promise<void>, BadgeState, null, SubmitNewBadgeAction> => async (
  dispatch: ThunkDispatch<BadgeState, null, SubmitNewBadgeAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: SubmitNewBadgeRequest = {
    application: application,
  };

  return client
    .SubmitNewBadge(req)
    .then((resp: SubmitNewBadgeResponse) => {
      const err = validateSubmitNewBadgeResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(submitNewBadgeSuccess());
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(submitNewBadgeError(errResp.message));
      return Promise.reject(errResp);
    });
};

const addBadgeMemberSuccess = (employee: Employee): EditBadgeMemberAction => ({
  type: 'ADD_BADGE_MEMBER_SUCCESS',
  employee: employee,
});

export const addBadgeMember = (
  employee: Employee,
  badgeId: string,
): ThunkAction<Promise<void>, BadgeState, null, EditBadgeMemberAction> => async (
  dispatch: ThunkDispatch<BadgeState, null, EditBadgeMemberAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: AddBadgeAssigneeRequest = {
    employeeId: employee.id,
    badgeId: badgeId,
  };

  return client
    .AddBadgeAssignee(req)
    .then((resp: AddBadgeAssigneeResponse) => {
      const err = validateAddBadgeAssigneeResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(addBadgeMemberSuccess(employee));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      return Promise.reject(errResp);
    });
};

const removeBadgeMemberSuccess = (employee: Employee): EditBadgeMemberAction => ({
  type: 'REMOVE_BADGE_MEMBER_SUCCESS',
  employee: employee,
});

export const removeBadgeMember = (
  employee: Employee,
  badgeId: string,
): ThunkAction<Promise<void>, BadgeState, null, EditBadgeMemberAction> => async (
  dispatch: ThunkDispatch<BadgeState, null, EditBadgeMemberAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: RemoveBadgeAssigneeRequest = {
    employeeId: employee.id,
    badgeId: badgeId,
  };

  return client
    .RemoveBadgeAssignee(req)
    .then((resp: RemoveBadgeAssigneeResponse) => {
      const err = validateRemoveBadgeAssigneeResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(removeBadgeMemberSuccess(employee));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      return Promise.reject(errResp);
    });
};

export const updateBadgeDetailSuccess = (badge: Badge): UpdateBadgeDetailSuccess => ({
  type: 'UPDATE_BADGE_DETAIL_SUCCESS',
  badge: badge,
});

export const updateBadgeDetailError = (msg: string): UpdateBadgeDetailError => ({
  type: 'UPDATE_BADGE_DETAIL_ERROR',
  msg: msg,
});

export const updateBadgeDetail = (
  id: string,
  name: string,
  description: string,
  iconImage: Uint8Array,
): ThunkAction<Promise<void>, BadgeState, null, UpdateBadgeDetailAction> => async (
  dispatch: ThunkDispatch<BadgeState, null, UpdateBadgeDetailAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: UpdateBadgeDetailRequest = {
    badgeId: id,
    name: name,
    description: description,
    iconImage: iconImage,
  };

  return client
    .UpdateBadgeDetail(req)
    .then((resp: UpdateBadgeDetailResponse) => {
      const [badge, err] = validateUpdateBadgeDetailResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(updateBadgeDetailSuccess(badge));
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(updateBadgeDetailError(errResp.message));
      return Promise.reject(errResp);
    });
};
