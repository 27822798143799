import React, { useEffect } from 'react';
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../store';
import { UserInfo, selectLoggedIn, loadUserByCookie, resetUser } from '../store/user';
import { Loader } from '../components/Loader';
import DynamicConfig from '../config/DynamicConfig';

export interface AuthMiddlewareProps extends RouteComponentProps {
  /** is user logged in */
  loggedIn: boolean;
  /** function to load user info */
  loadUser: () => Promise<UserInfo>;
  /** function to reset user info */
  resetUser: typeof resetUser;
  /** standard children prop */
  children?: React.ReactNode;
}

export const deleteJWTCookie = () => {
  document.cookie = 'jwt=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
};

export const getJWTCookie = (): string => {
  const cookieName = 'jwt';
  return (
    document.cookie.split(';').find(
      // check for leading whitespace in case of multiple cookies
      (c) => c.startsWith(`${cookieName}`) || c.startsWith(` ${cookieName}`),
    ) || ''
  );
};

const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({
  history,
  location,
  loggedIn,
  loadUser,
  resetUser,
  children,
}: AuthMiddlewareProps) => {
  const pathRequiresAuth = location.pathname !== '/login' && location.pathname !== '/login/';
  const { AUTH_ENABLED, MAINTENANCE_MODE } = DynamicConfig.GetConfig();
  const authRequired = pathRequiresAuth && AUTH_ENABLED && !MAINTENANCE_MODE;
  const jwtCookie = getJWTCookie();
  const isLoading = !loggedIn && !!authRequired;

  /** reset user if cookie expired during session */
  useEffect(() => {
    if (authRequired && jwtCookie === '' && loggedIn) {
      resetUser();
    }
  });

  /** initiate new login when redirected from Okta */
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const fromOktaTile = urlParams.get('iss');
    if (fromOktaTile) {
      deleteJWTCookie();
    }
  }, []);

  /** load user from cookie */
  useEffect(() => {
    if (authRequired && jwtCookie !== '') {
      loadUser().catch(() => {
        deleteJWTCookie();
        history.push('/login', { from: location.pathname });
      });
    }
  }, []);

  if (authRequired && jwtCookie === '') {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location.pathname },
        }}
      />
    );
  }

  return <Loader loading={isLoading}>{children}</Loader>;
};

const mapStateToProps = (state: RootState) => ({
  loggedIn: selectLoggedIn(state),
});

const mapDispatchToProps = {
  loadUser: loadUserByCookie,
  resetUser: resetUser,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

/* eslint-disable */
export default withRouter(
  connect<StateToPropsType, DispatchToPropsType, {}, RootState>(
    mapStateToProps,
    mapDispatchToProps,
  )(AuthMiddleware),
);
