export class FileUtils {
  /** returns the width & height of a valid image */
  public static GetImageFileDimensions(file: File): Promise<{ width: number; height: number }> {
    return new Promise<{ width: number; height: number }>((resolve) => {
      try {
        const img = new Image();

        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);
          resolve({ width, height });
        };

        img.onerror = () => {
          resolve({ width: 0, height: 0 });
        };

        img.src = window.URL.createObjectURL(file);
      } catch (err) {
        resolve({ width: 0, height: 0 });
      }
    });
  }
}
