import { useEffect, useCallback } from 'react';
import { BadgePagesProps } from './BadgePages';

export const BadgePagesLogic = ({ loadMyBadges }: BadgePagesProps) => {
  const loadMyBadgesCallback = useCallback(() => {
    loadMyBadges();
  }, [loadMyBadges]);

  useEffect(() => {
    loadMyBadgesCallback();
  }, []);
};
