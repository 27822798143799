import { Flex, Heading, spaceMixin } from '@zillow/constellation';
import React from 'react';
import Lottie from 'react-lottie-player';
import styled from 'styled-components';
import lottieJson from '../../assets/undermaintenance/under-maintenance.json';
import { Banner } from '../../components/Banner';

const Wrapper = styled(Flex)`
  text-align: center;
`;

const ContentWrapper = styled(Flex)`
  max-width: 65vh;
  margin: ${spaceMixin('md')} auto;
`;

const UnderMaintenance: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <Banner>
        <Wrapper flexDirection="column" alignItems="center">
          <Heading fontColor="textWhite" level="3" marginY="xl">
            System Under Maintenance
          </Heading>
        </Wrapper>
      </Banner>
      <ContentWrapper justifyContent="center">
        <Lottie loop animationData={lottieJson} play speed={1} />
      </ContentWrapper>
    </React.Fragment>
  );
};
export default UnderMaintenance;
