/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Timestamp } from './google/protobuf/timestamp';
import { Employee } from './zallengine_employee';
import { CoreValue, LeadershipBlueprint } from './zallengine_zg101';

export const protobufPackage = 'zallengine_contract';

export interface Karma {
  id: string;
  sender: Employee | undefined;
  receiver: Employee | undefined;
  sentDate: Date | undefined;
  message: string;
  coreValue: CoreValue | undefined;
  leadershipBlueprint: LeadershipBlueprint | undefined;
}

function createBaseKarma(): Karma {
  return {
    id: '0',
    sender: undefined,
    receiver: undefined,
    sentDate: undefined,
    message: '',
    coreValue: undefined,
    leadershipBlueprint: undefined,
  };
}

export const Karma = {
  encode(message: Karma, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.sender !== undefined) {
      Employee.encode(message.sender, writer.uint32(18).fork()).ldelim();
    }
    if (message.receiver !== undefined) {
      Employee.encode(message.receiver, writer.uint32(26).fork()).ldelim();
    }
    if (message.sentDate !== undefined) {
      Timestamp.encode(toTimestamp(message.sentDate), writer.uint32(34).fork()).ldelim();
    }
    if (message.message !== '') {
      writer.uint32(42).string(message.message);
    }
    if (message.coreValue !== undefined) {
      CoreValue.encode(message.coreValue, writer.uint32(50).fork()).ldelim();
    }
    if (message.leadershipBlueprint !== undefined) {
      LeadershipBlueprint.encode(message.leadershipBlueprint, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Karma {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKarma();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sender = Employee.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.receiver = Employee.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sentDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.message = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.coreValue = CoreValue.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.leadershipBlueprint = LeadershipBlueprint.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Karma {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : '0',
      sender: isSet(object.sender) ? Employee.fromJSON(object.sender) : undefined,
      receiver: isSet(object.receiver) ? Employee.fromJSON(object.receiver) : undefined,
      sentDate: isSet(object.sentDate) ? fromJsonTimestamp(object.sentDate) : undefined,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      coreValue: isSet(object.coreValue) ? CoreValue.fromJSON(object.coreValue) : undefined,
      leadershipBlueprint: isSet(object.leadershipBlueprint)
        ? LeadershipBlueprint.fromJSON(object.leadershipBlueprint)
        : undefined,
    };
  },

  toJSON(message: Karma): unknown {
    const obj: any = {};
    if (message.id !== '0') {
      obj.id = message.id;
    }
    if (message.sender !== undefined) {
      obj.sender = Employee.toJSON(message.sender);
    }
    if (message.receiver !== undefined) {
      obj.receiver = Employee.toJSON(message.receiver);
    }
    if (message.sentDate !== undefined) {
      obj.sentDate = message.sentDate.toISOString();
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.coreValue !== undefined) {
      obj.coreValue = CoreValue.toJSON(message.coreValue);
    }
    if (message.leadershipBlueprint !== undefined) {
      obj.leadershipBlueprint = LeadershipBlueprint.toJSON(message.leadershipBlueprint);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Karma>, I>>(base?: I): Karma {
    return Karma.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Karma>, I>>(object: I): Karma {
    const message = createBaseKarma();
    message.id = object.id ?? '0';
    message.sender =
      object.sender !== undefined && object.sender !== null
        ? Employee.fromPartial(object.sender)
        : undefined;
    message.receiver =
      object.receiver !== undefined && object.receiver !== null
        ? Employee.fromPartial(object.receiver)
        : undefined;
    message.sentDate = object.sentDate ?? undefined;
    message.message = object.message ?? '';
    message.coreValue =
      object.coreValue !== undefined && object.coreValue !== null
        ? CoreValue.fromPartial(object.coreValue)
        : undefined;
    message.leadershipBlueprint =
      object.leadershipBlueprint !== undefined && object.leadershipBlueprint !== null
        ? LeadershipBlueprint.fromPartial(object.leadershipBlueprint)
        : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === 'string') {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
