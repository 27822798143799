import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RpcImpl } from '../../contract/rpc';
import {
  ZallWebClientImpl,
  GetInterestWallDetailRequest,
  GetInterestWallDetailResponse,
  GetAllInterestsRequest,
  GetAllInterestsResponse,
  CreateInterestRequest,
  CreateInterestResponse,
} from '../../contract/zallengine_zallweb';
import {
  InterestState,
  InterestAction,
  LoadAllInterestsSuccess,
  LoadInterestFansSuccess,
  LoadInterestError,
  CreateInterestSuccess,
  CreateInterestError,
} from './types';
import {
  validateGetInterestWallDetailResp,
  validateGetAllInterestsResp,
  validateCreateInterestResp,
} from './validators';
import DynamicConfig from '../../config/DynamicConfig';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { Employee, Interest } from '../index';
import { GetDisplayError } from '../errorHelpers';

/**
 *
export type ThunkAction<R, S, E, A extends Action> = (
  dispatch: ThunkDispatch<S, E, A>,
  getState: () => S,
  extraArgument: E
) => R;
 */

export const loadAllInterestsSuccess = (interests: Interest[]): LoadAllInterestsSuccess => ({
  type: 'LOAD_ALL_INTERESTS_SUCCESS',
  interests: interests,
});

export const loadInterestFansSuccess = (
  fans: Employee[],
  interest: Interest,
): LoadInterestFansSuccess => ({
  type: 'LOAD_INTEREST_FANS_SUCCESS',
  fans: fans,
  interest: interest,
});

export const loadInterestError = (msg: string): LoadInterestError => ({
  type: 'LOAD_INTEREST_ERROR',
  msg,
});

export const loadInterestWallDetail = (
  interestId: string,
): ThunkAction<Promise<void>, InterestState, null, InterestAction> => async (
  dispatch: ThunkDispatch<InterestState, null, InterestAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: GetInterestWallDetailRequest = {
    interestId: interestId,
  };

  return client
    .GetInterestWallDetail(req)
    .then((resp: GetInterestWallDetailResponse) => {
      const [fans, interest, err] = validateGetInterestWallDetailResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(loadInterestFansSuccess(fans, interest));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(loadInterestError(errResp.message));
      return Promise.reject(errResp);
    });
};

export const loadAllInterests = (): ThunkAction<
  Promise<void>,
  InterestState,
  null,
  InterestAction
> => async (dispatch: ThunkDispatch<InterestState, null, InterestAction>): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: GetAllInterestsRequest = {};

  return client
    .GetAllInterests(req)
    .then((resp: GetAllInterestsResponse) => {
      const [interests, err] = validateGetAllInterestsResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(loadAllInterestsSuccess(interests));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(loadInterestError(errResp.message));
      return Promise.reject(errResp);
    });
};

export const createInterestSuccess = (): CreateInterestSuccess => ({
  type: 'CREATE_INTEREST_SUCCESS',
});

export const createInterestError = (msg: string): CreateInterestError => ({
  type: 'CREATE_INTEREST_ERROR',
  msg: msg,
});

export const createInterest = (
  name: string,
  description: string,
  iconImage: Uint8Array,
): ThunkAction<Promise<string>, InterestState, null, InterestAction> => async (
  dispatch: ThunkDispatch<InterestState, null, InterestAction>,
): Promise<string> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: CreateInterestRequest = {
    name: name,
    description: description,
    iconImage: iconImage,
  };

  return client
    .CreateInterest(req)
    .then((resp: CreateInterestResponse) => {
      const [interestId, err] = validateCreateInterestResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(createInterestSuccess());
        return Promise.resolve(interestId);
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(createInterestError(errResp.message));
      return Promise.reject(errResp);
    });
};
