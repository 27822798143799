import { useState, useEffect, useCallback } from 'react';
import { AllInterestsContainerProps } from './AllInterestsContainer';

export const AllInterestsContainerLogic = ({ loadAllInterests }: AllInterestsContainerProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const loadAllInterestsCallback = useCallback((): Promise<void> => {
    return loadAllInterests();
  }, [loadAllInterests]);

  useEffect(() => {
    setIsLoading(true);
    loadAllInterestsCallback().then(() => setIsLoading(false));
  }, []);

  return { isLoading };
};
