import { useState, useCallback, useEffect } from 'react';
import { BadgeType } from '../../contract/zallengine_badge';
import { BadgeApplication } from '../../store/admin';
import { BadgeRequestContainerProps, BadgeFormValues } from './BadgeRequestContainer';
import { FileUtils } from '../../utilities/fileUtils';
import { useForm } from 'react-hook-form';

export const MAX_BADGE_ICON_KB = 200;
export const BADGE_ICON_HEIGHT_PX = 500;
export const BADGE_ICON_WIDTH_PX = 500;

export const validateFileDimensions = async (file: File) => {
  const dimensions = await FileUtils.GetImageFileDimensions(file);
  return dimensions.width === BADGE_ICON_WIDTH_PX && dimensions.height === BADGE_ICON_HEIGHT_PX;
};

export const validateFileSize = (file: File) => {
  return file.size <= MAX_BADGE_ICON_KB * 1000;
};

export const BadgeRequestContainerLogic = ({ submitNewBadge }: BadgeRequestContainerProps) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<BadgeFormValues>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsUploading(false);
    setSubmitError(false);
    setSubmitSuccess(false);

    setIsModalOpen(false);
  }, []);

  /** reset form when modal is opened */
  useEffect(() => {
    if (isModalOpen) {
      reset();
    }
  }, [isModalOpen]);

  const submitNewBadgeCallback = useCallback(async (data: BadgeFormValues) => {
    return data.badgeImg
      .arrayBuffer()
      .then((buffer) => {
        const application: BadgeApplication = {
          name: data.badgeName,
          description: data.badgeDesc,
          businessJustification: data.bizJustif,
          type: BadgeType.BADGE_TYPE_UNSPECIFIED,
          estNumOfAssignees: data.numEmployees.toString(),
          iconImage: new Uint8Array(buffer),
        };
        return submitNewBadge(application)
          .then(() => Promise.resolve())
          .catch((err) => Promise.reject(err));
      })
      .catch((err) => Promise.reject(err));
  }, []);

  const onSubmit = useCallback((data: BadgeFormValues) => {
    setSubmitError(false);

    setIsUploading(true);
    submitNewBadgeCallback(data)
      .then(() => {
        setSubmitSuccess(true);
      })
      .catch(() => setSubmitError(true))
      .finally(() => setIsUploading(false));
  }, []);

  return {
    isModalOpen,
    isUploading,
    submitSuccess,
    submitError,
    openModal,
    closeModal,
    onSubmit,
    validateFileDimensions,
    validateFileSize,
    register,
    handleSubmit,
    control,
    errors,
  };
};
