import { createSelector, Selector } from 'reselect';
import { RootState, ImpersonationState } from '../index';
import { UserInfo } from '../user';

export const selectImpersonationState = (state: RootState): ImpersonationState =>
  state.impersonation;

export const selectIsImpersonating: Selector<RootState, boolean> = createSelector(
  [selectImpersonationState],
  (impersonationState) => {
    return impersonationState.isImpersonating;
  },
);

export const selectImpersonator: Selector<RootState, UserInfo | undefined> = createSelector(
  [selectImpersonationState],
  (impersonationState) => {
    return impersonationState.impersonator;
  },
);
