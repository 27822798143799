export class DateUtils {
  /** formats a date to a string, eg. 'Feb 3, 2021' */
  public static FormatDateToString(d: Date): string {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const monthName = months[d.getUTCMonth()];
    return `${monthName} ${d.getUTCDate()}, ${d.getUTCFullYear()}`;
  }

  /** formats a date to an ISO string, eg. '2021/02/03' */
  public static FormatDateToISOString(d: Date): string {
    return d.toISOString().split('T')[0];
  }

  /** days difference between 2 dates, e.g. 9:00 01/02/2020 and 18:00 01/03/2020 -> 1 day difference */
  public static DaysDiff(d1: Date, d2: Date): number {
    return Math.floor(Math.abs(d2.getTime() - d1.getTime()) / (1000 * 60 * 60 * 24));
  }

  /** checks if a date falls between two dates (inclusive) */
  public static IsDateBetween(dateToCheck: Date, d1: Date, d2: Date): boolean {
    return dateToCheck >= d1 && dateToCheck <= d2;
  }

  /**
   *
   * @param startDate start date in 01/01 format
   * @param endDate end date in 01/01 format
   * @param crossesYears if the date range crosses to a new year (e.g. Dec -> Jan)
   * @returns if today's date is within the date range (inclusive)
   */
  public static IsTodayInDateRange = (
    startDate: string,
    endDate: string,
    crossesYears: boolean,
  ) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return crossesYears
      ? today >= new Date(startDate + `/${today.getFullYear()}`) ||
          today <= new Date(endDate + `/${today.getFullYear()}`)
      : today >= new Date(startDate + `/${today.getFullYear()}`) &&
          today <= new Date(endDate + `/${today.getFullYear()}`);
  };
}
