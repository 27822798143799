import React from 'react';
import styled from 'styled-components';
import {
  Paragraph,
  TextButton,
  token,
  fontSizeMixin,
  lineHeightMixin,
  spaceMixin,
  mediaBreakpointMixin,
} from '@zillow/constellation';
import { PersonCard } from './PersonCard';
import { InsightsImage } from '../Insights';

export interface TeamMemberCardProps {
  /** person's full name */
  fullName: string;
  /** person's username */
  username: string;
  /** person's job title */
  title: string;
  /** person's email */
  email: string;
  /** number of person's reportees */
  totalReportsCount: string;
  /** person's photo */
  photoUrl?: string;
  /** person's leading insights color */
  insightColor?: string;
}

const PersonCardWrapper = styled.div`
  margin: ${spaceMixin('xs')};
`;

const InsightBlock = styled.img`
  width: 45px;
  margin-bottom: -${spaceMixin('xs')};
`;

const PersonInfoContainer = styled.div`
  @media ${mediaBreakpointMixin('xs')} {
    width: 200px;
  }
  @media ${mediaBreakpointMixin('sm')} {
    width: 220px;
  }
  @media ${mediaBreakpointMixin('md')} {
    width: 240px;
  }
  @media ${mediaBreakpointMixin('lg')} {
    width: 250px;
  }
  width: 260px;
`;

const Text = styled(Paragraph)`
  font-size: ${fontSizeMixin('body')};
  line-height: ${lineHeightMixin('finePrint')};
`;

const Link = styled(TextButton)`
  display: block;
  width: fit-content;
  text-decoration: none;
  color: ${token('colors.blue400')};
  font-size: ${fontSizeMixin('body')};
`;

const Italic = styled(Text)`
  font-style: italic;
  font-size: ${fontSizeMixin('bodySmall')};
  line-height: ${lineHeightMixin('bodySmall')};
`;

export const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
  fullName,
  username,
  title,
  email,
  totalReportsCount,
  photoUrl,
  insightColor,
}: TeamMemberCardProps) => {
  return (
    <PersonCardWrapper data-testid="render-teammate-card-test">
      <PersonCard
        data-testid="render-person-card-test"
        fullName={fullName}
        username={username}
        photoUrl={photoUrl}
        cardLabel={insightColor && <InsightBlock alt={''} src={InsightsImage(insightColor)} />}
        cardBody={
          <PersonInfoContainer data-testid="render-person-info-test">
            <Text data-testid="render-title-test">{title}</Text>
            <Link data-testid="render-link-test" as="a" href={`mailto:${email}`}>
              {email}
            </Link>
            {totalReportsCount !== '0' && (
              <Italic fontColor="textLight">Has team of {totalReportsCount}</Italic>
            )}
          </PersonInfoContainer>
        }
        gaCategory="Person Card"
        gaAction="Clicked on Team Member Card"
      />
    </PersonCardWrapper>
  );
};
