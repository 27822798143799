import { ImpersonationState, ImpersonationAction } from './types';

export const initialState: ImpersonationState = {
  isImpersonating: false,
  impersonator: undefined,
};

export const impersonationReducer = (
  state = initialState,
  action: ImpersonationAction,
): ImpersonationState => {
  switch (action.type) {
    case 'START_IMPERSONATION_MODE':
      return {
        ...state,
        isImpersonating: true,
        impersonator: action.impersonator,
      };
    case 'STOP_IMPERSONATION_SUCCESS':
      return {
        ...state,
        isImpersonating: false,
      };
    default:
      return state;
  }
};
