import { Action } from 'redux';
import {
  PendingBadge as PendingBadgeContract,
  BadgeApplication as BadgeApplicationContract,
} from '../../contract/zallengine_badge';
import { SubmitBadgeDecisionRequest_BadgeDecisionType } from '../../contract/zallengine_zallweb';

export type AdminState = {
  pendingBadge: PendingBadge[];
  loadPendingBadgeError: string;
  submitBadgeError: string;
};

export type BadgeApplication = BadgeApplicationContract;
export type PendingBadge = PendingBadgeContract & {
  processed: boolean;
};
export type BadgeDecisionType = SubmitBadgeDecisionRequest_BadgeDecisionType;
export const DecisionStatusValue = SubmitBadgeDecisionRequest_BadgeDecisionType;

export interface LoadPendingBadgesSuccess extends Action {
  type: 'LOAD_PENDING_BADGES_SUCCESS';
  badges: PendingBadge[];
}

export interface LoadPendingBadgesError extends Action {
  type: 'LOAD_PENDING_BADGES_ERROR';
  msg: string;
}

export interface SubmitBadgeDecisionSuccess extends Action {
  type: 'SUBMIT_BADGE_DECISION_SUCCESS';
  badgeId: string;
}

export interface SubmitBadgeDecisionError extends Action {
  type: 'SUBMIT_BADGE_DECISION_ERROR';
  msg: string;
}

export type PendingBadgeAction =
  | LoadPendingBadgesSuccess
  | LoadPendingBadgesError
  | SubmitBadgeDecisionSuccess
  | SubmitBadgeDecisionError;

export type AdminAction = PendingBadgeAction;
