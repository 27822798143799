/**
 * Returns number parsed from a string
 *
 * @param str - The string to covert
 * @returns number if str can be converted to a safe and valid number; otherwise, undefined is returned
 */
export const stringToInteger = (str: string): number | undefined => {
  const n = parseInt(str, 10);
  return Number.isSafeInteger(n) ? n : undefined;
};
