import React from 'react';
import styled from 'styled-components';
import { Text, Flex, Input, TextButton, spaceMixin } from '@zillow/constellation';
import { PhoneNumberFieldLogic } from './PhoneNumberField.hooks';
import { PatternFormat } from 'react-number-format';

export interface PhoneNumberFieldProps {
  /** phone number field label */
  label: string;
  /** add phone number CTA label */
  addPhoneNumberCTA?: string;
  /** phone number */
  phoneNumber?: string;
  /** is editable */
  editable?: boolean;
  /** on save callback */
  onSave?: (phoneNumber: string) => Promise<void>;
}

const PhoneNumberInput = styled(Input)`
  width: 140px;
  padding: 0 ${spaceMixin('xs')};
  margin: 0 ${spaceMixin('xs')};
`;

const AddPhoneNumberBtn = styled(TextButton)`
  display: block;
  width: fit-content;
  text-transform: capitalize;
`;

export const PhoneNumberField: React.FC<PhoneNumberFieldProps> = (props: PhoneNumberFieldProps) => {
  const { label, addPhoneNumberCTA, editable } = props;
  const { inputValue, isInputValid, isEditing, onInputChange, handleButtonClick, setIsEditing } =
    PhoneNumberFieldLogic(props);

  if (!inputValue) {
    if (!editable) return null;

    if (!isEditing) {
      return (
        <AddPhoneNumberBtn
          fontType="bodySmallHeading"
          onClick={() => setIsEditing(true)}
          data-testid="render-cta-button-test"
        >
          {addPhoneNumberCTA}
        </AddPhoneNumberBtn>
      );
    }
  }

  return (
    <Flex>
      <Text fontType="bodySmall">{label}: </Text>
      {!isEditing ? (
        <Text fontType="bodySmall" marginRight="xs" data-testid="render-phone-number-test">
          {inputValue}
        </Text>
      ) : (
        <PatternFormat
          type="tel"
          format="(###) ###-####"
          mask="_"
          autoFocus
          value={inputValue}
          onValueChange={onInputChange}
          customInput={PhoneNumberInput}
          data-testid="render-phone-input-test"
        />
      )}
      {editable && (
        <TextButton
          fontType="bodySmallHeading"
          disabled={!isInputValid}
          onClick={handleButtonClick}
          data-testid="render-edit-button-test"
        >
          {isEditing ? 'Save' : 'Edit'}
        </TextButton>
      )}
    </Flex>
  );
};
