/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Insights } from './zallengine_insights';

export const protobufPackage = 'zallengine_contract';

export interface Employee {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  title: string;
  photoUrl: string;
  totalReportsCount: string;
  insights: Insights | undefined;
  isActive: boolean;
}

export interface EmployeeFound {
  id: string;
  username: string;
  fullName: string;
  title: string;
  email: string;
  photoUrl: string;
}

function createBaseEmployee(): Employee {
  return {
    id: '0',
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    title: '',
    photoUrl: '',
    totalReportsCount: '0',
    insights: undefined,
    isActive: false,
  };
}

export const Employee = {
  encode(message: Employee, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.username !== '') {
      writer.uint32(18).string(message.username);
    }
    if (message.email !== '') {
      writer.uint32(26).string(message.email);
    }
    if (message.firstName !== '') {
      writer.uint32(34).string(message.firstName);
    }
    if (message.lastName !== '') {
      writer.uint32(42).string(message.lastName);
    }
    if (message.fullName !== '') {
      writer.uint32(50).string(message.fullName);
    }
    if (message.title !== '') {
      writer.uint32(58).string(message.title);
    }
    if (message.photoUrl !== '') {
      writer.uint32(66).string(message.photoUrl);
    }
    if (message.totalReportsCount !== '0') {
      writer.uint32(72).int64(message.totalReportsCount);
    }
    if (message.insights !== undefined) {
      Insights.encode(message.insights, writer.uint32(82).fork()).ldelim();
    }
    if (message.isActive === true) {
      writer.uint32(88).bool(message.isActive);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Employee {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmployee();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.username = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fullName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.title = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.photoUrl = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.totalReportsCount = longToString(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.insights = Insights.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.isActive = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Employee {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : '0',
      username: isSet(object.username) ? globalThis.String(object.username) : '',
      email: isSet(object.email) ? globalThis.String(object.email) : '',
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : '',
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : '',
      fullName: isSet(object.fullName) ? globalThis.String(object.fullName) : '',
      title: isSet(object.title) ? globalThis.String(object.title) : '',
      photoUrl: isSet(object.photoUrl) ? globalThis.String(object.photoUrl) : '',
      totalReportsCount: isSet(object.totalReportsCount)
        ? globalThis.String(object.totalReportsCount)
        : '0',
      insights: isSet(object.insights) ? Insights.fromJSON(object.insights) : undefined,
      isActive: isSet(object.isActive) ? globalThis.Boolean(object.isActive) : false,
    };
  },

  toJSON(message: Employee): unknown {
    const obj: any = {};
    if (message.id !== '0') {
      obj.id = message.id;
    }
    if (message.username !== '') {
      obj.username = message.username;
    }
    if (message.email !== '') {
      obj.email = message.email;
    }
    if (message.firstName !== '') {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== '') {
      obj.lastName = message.lastName;
    }
    if (message.fullName !== '') {
      obj.fullName = message.fullName;
    }
    if (message.title !== '') {
      obj.title = message.title;
    }
    if (message.photoUrl !== '') {
      obj.photoUrl = message.photoUrl;
    }
    if (message.totalReportsCount !== '0') {
      obj.totalReportsCount = message.totalReportsCount;
    }
    if (message.insights !== undefined) {
      obj.insights = Insights.toJSON(message.insights);
    }
    if (message.isActive === true) {
      obj.isActive = message.isActive;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Employee>, I>>(base?: I): Employee {
    return Employee.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Employee>, I>>(object: I): Employee {
    const message = createBaseEmployee();
    message.id = object.id ?? '0';
    message.username = object.username ?? '';
    message.email = object.email ?? '';
    message.firstName = object.firstName ?? '';
    message.lastName = object.lastName ?? '';
    message.fullName = object.fullName ?? '';
    message.title = object.title ?? '';
    message.photoUrl = object.photoUrl ?? '';
    message.totalReportsCount = object.totalReportsCount ?? '0';
    message.insights =
      object.insights !== undefined && object.insights !== null
        ? Insights.fromPartial(object.insights)
        : undefined;
    message.isActive = object.isActive ?? false;
    return message;
  },
};

function createBaseEmployeeFound(): EmployeeFound {
  return { id: '0', username: '', fullName: '', title: '', email: '', photoUrl: '' };
}

export const EmployeeFound = {
  encode(message: EmployeeFound, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.username !== '') {
      writer.uint32(18).string(message.username);
    }
    if (message.fullName !== '') {
      writer.uint32(26).string(message.fullName);
    }
    if (message.title !== '') {
      writer.uint32(34).string(message.title);
    }
    if (message.email !== '') {
      writer.uint32(42).string(message.email);
    }
    if (message.photoUrl !== '') {
      writer.uint32(50).string(message.photoUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmployeeFound {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmployeeFound();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.username = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fullName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.title = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.photoUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmployeeFound {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : '0',
      username: isSet(object.username) ? globalThis.String(object.username) : '',
      fullName: isSet(object.fullName) ? globalThis.String(object.fullName) : '',
      title: isSet(object.title) ? globalThis.String(object.title) : '',
      email: isSet(object.email) ? globalThis.String(object.email) : '',
      photoUrl: isSet(object.photoUrl) ? globalThis.String(object.photoUrl) : '',
    };
  },

  toJSON(message: EmployeeFound): unknown {
    const obj: any = {};
    if (message.id !== '0') {
      obj.id = message.id;
    }
    if (message.username !== '') {
      obj.username = message.username;
    }
    if (message.fullName !== '') {
      obj.fullName = message.fullName;
    }
    if (message.title !== '') {
      obj.title = message.title;
    }
    if (message.email !== '') {
      obj.email = message.email;
    }
    if (message.photoUrl !== '') {
      obj.photoUrl = message.photoUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmployeeFound>, I>>(base?: I): EmployeeFound {
    return EmployeeFound.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmployeeFound>, I>>(object: I): EmployeeFound {
    const message = createBaseEmployeeFound();
    message.id = object.id ?? '0';
    message.username = object.username ?? '';
    message.fullName = object.fullName ?? '';
    message.title = object.title ?? '';
    message.email = object.email ?? '';
    message.photoUrl = object.photoUrl ?? '';
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
