/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import { StringValue } from './google/protobuf/wrappers';

export const protobufPackage = 'zallengine_contract';

export interface NamePronunciation {
  audioUrl: string | undefined;
  description: string | undefined;
}

function createBaseNamePronunciation(): NamePronunciation {
  return { audioUrl: undefined, description: undefined };
}

export const NamePronunciation = {
  encode(message: NamePronunciation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.audioUrl !== undefined) {
      StringValue.encode({ value: message.audioUrl! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.description !== undefined) {
      StringValue.encode({ value: message.description! }, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NamePronunciation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNamePronunciation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.audioUrl = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NamePronunciation {
    return {
      audioUrl: isSet(object.audioUrl) ? String(object.audioUrl) : undefined,
      description: isSet(object.description) ? String(object.description) : undefined,
    };
  },

  toJSON(message: NamePronunciation): unknown {
    const obj: any = {};
    if (message.audioUrl !== undefined) {
      obj.audioUrl = message.audioUrl;
    }
    if (message.description !== undefined) {
      obj.description = message.description;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NamePronunciation>, I>>(base?: I): NamePronunciation {
    return NamePronunciation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NamePronunciation>, I>>(object: I): NamePronunciation {
    const message = createBaseNamePronunciation();
    message.audioUrl = object.audioUrl ?? undefined;
    message.description = object.description ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
