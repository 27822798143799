import React from 'react';
import styled from 'styled-components';
import { TextButton, IconSpeakerVolumeUp, token } from '@zillow/constellation';
import { AudioPlayerIconLogic } from './AudioPlayerIcon.hooks';

export interface AudioPlayerIconProps {
  audioURL: string;
}

const AudioBtn = styled(TextButton)<{ isPlaying: boolean }>`
  font-size: 35px;
  color: ${(props) => (props.isPlaying ? token('colors.blue375') : token('colors.blue500'))};

  :hover {
    text-decoration: none !important;
    color: ${(props) => (props.isPlaying ? token('colors.blue375') : token('colors.blue500'))};
  }
`;

export const AudioPlayerIcon: React.FC<AudioPlayerIconProps> = (props: AudioPlayerIconProps) => {
  const { playAudio, isAudioPlaying } = AudioPlayerIconLogic(props);

  return (
    <AudioBtn
      onClick={() => playAudio()}
      isPlaying={isAudioPlaying}
      aria-label="Play name pronunciation"
      data-testid="audio-icon"
    >
      <IconSpeakerVolumeUp size="lg" />
    </AudioBtn>
  );
};
