import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { debounce } from 'lodash';
import {
  Flex,
  Heading,
  Text,
  spaceMixin,
  fontSizeMixin,
  AdornedInput,
  Input,
  IconSearch,
  Adornment,
  Spacer
} from '@zillow/constellation';
import { AllBadgesContainerLogic } from './AllBadgesContainer.hooks';
import { RootState, Badge as BadgeType } from '../../store';
import { selectUserBadges } from '../../store/user';
import { loadAllBadges, selectAllBadges } from '../../store/badge';
import BadgeRequestContainer from '../BadgeRequestContainer';
import { NoStyleLink } from '../../components/Links';
import { Badge } from '../../components/Badge';
import { Loader } from '../../components/Loader';
import DynamicConfig from '../../config/DynamicConfig';
import { PaginationSelect } from '../../components/PaginationSelect';

export interface AllBadgesContainerProps {
  /** user's own badges */
  myBadges: BadgeType[];
  /** list of all badges */
  badges: BadgeType[];
  /** load all badges */
  loadAllBadges: () => Promise<void>;
}

const Link = styled(NoStyleLink)`
  display: block;
  width: fit-content;
  margin: ${spaceMixin('sm')} 0;
`;

const IconWrapper = styled.div`
  display: inline-block;
  margin-right: ${spaceMixin('xs')};
  position: relative;
  top: 7px;
`;

const LargeText = styled(Text)`
  font-size: ${fontSizeMixin('h5')};
`;

export function getBadgesForPage(
  badges: BadgeType[],
  currentPage: number,
  badgesPerPage: number,
): BadgeType[] {
  return badges.slice(currentPage * badgesPerPage, (currentPage + 1) * badgesPerPage);
}

const writeToGA = debounce(() => {
  ReactGA.event({ category: 'Badges', action: 'Filter Badges' });
}, 1000);

const AllBadgesContainerBase: React.FC<AllBadgesContainerProps> = (
  props: AllBadgesContainerProps,
) => {
  const [searchInput, setSearchInput] = useState('');
  const { badges } = props;
  const [badgesPerPage, setBadgesPerPage] = useState(10);

  const searchBadges = (keyword: string) => {
    const regex = new RegExp(keyword, 'i'); // 'i' for case-insensitive
    return badges.filter((badge) => {
      return regex.test(badge.name) || regex.test(badge.description);
    });
  };

  const { isLoading } = AllBadgesContainerLogic(props);
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const filteredBadges = searchInput ? searchBadges(searchInput) : badges;

  const totalPages = Math.ceil(filteredBadges.length / badgesPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const badgesForPage = getBadgesForPage(filteredBadges, currentPage, badgesPerPage);

  return (
    <Loader loading={isLoading}>
      <Flex
        display="flex"
        flexWrap="wrap"
        flexDirection="column"
        marginX="md"
        marginY="sm"
        data-testid="all-badges-container"
      >
        <Flex
          display="flex"
          justifyContent="right"
          alignItems="flex-end"
          style={{ width: '100%' }}
        >
          <AdornedInput
            style={{ width: '300px' }}
            input={
              <Input
                placeholder="Filter badges"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentPage(0);
                  setSearchInput(event.target.value);
                  writeToGA();
                }}
                data-testid="badges-searchbox-input"
              />
            }
            leftAdornment={
              <Adornment>
                <IconSearch />
              </Adornment>
            }
          />
        </Flex>
        <Heading level="4">All Badges</Heading>
        <BadgeRequestContainer />
        <Flex margin="xs">
          {badgesForPage.map((badge, i) => (
            <Link key={i} to={`/badges/${badge.id}`}>
              <IconWrapper>
                <Badge
                  name={badge.name}
                  iconUrl={`${ZALL_ENGINE_PROXY}${badge.iconUrl}?ts=${badge.updatedDate}`}
                  size={'40px'}
                />
              </IconWrapper>

              <LargeText as="strong" data-testid="all-badges-badge-name">
                {badge.name}
              </LargeText>

              {badge.description && (
                <>
                  <LargeText marginX="xs">-</LargeText>
                  <Text fontType="body" data-testid="all-badges-badge-desc">
                    {badge.description}
                  </Text>
                </>
              )}
            </Link>
          ))}
          </Flex>
          <Spacer margin="sm" />
              <PaginationSelect 
                perPage={badgesPerPage}
                setItemsPerPage={setBadgesPerPage} 
                totalPages={totalPages} 
                currentPage={currentPage} 
                setCurrentPage={setCurrentPage} 
                totalItems={badges.length}
              />
      </Flex>
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => ({
  myBadges: selectUserBadges(state),
  badges: selectAllBadges(state),
});

const mapDispatchToProps = {
  loadAllBadges: loadAllBadges,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const AllBadgesContainer = connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(AllBadgesContainerBase);

export { AllBadgesContainer as default, AllBadgesContainerBase };
