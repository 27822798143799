import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { SearchboxProps, SearchOption } from './Searchbox';
import { v4 as uuidv4 } from 'uuid';

export const SearchboxLogic = ({ searchResults, onChange, onSelectResult }: SearchboxProps) => {
  const [searching, setSearching] = useState(false);
  const [inputKey, setInputKey] = useState(uuidv4());
  const [searchInput, setSearchInput] = useState('');
  const [searchOptions, setSearchOptions] = useState<SearchOption[]>([]);

  /** set search options */
  useEffect(() => {
    /*
     * avoid matching value to label to bypass a Constellation bug where if
     * the search input matches the value completely, the option cannot be selected.
     */
    const res: SearchOption[] = searchResults.map((e) => ({
      id: e.id,
      label: e.fullName,
      value: e.id,
      employee: e,
    }));
    setSearching(false);
    setSearchOptions(res);
  }, [searchResults]);

  const handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let keyword = e.target.value;
    if (keyword && keyword.slice) {
      keyword = keyword.slice(0, 64);
    }
    if (keyword !== searchInput) {
      if (keyword) {
        setSearchOptions([]);
        setSearching(true);
      }
      setSearchInput(keyword);
      onChange(keyword);
    }
  };

  const handleOnSelectOption = (option: SearchOption) => {
    ReactGA.event({ category: 'Search Results', action: 'Clicked on Search Result' });

    onSelectResult(option.employee);
    setSearchInput('');
    setInputKey(uuidv4()); // force resetting the component to clear searchbox input
  };

  return {
    inputKey,
    searching,
    searchInput,
    searchOptions,
    handleOnInputChange,
    handleOnSelectOption,
  };
};
