import { createSelector, Selector } from 'reselect';
import { RootState, InsightsState, Employee } from '../index';

export const selectInsightsState = (state: RootState): InsightsState => state.insights;

export const selectInsightsTwinsTargetEmployee: Selector<RootState, Employee> = createSelector(
  [selectInsightsState],
  (insightsState) => {
    return insightsState.targetEmployee;
  },
);

export const selectInsightsTwins: Selector<RootState, Employee[]> = createSelector(
  [selectInsightsState],
  (insightsState) => {
    return insightsState.insightsTwins;
  },
);

export const selectInsightsLeaders: Selector<RootState, Employee[]> = createSelector(
  [selectInsightsState],
  (insightsState) => {
    return insightsState.insightsLeaders;
  },
);
