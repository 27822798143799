import { useEffect, useCallback, useState } from 'react';
import { InsightsTwinsContainerProps } from './InsightsTwinsContainer';

export const InsightsTwinsContainerLogic = ({
  username,
  loadInsightsTwins,
}: InsightsTwinsContainerProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const loadInsightsTwinsCallback = useCallback(
    (username: string): Promise<void> => {
      return loadInsightsTwins(username);
    },
    [loadInsightsTwins],
  );

  useEffect(() => {
    if (username) {
      setIsLoading(true);
      loadInsightsTwinsCallback(username).then(() => setIsLoading(false));
    }
  }, [username]);

  return { isLoading };
};
