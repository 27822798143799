import * as React from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import {
  Flex,
  Heading,
  Paragraph,
  Spacer,
  spaceMixin,
  mediaBreakpointMixin,
} from '@zillow/constellation';
import { InsightsLeaders } from './InsightsLeaders';
import { InsightsTwins } from './InsightsTwins';
import { Banner } from '../../components/Banner';
import { Insights as InsightsBlocks } from '../../components/Insights';

export const BannerTextWrapper = styled(Flex)`
  text-align: center;
`;

export const MainWrapper = styled(Flex)`
  margin-top: ${spaceMixin('lg')};
  padding: ${spaceMixin('xl')} 0;
`;

export const LeftPanel = styled(Flex)`
  @media ${mediaBreakpointMixin('md')} {
    width: 200px;
    border-right: 2px solid #ccc;
    border-bottom: none;
    min-height: 100vh;
  }

  border-right: none;
  border-bottom: 2px solid #ccc;
  width: 100%;
  text-align: center;
`;

export const RightPanel = styled(Flex)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
`;

export const LineBreak = styled.hr`
  width: 100%;
  border-top: 2px solid #ccc;
`;

export const UserCard = styled.div`
  width: 130px;
`;

const InsightsPages: React.FC = () => {
  return (
    <>
      <Banner>
        <BannerTextWrapper flexDirection="column" alignItems="center">
          <Heading fontColor="textWhite" level="3">
            Zillow Group Insights
          </Heading>
          <Spacer margin="md" />
          <Paragraph fontColor="textWhite" fontType="body">
            Zall and their insight colors
          </Paragraph>
        </BannerTextWrapper>
      </Banner>
      <MainWrapper display="flex" flexWrap="wrap">
        <LeftPanel>
          <InsightsBlocks blocks={['y', 'g', 'r', 'b']} space={'20px'} clickable />
        </LeftPanel>
        <RightPanel>
          <Switch>
            <Route path="/insights/twins/:username" component={InsightsTwins} />
            <Route path="/insights/:color" component={InsightsLeaders} />
          </Switch>
        </RightPanel>
      </MainWrapper>
    </>
  );
};

export default InsightsPages;
