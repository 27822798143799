import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import {
  Flex,
  Paragraph,
  TextButton,
  AdornedInput,
  Input,
  IconSearch,
  Adornment,
  Spacer,
} from '@zillow/constellation';
import { LineBreak, UserCard } from '../InsightsTwinsContainer';
import { InsightsImage } from '../../components/Insights';
import { AvatarDisk } from '../../components/AvatarDisk';
import { RootState, Employee } from '../../store';
import { loadInsightsLeaders, selectInsightsLeaders } from '../../store/insights';
import { InsightsLeadersContainerLogic } from './InsightsLeadersContainer.hooks';
import { insightsColorExplanation } from '../../store/wallConfig';
import DynamicConfig from '../../config/DynamicConfig';
import { Loader } from '../../components/Loader';
import { PaginationSelect } from '../../components/PaginationSelect';
interface StateProps {
  /** list of insights leaders */
  leaders: Employee[];
  loadInsightsLeaders: (username: string) => Promise<void>;
}
interface OwnProps {
  /** color in path */
  color: string;
}

export type InsightsLeadersContainerProps = StateProps & OwnProps;

export function getLeadersForPage(
  leaders: Employee[],
  currentPage: number,
  leadersPerPage: number,
): Employee[] {
  return leaders.slice(currentPage * leadersPerPage, (currentPage + 1) * leadersPerPage);
}

const writeToGA = debounce(() => {
  ReactGA.event({ category: 'Insights', action: 'Filter Insight Leaders' });
}, 1000);

const InsightsLeadersContainerBase: React.FC<InsightsLeadersContainerProps> = (
  props: InsightsLeadersContainerProps,
) => {
  const [searchInput, setSearchInput] = useState('');
  const { color, leaders } = props;
  const [leadersPerPage, setLeadersPerPage] = useState(10);

  const searchLeaders = (keyword: string) => {
    const regex = new RegExp(keyword, 'i'); // 'i' for case-insensitive
    return leaders.filter((leader) => {
      return regex.test(leader.fullName);
    });
  };

  const { INSIGHTS_PRODUCT_URL, ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const { isLoading } = InsightsLeadersContainerLogic(props);
  const filteredLeaders = searchInput ? searchLeaders(searchInput) : leaders;

  const totalPages = Math.ceil(filteredLeaders.length / leadersPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const leadersForPage = getLeadersForPage(filteredLeaders, currentPage, leadersPerPage);

  return (
    <Loader loading={isLoading}>
      <Flex display="flex">
        <Flex flex="1" display="flex" alignItems="center" justifyContent="center">
          <img src={InsightsImage(color)} />
        </Flex>
        <Flex flex="2">
          <Paragraph>Strengths:</Paragraph>
          <ul>
            {insightsColorExplanation[color].pros.map((pro, idx) => (
              <li key={idx}>{pro}</li>
            ))}
          </ul>
          <Paragraph>On the flip side:</Paragraph>
          <ul>
            {insightsColorExplanation[color].cons.map((con, idx) => (
              <li key={idx}>{con}</li>
            ))}
          </ul>
          <TextButton as="a" href={INSIGHTS_PRODUCT_URL} marginTop="xs">
            Learn more about insights here
          </TextButton>
        </Flex>
      </Flex>
      <LineBreak />

      <Flex display="flex" justifyContent="center" style={{ width: '100%' }}>
        <Flex
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{ width: '50%' }}
        >
          <AdornedInput
            style={{ width: '300px' }}
            input={
              <Input
                placeholder="Filter leaders"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentPage(0);
                  setSearchInput(event.target.value);
                  writeToGA();
                }}
                data-testid="insight-leaders-searchbox-input"
              />
            }
            leftAdornment={
              <Adornment>
                <IconSearch />
              </Adornment>
            }
          />
        </Flex>
      </Flex>
      <Spacer margin="sm" />
      <Flex display="flex" justifyContent="center" flexWrap="wrap" style={{ width: '100%' }}>
        {leadersForPage.map((leader, idx) => (
          <UserCard key={idx} data-testid="insight-leader-card">
            <AvatarDisk
              fullName={leader.fullName}
              size="lg"
              link={leader.username ? `/${leader.username}` : '/'}
              photoUrl={leader.photoUrl && `${ZALL_ENGINE_PROXY}${leader.photoUrl}`}
              showName
              gaCategory="Insights"
              gaAction="Clicked on Insights Leader"
            />
          </UserCard>
        ))}
      </Flex>
      <Spacer margin="sm" />
      <PaginationSelect
        perPage={leadersPerPage}
        setItemsPerPage={setLeadersPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalItems={leaders.length}
      />
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => ({
  leaders: selectInsightsLeaders(state),
});

const mapDispatchToProps = {
  loadInsightsLeaders: loadInsightsLeaders,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const InsightsLeadersContainer = connect<
  StateToPropsType,
  DispatchToPropsType,
  OwnProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps,
)(InsightsLeadersContainerBase);

export { InsightsLeadersContainer as default, InsightsLeadersContainerBase };
