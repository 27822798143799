/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Timestamp } from './google/protobuf/timestamp';
import { Employee } from './zallengine_employee';

export const protobufPackage = 'zallengine_contract';

export enum BadgeType {
  BADGE_TYPE_UNSPECIFIED = 0,
  BADGE_TYPE_PROJECT_OR_PROGRAM = 1,
  BADGE_TYPE_COMPANY_INITIATIVE_OR_OKR = 2,
  BADGE_TYPE_COMPANY_AWARD = 3,
  UNRECOGNIZED = -1,
}

export function badgeTypeFromJSON(object: any): BadgeType {
  switch (object) {
    case 0:
    case 'BADGE_TYPE_UNSPECIFIED':
      return BadgeType.BADGE_TYPE_UNSPECIFIED;
    case 1:
    case 'BADGE_TYPE_PROJECT_OR_PROGRAM':
      return BadgeType.BADGE_TYPE_PROJECT_OR_PROGRAM;
    case 2:
    case 'BADGE_TYPE_COMPANY_INITIATIVE_OR_OKR':
      return BadgeType.BADGE_TYPE_COMPANY_INITIATIVE_OR_OKR;
    case 3:
    case 'BADGE_TYPE_COMPANY_AWARD':
      return BadgeType.BADGE_TYPE_COMPANY_AWARD;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return BadgeType.UNRECOGNIZED;
  }
}

export function badgeTypeToJSON(object: BadgeType): string {
  switch (object) {
    case BadgeType.BADGE_TYPE_UNSPECIFIED:
      return 'BADGE_TYPE_UNSPECIFIED';
    case BadgeType.BADGE_TYPE_PROJECT_OR_PROGRAM:
      return 'BADGE_TYPE_PROJECT_OR_PROGRAM';
    case BadgeType.BADGE_TYPE_COMPANY_INITIATIVE_OR_OKR:
      return 'BADGE_TYPE_COMPANY_INITIATIVE_OR_OKR';
    case BadgeType.BADGE_TYPE_COMPANY_AWARD:
      return 'BADGE_TYPE_COMPANY_AWARD';
    case BadgeType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Badge {
  id: string;
  name: string;
  description: string;
  iconUrl: string;
  updatedDate: Date | undefined;
}

export interface BadgeApplication {
  name: string;
  description: string;
  iconImage: Uint8Array;
  businessJustification: string;
  type: BadgeType;
  estNumOfAssignees: string;
}

export interface PendingBadge {
  badge: Badge | undefined;
  department: string;
  businessJustification: string;
  owner: Employee | undefined;
  type: BadgeType;
  estNumOfAssignees: string;
}

function createBaseBadge(): Badge {
  return { id: '0', name: '', description: '', iconUrl: '', updatedDate: undefined };
}

export const Badge = {
  encode(message: Badge, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== '') {
      writer.uint32(26).string(message.description);
    }
    if (message.iconUrl !== '') {
      writer.uint32(34).string(message.iconUrl);
    }
    if (message.updatedDate !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedDate), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Badge {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBadge();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.iconUrl = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.updatedDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Badge {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : '0',
      name: isSet(object.name) ? globalThis.String(object.name) : '',
      description: isSet(object.description) ? globalThis.String(object.description) : '',
      iconUrl: isSet(object.iconUrl) ? globalThis.String(object.iconUrl) : '',
      updatedDate: isSet(object.updatedDate) ? fromJsonTimestamp(object.updatedDate) : undefined,
    };
  },

  toJSON(message: Badge): unknown {
    const obj: any = {};
    if (message.id !== '0') {
      obj.id = message.id;
    }
    if (message.name !== '') {
      obj.name = message.name;
    }
    if (message.description !== '') {
      obj.description = message.description;
    }
    if (message.iconUrl !== '') {
      obj.iconUrl = message.iconUrl;
    }
    if (message.updatedDate !== undefined) {
      obj.updatedDate = message.updatedDate.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Badge>, I>>(base?: I): Badge {
    return Badge.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Badge>, I>>(object: I): Badge {
    const message = createBaseBadge();
    message.id = object.id ?? '0';
    message.name = object.name ?? '';
    message.description = object.description ?? '';
    message.iconUrl = object.iconUrl ?? '';
    message.updatedDate = object.updatedDate ?? undefined;
    return message;
  },
};

function createBaseBadgeApplication(): BadgeApplication {
  return {
    name: '',
    description: '',
    iconImage: new Uint8Array(0),
    businessJustification: '',
    type: 0,
    estNumOfAssignees: '0',
  };
}

export const BadgeApplication = {
  encode(message: BadgeApplication, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== '') {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== '') {
      writer.uint32(18).string(message.description);
    }
    if (message.iconImage.length !== 0) {
      writer.uint32(26).bytes(message.iconImage);
    }
    if (message.businessJustification !== '') {
      writer.uint32(34).string(message.businessJustification);
    }
    if (message.type !== 0) {
      writer.uint32(40).int32(message.type);
    }
    if (message.estNumOfAssignees !== '0') {
      writer.uint32(48).int64(message.estNumOfAssignees);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BadgeApplication {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBadgeApplication();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.iconImage = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.businessJustification = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.estNumOfAssignees = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BadgeApplication {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : '',
      description: isSet(object.description) ? globalThis.String(object.description) : '',
      iconImage: isSet(object.iconImage) ? bytesFromBase64(object.iconImage) : new Uint8Array(0),
      businessJustification: isSet(object.businessJustification)
        ? globalThis.String(object.businessJustification)
        : '',
      type: isSet(object.type) ? badgeTypeFromJSON(object.type) : 0,
      estNumOfAssignees: isSet(object.estNumOfAssignees)
        ? globalThis.String(object.estNumOfAssignees)
        : '0',
    };
  },

  toJSON(message: BadgeApplication): unknown {
    const obj: any = {};
    if (message.name !== '') {
      obj.name = message.name;
    }
    if (message.description !== '') {
      obj.description = message.description;
    }
    if (message.iconImage.length !== 0) {
      obj.iconImage = base64FromBytes(message.iconImage);
    }
    if (message.businessJustification !== '') {
      obj.businessJustification = message.businessJustification;
    }
    if (message.type !== 0) {
      obj.type = badgeTypeToJSON(message.type);
    }
    if (message.estNumOfAssignees !== '0') {
      obj.estNumOfAssignees = message.estNumOfAssignees;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BadgeApplication>, I>>(base?: I): BadgeApplication {
    return BadgeApplication.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BadgeApplication>, I>>(object: I): BadgeApplication {
    const message = createBaseBadgeApplication();
    message.name = object.name ?? '';
    message.description = object.description ?? '';
    message.iconImage = object.iconImage ?? new Uint8Array(0);
    message.businessJustification = object.businessJustification ?? '';
    message.type = object.type ?? 0;
    message.estNumOfAssignees = object.estNumOfAssignees ?? '0';
    return message;
  },
};

function createBasePendingBadge(): PendingBadge {
  return {
    badge: undefined,
    department: '',
    businessJustification: '',
    owner: undefined,
    type: 0,
    estNumOfAssignees: '0',
  };
}

export const PendingBadge = {
  encode(message: PendingBadge, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.badge !== undefined) {
      Badge.encode(message.badge, writer.uint32(10).fork()).ldelim();
    }
    if (message.department !== '') {
      writer.uint32(18).string(message.department);
    }
    if (message.businessJustification !== '') {
      writer.uint32(26).string(message.businessJustification);
    }
    if (message.owner !== undefined) {
      Employee.encode(message.owner, writer.uint32(34).fork()).ldelim();
    }
    if (message.type !== 0) {
      writer.uint32(40).int32(message.type);
    }
    if (message.estNumOfAssignees !== '0') {
      writer.uint32(48).int64(message.estNumOfAssignees);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PendingBadge {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePendingBadge();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.badge = Badge.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.department = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.businessJustification = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.owner = Employee.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.estNumOfAssignees = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PendingBadge {
    return {
      badge: isSet(object.badge) ? Badge.fromJSON(object.badge) : undefined,
      department: isSet(object.department) ? globalThis.String(object.department) : '',
      businessJustification: isSet(object.businessJustification)
        ? globalThis.String(object.businessJustification)
        : '',
      owner: isSet(object.owner) ? Employee.fromJSON(object.owner) : undefined,
      type: isSet(object.type) ? badgeTypeFromJSON(object.type) : 0,
      estNumOfAssignees: isSet(object.estNumOfAssignees)
        ? globalThis.String(object.estNumOfAssignees)
        : '0',
    };
  },

  toJSON(message: PendingBadge): unknown {
    const obj: any = {};
    if (message.badge !== undefined) {
      obj.badge = Badge.toJSON(message.badge);
    }
    if (message.department !== '') {
      obj.department = message.department;
    }
    if (message.businessJustification !== '') {
      obj.businessJustification = message.businessJustification;
    }
    if (message.owner !== undefined) {
      obj.owner = Employee.toJSON(message.owner);
    }
    if (message.type !== 0) {
      obj.type = badgeTypeToJSON(message.type);
    }
    if (message.estNumOfAssignees !== '0') {
      obj.estNumOfAssignees = message.estNumOfAssignees;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PendingBadge>, I>>(base?: I): PendingBadge {
    return PendingBadge.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PendingBadge>, I>>(object: I): PendingBadge {
    const message = createBasePendingBadge();
    message.badge =
      object.badge !== undefined && object.badge !== null
        ? Badge.fromPartial(object.badge)
        : undefined;
    message.department = object.department ?? '';
    message.businessJustification = object.businessJustification ?? '';
    message.owner =
      object.owner !== undefined && object.owner !== null
        ? Employee.fromPartial(object.owner)
        : undefined;
    message.type = object.type ?? 0;
    message.estNumOfAssignees = object.estNumOfAssignees ?? '0';
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if (globalThis.Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, 'base64'));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (globalThis.Buffer) {
    return globalThis.Buffer.from(arr).toString('base64');
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(''));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === 'string') {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
