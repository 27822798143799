import { createSelector, Selector } from 'reselect';
import { RootState, AdminState, PendingBadge } from '../index';

export const selectAdminState = (state: RootState): AdminState => state.admin;

export const selectPendingBadges: Selector<RootState, PendingBadge[]> = createSelector(
  [selectAdminState],
  (adminState) => {
    return adminState.pendingBadge;
  },
);
