import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { debounce } from 'lodash';
import {
  Flex,
  Heading,
  Paragraph,
  Spacer,
  Text,
  IconCloseCircle,
  LoadingMask,
  TextButton,
  Alert,
  IconEdit,
  withToast,
  mediaBreakpointMixin,
  spaceMixin,
  AdornedInput,
  Input,
  IconSearch,
  Adornment
} from '@zillow/constellation';
import { RootState, Employee, Badge as BadgeType } from '../../store';
import {
  loadBadgeWallDetail,
  selectTargetBadge,
  selectBadgeMembers,
  addBadgeMember,
  removeBadgeMember,
  updateBadgeDetail,
} from '../../store/badge';
import {
  selectUserUsername,
  loadMyBadges,
  selectUserBadges,
  selectIsBadgeAdmin,
  addToMyBadges,
} from '../../store/user';
import { EmployeeFound, searchEmployees } from '../../store/search';
import { BadgeMembersContainerLogic } from './BadgeMembersContainer.hooks';
import { BadgeMembersAdminLogic } from './BadgeMembersAdmin.hooks';
import { BadgeAdminSearchbox } from './BadgeAdminSearchbox';
import { EditBadgeDetailsModal } from './EditBadgeDetailsModal';
import { UserCard } from '../InsightsTwinsContainer';
import { AvatarDisk } from '../../components/AvatarDisk';
import { Loader } from '../../components/Loader';
import { Badge } from '../../components/Badge';
import { Modal } from '../../components/Modal';
import { HtmlTitle } from '../../components/HtmlTitle';
import DynamicConfig from '../../config/DynamicConfig';
import { PaginationSelect } from '../../components/PaginationSelect';

interface StateProps {
  /** badge detail */
  badge: BadgeType;
  /** list of badge members */
  members: Employee[];
  /** current user's username */
  username: string;
  /** is badge admin */
  isBadgeAdmin: boolean;
  /** user's own badges */
  myBadges: BadgeType[];
  /** loadBadgeWallDetail */
  loadBadgeWallDetail: (badgeId: string) => Promise<void>;
  /** loadMyBadges */
  loadMyBadges: () => Promise<void>;
  /** addToMyBadges */
  addToMyBadges: (badge: BadgeType) => Promise<void>;
  /** updateBadgeDetail */
  updateBadgeDetail: (
    id: string,
    name: string,
    description: string,
    iconImage: Uint8Array,
  ) => Promise<void>;
  /** badge admin props */
  searchEmployees: (keyword: string) => Promise<EmployeeFound[]>;
  addBadgeMember: (employee: Employee, badgeId: string) => Promise<void>;
  removeBadgeMember: (employee: Employee, badgeId: string) => Promise<void>;
  enqueueToast: (msg: JSX.Element) => void;
}

interface OwnProps {
  /** badge id in path */
  badgeId: string;
}

export type BadgeMembersContainerProps = StateProps & OwnProps;

const RequestBadgeBtn = styled(TextButton)`
  width: fit-content;
  margin-top: ${spaceMixin('md')};
`;

const BadgeDetailWrapper = styled(Flex)`
  @media ${mediaBreakpointMixin('xl')} {
    flex-direction: row;
  }
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: center;
`;

const RemoveBtn = styled.div`
  position: absolute;
  right: 18px;
  top: -3px;

  :hover {
    cursor: pointer;
  }
`;

const BadgeMemberWrapper = styled.div`
  position: relative;
  margin: auto;
`;

const ModalContent = styled(Flex)`
  min-height: 100px;
`;

export function getMembersForPage(
  members: Employee[],
  currentPage: number,
  membersPerPage: number,
): Employee[] {
  return members.slice(currentPage * membersPerPage, (currentPage + 1) * membersPerPage);
}

const writeToGA = debounce(() => {
  ReactGA.event({ category: 'Badges', action: 'Filter Badge Members' });
}, 1000);

const BadgeMembersContainerBase: React.FC<BadgeMembersContainerProps> = (
  props: BadgeMembersContainerProps,
) => {
  const [searchInput, setSearchInput] = useState('');
  const { badge, members, isBadgeAdmin, updateBadgeDetail } = props;
  const [membersPerPage, setMembersPerPage] = useState(10);

  const searchMembers = (keyword: string) => {
    const regex = new RegExp(keyword, 'i'); // 'i' for case-insensitive
    return members.filter((member) => {
      return regex.test(member.fullName);
    });
  };

  const {
    isLoading,
    userHasBadge,
    addToMyBadgesStatus,
    isEditBadgeModalOpen,
    openEditBadgeDetailsModal,
    setIsEditBadgeModalOpen,
    addToMyBadgesCallback,
    handleCloseModal,
  } = BadgeMembersContainerLogic(props);
  const { loadingMemberIds, handleRemoveMember, ...adminLogicProps } =
    BadgeMembersAdminLogic(props);
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const filteredMembers = searchInput ? searchMembers(searchInput) : members;

  const totalPages = Math.ceil(filteredMembers.length / membersPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const membersForPage = getMembersForPage(filteredMembers, currentPage, membersPerPage);

  return (
    <>
      <Loader loading={isLoading}>
        <HtmlTitle>
          <title>{`${badge.name} - Badge - ZallWall`}</title>
        </HtmlTitle>
        <Flex
          display="flex"
          flexDirection={{ default: 'column', xl: 'row' }}
          data-testid="badge-members-container"
        >
          <Flex flex="1" display="flex" alignItems="center" justifyContent="center">
            <Badge
              name={badge.name}
              iconUrl={`${ZALL_ENGINE_PROXY}${badge.iconUrl}?ts=${badge.updatedDate}`}
            />
          </Flex>
          <BadgeDetailWrapper>
            <Flex display="flex" flexDirection="column" marginRight="xl">
              <Heading level="3">{badge.name}</Heading>
              <Paragraph marginTop="md">{badge.description}</Paragraph>

              {!userHasBadge && (
                <>
                  <RequestBadgeBtn
                    onClick={() => addToMyBadgesCallback()}
                    data-testid="request-badge-btn"
                  >
                    Request this Badge
                  </RequestBadgeBtn>
                  <Text as="i">
                    Please #DoTheRightThing and only request this badge if you are eligible.
                  </Text>
                </>
              )}
            </Flex>
          </BadgeDetailWrapper>
          <Flex flex="1" marginRight="lg">
            <TextButton
              icon={<IconEdit />}
              marginBottom="sm"
              onClick={openEditBadgeDetailsModal}
              data-testid="edit-badge-details-btn"
            >
              Edit badge details
            </TextButton>
            {isBadgeAdmin && <BadgeAdminSearchbox {...adminLogicProps} />}
          </Flex>
        </Flex>
        <Spacer margin="sm" />
        <Flex display="flex" justifyContent="center" style={{ width: '100%' }}>
          <Flex
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{ width: '50%' }}
          >
             {members.length > 0 && (
            <AdornedInput
              style={{ width: '300px' }}
              input={
                <Input
                  placeholder="Filter members"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCurrentPage(0);
                    setSearchInput(event.target.value);
                    writeToGA();
                  }}
                  data-testid="badge-members-searchbox-input"
                />
              }
              leftAdornment={
                <Adornment>
                  <IconSearch />
                </Adornment>
              }
            />
             )}
          </Flex>
        </Flex>
        <Spacer margin="sm" />
        <Flex display="flex" justifyContent="center" flexWrap="wrap" style={{ width: '100%'}}>
          {members.length === 0 && (
            <Paragraph>No badge members for <strong>{badge.name}</strong> yet!</Paragraph>
          )}
          {membersForPage.map((member, idx) => (
            <LoadingMask key={idx} loading={loadingMemberIds.includes(member.id)}>
              <UserCard>
                <BadgeMemberWrapper>
                  {isBadgeAdmin && (
                    <RemoveBtn
                      onClick={() => handleRemoveMember(member)}
                      data-testid="remove-badge-member-btn"
                    >
                      <IconCloseCircle size="sm" />
                    </RemoveBtn>
                  )}
                  <AvatarDisk
                    fullName={member.fullName}
                    size="lg"
                    photoUrl={member.photoUrl && `${ZALL_ENGINE_PROXY}${member.photoUrl}`}
                    link={member.username ? `/${member.username}` : '/'}
                    showName
                    gaCategory="Badges"
                    gaAction="Clicked on Badge Members List"
                  />
                </BadgeMemberWrapper>
              </UserCard>
            </LoadingMask>
          ))}
        </Flex>
        <Spacer margin="sm" />
          <PaginationSelect 
            perPage={membersPerPage}
            setItemsPerPage={setMembersPerPage} 
            totalPages={totalPages} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            totalItems={members.length}
            /> 
      </Loader>
      <Modal
        header={
          <Heading level={6}>
            Thank you for submitting
            <br />
            your badge request!
          </Heading>
        }
        body={
          <ModalContent padding="sm">
            <Loader loading={addToMyBadgesStatus === 'Loading'}>
              {addToMyBadgesStatus === 'Success' ? (
                <Paragraph>The badge will be added to your profile within 1 day.</Paragraph>
              ) : (
                <Alert appearance="error" body="Something went wrong. Please try again later." />
              )}
            </Loader>
          </ModalContent>
        }
        isOpen={addToMyBadgesStatus !== 'Idle'}
        handleClose={handleCloseModal}
        shouldCloseOnOutsideClick={false}
        size={'xs'}
      />
      <EditBadgeDetailsModal
        isModalOpen={isEditBadgeModalOpen}
        setIsModalOpen={setIsEditBadgeModalOpen}
        badge={badge}
        updateBadgeDetail={updateBadgeDetail}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  badge: selectTargetBadge(state),
  members: selectBadgeMembers(state),
  username: selectUserUsername(state),
  isBadgeAdmin: selectIsBadgeAdmin(state),
  myBadges: selectUserBadges(state),
});

const mapDispatchToProps = {
  loadBadgeWallDetail: loadBadgeWallDetail,
  loadMyBadges: loadMyBadges,
  addToMyBadges: addToMyBadges,
  searchEmployees: searchEmployees,
  addBadgeMember: addBadgeMember,
  removeBadgeMember: removeBadgeMember,
  updateBadgeDetail: updateBadgeDetail,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const BadgeMembersContainer = connect<StateToPropsType, DispatchToPropsType, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(withToast(BadgeMembersContainerBase));

export { BadgeMembersContainer as default, BadgeMembersContainerBase };
