import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../store';
import {
  UserInfo,
  selectLoggedIn,
  selectUserLoaded,
  loadUserByCookie,
  authenticateUser,
} from '../../store/user';

import { LoginContainerLogic } from './LoginContainer.hooks';
import { LoginError } from './LoginError';
import { Loader } from '../../components/Loader';
import { ErrorType } from '../../store/errorHelpers';

type LoginParams = {
  from: string;
};
// eslint-disable-next-line @typescript-eslint/ban-types
export interface LoginContainerProps extends RouteComponentProps<{}, {}, LoginParams> {
  /** is user logged in */
  loggedIn: boolean;
  /** is user info loaded */
  userLoaded: boolean;
  /** function to authenticate user */
  authenticateUser: (authCode: string, redirectUrl: string) => Promise<void>;
  /** function to load user info */
  loadUser: () => Promise<UserInfo>;
}

const LoginContainer: React.FC<LoginContainerProps> = (props: LoginContainerProps) => {
  const { loggedIn, userLoaded } = props;
  const { sourcePath, toOktaLoginCallback, loginError } = LoginContainerLogic(props);

  if (loginError !== ErrorType.NONE) {
    return <LoginError errorType={loginError} toOktaLogin={toOktaLoginCallback} />;
  }

  return (
    <Loader loading={!loggedIn || !userLoaded}>
      <Redirect to={sourcePath} />
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => ({
  loggedIn: selectLoggedIn(state),
  userLoaded: selectUserLoaded(state),
});

const mapDispatchToProps = {
  loadUser: loadUserByCookie,
  authenticateUser: authenticateUser,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

export default withRouter(
  connect<StateToPropsType, DispatchToPropsType, Record<string, never>, RootState>(
    mapStateToProps,
    mapDispatchToProps,
  )(LoginContainer),
);
