import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { unescape } from 'he';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { debounce } from 'lodash';
import {
  Flex,
  Heading,
  Text,
  spaceMixin,
  fontSizeMixin,
  AdornedInput,
  Input,
  IconSearch,
  Adornment,
  Spacer
} from '@zillow/constellation';
import { AllInterestsContainerLogic } from './AllInterestsContainer.hooks';
import { RootState, Interest as InterestType } from '../../store';
import { selectUserInterests } from '../../store/user';
import { loadAllInterests, selectAllInterests } from '../../store/interest';
import { Interest } from '../../components/Interest';
import { NoStyleLink } from '../../components/Links';
import { Loader } from '../../components/Loader';
import { PaginationSelect } from '../../components/PaginationSelect';
import DynamicConfig from '../../config/DynamicConfig';

export interface AllInterestsContainerProps {
  /** user's own interests */
  myInterests: InterestType[];
  /** list of all interests */
  interests: InterestType[];
  loadAllInterests: () => Promise<void>;
}

const Link = styled(NoStyleLink)`
  display: block;
  width: fit-content;
  margin: ${spaceMixin('sm')} 0;
`;

const IconWrapper = styled.div`
  display: inline-block;
  margin-right: ${spaceMixin('xs')};
  position: relative;
  top: 7px;
`;

const LargeText = styled(Text)`
  font-size: ${fontSizeMixin('h5')};
`;

export function getInterestsForPage(
  interests: InterestType[],
  currentPage: number,
  interestsPerPage: number,
): InterestType[] {
  return interests.slice(currentPage * interestsPerPage, (currentPage + 1) * interestsPerPage);
}

const writeToGA = debounce(() => {
  ReactGA.event({ category: 'Interests', action: 'Filter Interests' });
}, 1000);

const AllInterestsContainerBase: React.FC<AllInterestsContainerProps> = (
  props: AllInterestsContainerProps,
) => {
  const [searchInput, setSearchInput] = useState('');
  const { interests } = props;
  const [interestsPerPage, setInterestsPerPage] = useState(10);

  const searchInterests = (keyword: string) => {
    const regex = new RegExp(keyword, 'i'); // 'i' for case-insensitive
    return interests.filter((interest) => {
      return regex.test(interest.name) || regex.test(interest.description);
    });
  };

  const { isLoading } = AllInterestsContainerLogic(props);
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const filteredInterests = searchInput ? searchInterests(searchInput) : interests;

  const totalPages = Math.ceil(filteredInterests.length / interestsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const interestsForPage = getInterestsForPage(filteredInterests, currentPage, interestsPerPage);

  return (
    <Loader loading={isLoading}>
      <Flex
        display="flex"
        flexWrap="wrap"
        flexDirection="column"
        marginX="md"
        marginY="sm"
        data-testid="all-interests-container"
      >
        <Flex
          display="flex"
          justifyContent="right"
          alignItems="flex-end"
          style={{ width: '100%' }}
        >
          <AdornedInput
            style={{ width: '300px' }}
            input={
              <Input
                placeholder="Filter interests"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentPage(0);
                  setSearchInput(event.target.value);
                  writeToGA();
                }}
                data-testid="interests-searchbox-input"
              />
            }
            leftAdornment={
              <Adornment>
                <IconSearch />
              </Adornment>
            }
          />
        </Flex>
        <Heading level="4">All interests</Heading>

        <Flex margin="xs" >
          {interestsForPage.map((interest, i) => (
            <Link key={i} to={'/interests/' + interest.id}>
              <IconWrapper>
                <Interest
                  name={interest.name}
                  iconUrl={`${ZALL_ENGINE_PROXY}${interest.iconUrl}`}
                  size={'40px'}
                />
              </IconWrapper>

              <LargeText as="strong" marginX="xs" data-testid="all-interests-interest-name">
                {interest.name}
              </LargeText>

              {interest.description && (
                <>
                  <LargeText>-</LargeText>
                  <Text fontType="body" marginLeft="xs" data-testid="all-interests-interest-desc">
                    {unescape(interest.description)}
                  </Text>
                </>
              )}
            </Link>
          ))}
          </Flex>
          <Spacer margin="sm" />
                <PaginationSelect
                  perPage={interestsPerPage} 
                  setItemsPerPage={setInterestsPerPage} 
                  totalPages={totalPages} 
                  currentPage={currentPage} 
                  setCurrentPage={setCurrentPage} 
                  totalItems={interests.length}
                  /> 
        </Flex>
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => ({
  myInterests: selectUserInterests(state),
  interests: selectAllInterests(state),
});

const mapDispatchToProps = {
  loadAllInterests: loadAllInterests,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const AllInterestsContainer = connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(AllInterestsContainerBase);

export { AllInterestsContainer as default, AllInterestsContainerBase };
