import {
  GetPendingBadgesResponse,
  SubmitBadgeDecisionResponse,
} from '../../contract/zallengine_zallweb';
import { PendingBadge } from './types';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { ZallEngineErrorResponse, GetErrorStatusType } from '../errorHelpers';

export const validateGetPendingBadgesResp = (
  resp: GetPendingBadgesResponse,
): [PendingBadge[], ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.badges || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [
    resp.badges.map((pb) => {
      return {
        ...pb,
        processed: false,
      };
    }),
    errResp,
  ];
};

export const validateSubmitBadgeDecisionResp = (
  resp: SubmitBadgeDecisionResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return errResp;
  }

  return errResp;
};
