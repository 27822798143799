import { useEffect, useCallback } from 'react';
import { InterestPagesProps } from './InterestPages';

export const InterestPagesLogic = ({ loadMyInterests }: InterestPagesProps) => {
  const loadMyInterestsCallback = useCallback(() => {
    loadMyInterests();
  }, [loadMyInterests]);

  useEffect(() => {
    loadMyInterestsCallback();
  }, []);
};
