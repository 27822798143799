import React from 'react';
import styled from 'styled-components';
import {
  Flex,
  Heading,
  LoadingButton,
  Label,
  TextButton,
  Text,
  IconClose,
  FormField,
  Input,
  FormHelp,
  Alert,
  IconButton,
  IconMicrophone,
  IconStop,
} from '@zillow/constellation';
import { NamePronunciationContainerLogic } from './NamePronunciationContainer.hooks';
import { Modal } from '../../components/Modal';

export const MAX_AUDIO_SECONDS = 10;

export interface NamePronunciationProps {
  /** name pronunciation audio */
  pronunciationAudio?: string;
  /** name pronunciation text */
  pronunciationText?: string;
  /** on save callback */
  onSave: (audio: Uint8Array, description: string, deleteAudio: boolean) => Promise<void>;
}

const FormLabel = styled(Label)`
  cursor: default;
`;

const ModalHeader = styled(Heading)`
  text-align: left;
`;

const AudioPlayer = styled.audio`
  :focus {
    outline: none;
  }
  margin: auto 0;
`;

const HelperText = styled(Text)`
  margin-top: auto;
  margin-bottom: auto;
`;

const FlashingHelperText = styled(HelperText)`
  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    30% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  animation: flickerAnimation 2s infinite;
`;

export const NamePronunciationContainer: React.FC<NamePronunciationProps> = (
  props: NamePronunciationProps,
) => {
  const {
    isModalOpen,
    openModal,
    closeModal,
    isMicAccessDenied,
    isRecording,
    startRecorder,
    stopRecorder,
    deleteAudio,
    audioURL,
    savePronunciation,
    pronunText,
    setPronunText,
    uploadError,
    isUploading,
    secondsCount,
  } = NamePronunciationContainerLogic(props);

  const hasNamePronunInfo = props.pronunciationAudio || props.pronunciationText?.length;

  const helperText = isMicAccessDenied ? (
    <HelperText fontColor="textMedium">
      To record your name, please allow access to your microphone.
    </HelperText>
  ) : isRecording ? (
    <>
      <FlashingHelperText>Listening ...</FlashingHelperText>
      <HelperText marginLeft={{ default: 'lg', sm_lte: 'sm' }}>
        0:{secondsCount}/0:{MAX_AUDIO_SECONDS}
      </HelperText>
    </>
  ) : (
    <HelperText>Click on the microphone to start recording your name.</HelperText>
  );

  return (
    <>
      <Modal
        header={<ModalHeader level={6}>Tell us how to pronounce your name!</ModalHeader>}
        body={
          <Flex marginX="lg" marginY="sm">
            <FormLabel>Record your name</FormLabel>
            <Flex display="flex" marginY="sm">
              {audioURL ? (
                <>
                  <AudioPlayer controls={true} src={audioURL} />
                  <IconButton
                    buttonType="caution"
                    appearance="circle"
                    size="lg"
                    marginX="sm"
                    title={'Delete pronunciation audio'}
                    icon={<IconClose />}
                    onClick={deleteAudio}
                  />
                </>
              ) : (
                <>
                  <IconButton
                    buttonType="primary"
                    appearance="circle"
                    size="lg"
                    marginRight="sm"
                    title={`${isRecording ? 'Stop' : 'Start'} recording`}
                    icon={isRecording ? <IconStop /> : <IconMicrophone />}
                    onClick={() => (isRecording ? stopRecorder() : startRecorder())}
                  />
                  {helperText}
                </>
              )}
            </Flex>

            <FormField
              marginTop="xl"
              label={<FormLabel>Write out the pronunciation</FormLabel>}
              control={
                <Input
                  as="input"
                  maxLength="256"
                  defaultValue={pronunText}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPronunText(e.target.value)
                  }
                  aria-label="Pronunciation text"
                />
              }
              description={
                <FormHelp>Examples: eye-LEEN, &quot;Aylin, like I LEAN on the wall&quot;</FormHelp>
              }
            />
            {uploadError && (
              <Flex marginTop="sm">
                <Alert appearance="error" body="Something went wrong. Please try again later." />
              </Flex>
            )}
          </Flex>
        }
        footer={
          <LoadingButton
            buttonType="primary"
            onClick={savePronunciation}
            disabled={isRecording}
            loading={isUploading}
          >
            Save
          </LoadingButton>
        }
        isOpen={isModalOpen}
        handleClose={closeModal}
        wide
        shouldCloseOnOutsideClick={false}
      />

      <TextButton fontType="bodySmallHeading" onClick={openModal}>
        {!hasNamePronunInfo
          ? 'Add Name Pronunciation'
          : props.pronunciationText
          ? 'Edit'
          : 'Edit Name Pronunciation'}
      </TextButton>
    </>
  );
};
