import React, { useCallback } from 'react';
import styled from 'styled-components';
import {
  Input,
  AdornedInput,
  Adornment,
  IconSearch,
  Combobox,
  mediaBreakpointMixin,
  ComboboxOption,
  MediaObject,
  Flex,
  Text,
  StyledDialog,
  StyledListbox,
  ComboboxEmptyState,
  Spinner,
} from '@zillow/constellation';
import { EmployeeFound } from '../../store/search';
import { AvatarDisk } from '../AvatarDisk';
import { SearchboxLogic } from './Searchbox.hooks';
import DynamicConfig from '../../config/DynamicConfig';

/**
 * Main ZallWall Employee Searchbox
 */

export interface SearchboxProps {
  /** list of search results */
  searchResults: EmployeeFound[];
  /** on search input change handler */
  onChange: (keyword: string) => void;
  /** on select search result handler */
  onSelectResult: (employee: EmployeeFound) => void;
}

export type SearchOption = {
  id: string;
  value: string;
  label: string;
  employee: EmployeeFound;
};

const SearchBoxInput = styled(AdornedInput)`
  @media ${mediaBreakpointMixin('xs')} {
    width: 100%;
  }

  @media ${mediaBreakpointMixin('md')} {
    width: 240px;
  }

  @media ${mediaBreakpointMixin('lg')} {
    width: 350px;
  }
`;

const SearchBoxCombobox = styled(Combobox)`
  /* have dialog appear under the header */
  ${StyledDialog} {
    top: 10px !important;
    width: 100vw;
  }

  /* increase listbox max-height to show all 5 options */
  ${StyledListbox} {
    max-height: 970px;
  }
`;

export const Searchbox: React.FC<SearchboxProps> = (props: SearchboxProps) => {
  const {
    inputKey,
    searching,
    searchInput,
    searchOptions,
    handleOnInputChange,
    handleOnSelectOption,
  } = SearchboxLogic(props);

  const renderInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ onClear, onTagClose, onChange, ...rest }) => {
      return (
        <SearchBoxInput
          size="md"
          marginRight="xs"
          input={
            <Input
              placeholder="Search for people"
              aria-label="Search"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleOnInputChange(e);
                onChange(e);
              }}
              {...rest}
              value={searchInput}
              data-testid="searchbox-search-input"
            />
          }
          rightAdornment={
            <Adornment>
              <IconSearch />
            </Adornment>
          }
        />
      );
    },
    [searchInput, handleOnInputChange],
  );

  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();

  const renderOption = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (props: any) => {
      const { value, label, employee, ...rest } = props;

      return (
        <ComboboxOption key={value} value={value} label={label} {...rest}>
          <MediaObject
            media={
              <AvatarDisk
                fullName={employee.fullName}
                photoUrl={employee.photoUrl && `${ZALL_ENGINE_PROXY}${employee.photoUrl}`}
                size="md"
                shape={'square'}
              />
            }
            align="center"
            padding="xs"
          >
            <Flex display="flex" flexDirection="column">
              <Text fontColor="brand">{employee.fullName}</Text>
              <Text fontType="finePrint">{employee.title}</Text>
              <Text fontType="finePrint">{employee.email}</Text>
            </Flex>
          </MediaObject>
        </ComboboxOption>
      );
    },
    [],
  );

  const renderEmptyState = useCallback(() => {
    if (searching) {
      return (
        <ComboboxEmptyState paddingX="lg" paddingY="md">
          <Spinner size="xs" />
        </ComboboxEmptyState>
      );
    }
    if (searchInput.length) {
      return (
        <ComboboxEmptyState paddingX="lg" paddingY="md">
          <Text>No results</Text>
        </ComboboxEmptyState>
      );
    }
    return '';
  }, [searchInput, searching]);

  return (
    <SearchBoxCombobox
      data-testid="search-box-render-test"
      autoFocus
      key={inputKey}
      value={searchInput}
      autoCompleteBehavior="none"
      focusFirstOption={true}
      fluidDropdown={false}
      renderInput={renderInput}
      renderOption={renderOption}
      renderEmptyState={renderEmptyState}
      onOptionSelect={handleOnSelectOption}
      options={searchInput.length ? searchOptions : []}
    />
  );
};
