import { Employee } from '../index';
import { InsightsState, InsightsAction } from './types';

export const initialState: InsightsState = {
  targetEmployee: {} as Employee,
  insightsTwins: [],
  insightsLeaders: [],
  loadError: '',
};

export const insightsReducer = (state = initialState, action: InsightsAction): InsightsState => {
  switch (action.type) {
    case 'LOAD_INSIGHTS_TWINS_SUCCESS':
      return {
        ...state,
        insightsTwins: action.twins,
        targetEmployee: action.employee,
      };
    case 'LOAD_INSIGHTS_LEADERS_SUCCESS':
      return {
        ...state,
        insightsLeaders: action.leaders,
      };
    case 'LOAD_INSIGHTS_ERROR':
      return {
        ...state,
        loadError: action.msg,
      };
    default:
      return state;
  }
};
