import { createSelector, Selector } from 'reselect';
import { RootState, BadgeState, Employee, Badge } from '../index';

export const selectBadgeState = (state: RootState): BadgeState => state.badge;

export const selectAllBadges: Selector<RootState, Badge[]> = createSelector(
  [selectBadgeState],
  (badgeState) => {
    return badgeState.allBadges;
  },
);

export const selectTargetBadge: Selector<RootState, Badge> = createSelector(
  [selectBadgeState],
  (badgeState) => {
    return badgeState.targetBadge;
  },
);

export const selectBadgeOwner: Selector<RootState, Employee | undefined> = createSelector(
  [selectBadgeState],
  (badgeState) => {
    return badgeState.badgeOwner;
  },
);

export const selectBadgeMembers: Selector<RootState, Employee[]> = createSelector(
  [selectBadgeState],
  (badgeState) => {
    return badgeState.badgeMembers;
  },
);
