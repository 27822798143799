import { useState } from 'react';
import ReactGA from 'react-ga4';
import { URLInputFieldProps } from './URLInputField';

export const URLInputFieldLogic = ({
  urlValue,
  onSave,
  validateInput,
  gaCategory,
  gaAction,
}: URLInputFieldProps) => {
  const [inputValue, setInputValue] = useState(urlValue || '');
  const [isInputValid, setIsInputValid] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validateInput !== undefined) {
      setIsInputValid(validateInput(e.target.value));
    }
    setInputValue(e.target.value);
  };

  const handleSave = () => {
    if (onSave && inputValue !== (urlValue || '')) {
      onSave(inputValue);
    }
  };

  const handleEditClick = () => {
    if (!isEditing) ReactGA.event({ category: gaCategory, action: gaAction });

    if (isEditing) {
      handleSave();
    }
    setIsEditing(!isEditing);
  };

  return {
    inputValue,
    isInputValid,
    isEditing,
    onInputChange,
    handleEditClick,
    setIsEditing,
  };
};
