import { FunFactAnswer, Employee, WallDetail, Karma, Badge, Interest } from '../../index';
import tenYearServiceBadge from '../../../assets/badges/10_year_service.png';
import grandSlamBadge from '../../../assets/badges/grand_slam.png';
import iHelpedAlanBadge from '../../../assets/badges/i_helped_alan.png';
import stanfordInterest from '../../../assets/interests/stanford.jpg';
import seahawksInterest from '../../../assets/interests/seahawks.jpg';
import skiingInterest from '../../../assets/interests/skiing.png';

export const mockOthersWallDetail: WallDetail = {
  employee: {
    id: '0',
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    title: '',
    photoUrl: '',
    totalReportsCount: '0',
    insights: undefined,
    isActive: false,
  },
  workInfo: {
    brand: '',
    businessUnit: undefined,
    companyEntity: undefined,
    employeeType: undefined,
    workerType: undefined,
    positionTimeType: undefined,
    jobDesignation: undefined,
    workingArrangement: undefined,
    hireDate: undefined,
    terminationDate: undefined,
    contactNumber: undefined,
    office: undefined,
    tenure: undefined,
    workerLocationTimezone: undefined,
    workAddress: undefined,
  },
  personalInfo: {
    namePronunciation: undefined,
    pronoun: undefined,
    favoriteCoreValue: undefined,
    funFacts: [],
    interests: [],
    badges: [],
    primaryContactNumber: undefined,
    linkedinProfileUrl: undefined,
  },
  manager: {
    id: '0',
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    title: '',
    photoUrl: '',
    totalReportsCount: '0',
    insights: undefined,
    isActive: false,
  },
  assistant: undefined,
  assistantTo: [],
  reports: [],
  recentReceivedKarmas: [],
  totalReceivedKarmasCount: '4',
  totalSentKarmasCount: '12',
  isCurrentUser: false,
};

export const mockBadges: Badge[] = [
  {
    id: '1',
    name: '10 Years of Service',
    iconUrl: tenYearServiceBadge,
    description: 'Employees who have been Employees for 10 or more years',
    updatedDate: new Date(2022, 2, 10),
  },
  {
    id: '2',
    name: 'Grand Slam',
    iconUrl: grandSlamBadge,
    description: 'Employees who win the Grand Slam award',
    updatedDate: new Date(2022, 2, 10),
  },
  {
    id: '3',
    name: 'I Helped Alan',
    iconUrl: iHelpedAlanBadge,
    description: 'Employees who participated in the I Helped Alan Challenge!',
    updatedDate: new Date(2022, 2, 10),
  },
];

export const mockInterests: Interest[] = [
  {
    id: '1',
    name: 'Stanford',
    iconUrl: stanfordInterest,
    description: 'A page for people who went to or just like Stanford.',
  },
  {
    id: '2',
    name: 'Seahawks',
    iconUrl: seahawksInterest,
    description: '12th Man for life',
  },
  {
    id: '3',
    name: 'Skiing',
    iconUrl: skiingInterest,
    description:
      'Skiing / Snowboarding. Yes I put them together cause I think we should all be friends.',
  },
];

export const mockUser = {
  id: '99',
  username: 'rich',
  email: 'rich@zillowgroup.com',
  firstName: 'Rich',
  lastName: 'Barton',
  fullName: 'Rich Barton',
  title: 'Chief Executive Officer',
  photoUrl: 'https://wp.zillowstatic.com/zillowgroup/1/Rich-2-00bd1e-683x1024.jpg',
  totalReportsCount: '',
  insights: { colors: 'r-g-b-y', type: '', isVisible: true },
  namePronunciation: undefined,
  officePhone: '+1 (949) 555-5555 x55555',
  mobilePhone: '',
  startDate: new Date(2004, 10, 1),
  pronouns: 'He/him/his',
  badges: mockBadges,
  interests: mockInterests,
  tenure: { before: 30, after: 70 },
  brand: 'Zillow Group',
  isActive: true,
};

export const mockManager = {
  fullName: 'Lloyd Frink',
  title: 'Executive Chairman',
  numOfReports: 7171,
  photoUrl: 'https://wp.zillowstatic.com/zillowgroup/1/Rich-2-00bd1e-683x1024.jpg',
};

export const mockReports: Employee[] = [
  {
    id: '0',
    username: 'db',
    email: 'davebei@zillowgroup.com',
    firstName: 'David',
    lastName: 'Beitel',
    fullName: 'David Beitel',
    title: 'Chief Technology Officer',
    photoUrl: '',
    totalReportsCount: '1805',
    insights: undefined,
    isActive: true,
  },
  {
    id: '1',
    username: 'jw',
    email: 'jeremyw@zillowgroup.com',
    firstName: 'Jeremy',
    lastName: 'Wacksman',
    fullName: 'Jeremy Wacksman',
    title: 'Chief Operating Officer',
    photoUrl: '',
    totalReportsCount: '6318',
    insights: undefined,
    isActive: true,
  },
  {
    id: '2',
    username: 'kf',
    email: 'kristenf@zillowgroup.com',
    firstName: 'Kristen',
    lastName: 'Fowler',
    fullName: 'Kristen Fowler',
    title: 'Senior Executive Assistant',
    photoUrl: '',
    totalReportsCount: '1',
    insights: undefined,
    isActive: true,
  },
];

export const mockPersonalQuestionsAnswers: FunFactAnswer[] = [
  {
    funFactId: '1',
    input: 'Planet Earth',
  },
  {
    funFactId: '2',
    input: 'Awesome and normal things',
  },
  {
    funFactId: '3',
    input: 'Love',
  },
  {
    funFactId: '4',
    input:
      'Home is a safe haven and a comfort zone. A place to live with our families and pets and enjoy with friends. A place to build memories as well as a way to build future wealth. A place where we can truly just be ourselves.',
  },
];

export const mockKarmasReceived: Karma[] = [
  {
    id: '1',
    sender: mockReports[0],
    receiver: mockUser,
    message:
      'ac fringilla est commodo. Vestibulum rhoncus congue tempus. Vivamus cursus scelerisque nulla sit amet placerat.',
    coreValue: {
      id: '1',
      name: 'Own It',
    },
    sentDate: new Date('2021-01-22'),
    leadershipBlueprint: undefined,
  },
  {
    id: '2',
    sender: mockReports[1],
    receiver: mockUser,
    message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    coreValue: {
      id: '1',
      name: 'Own It',
    },
    sentDate: new Date('2021-01-04'),
    leadershipBlueprint: undefined,
  },
  {
    id: '3',
    sender: mockReports[2],
    receiver: mockUser,
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ornare lorem sit amet quam mattis, ac fringilla est commodo. Vestibulum rhoncus congue tempus. Vivamus cursus scelerisque nulla sit amet placerat. Morbi rhoncus dictum elementum. Nulla facilisi. Mauris porta sit amet erat a euismod. Duis lacus mauris, molestie et purus a, mollis',
    coreValue: {
      id: '2',
      name: 'Turn On the Lights',
    },
    sentDate: new Date('2020-10-22'),
    leadershipBlueprint: undefined,
  },
  {
    id: '4',
    sender: mockReports[1],
    receiver: mockUser,
    message:
      'ac fringilla est commodo. Vestibulum rhoncus congue tempus. Vivamus cursus scelerisque nulla sit amet placerat.',
    coreValue: {
      id: '4',
      name: 'ZG is a Team Sport',
    },
    sentDate: new Date('2020-07-11'),
    leadershipBlueprint: undefined,
  },
];

export const mockKarmasSent: Karma[] = [
  {
    id: '5',
    sender: mockUser,
    receiver: mockReports[1],
    message:
      'ac fringilla est commodo. Vestibulum rhoncus congue tempus. Vivamus cursus scelerisque nulla sit amet placerat.',
    coreValue: {
      id: '4',
      name: 'ZG is a Team Sport',
    },
    sentDate: new Date('2020-12-11'),
    leadershipBlueprint: undefined,
  },
  {
    id: '6',
    sender: mockUser,
    receiver: mockReports[2],
    message:
      'ac fringilla est commodo. Vestibulum rhoncus congue tempus. Vivamus cursus scelerisque nulla sit amet placerat.',
    coreValue: {
      id: '1',
      name: 'Own It',
    },
    sentDate: new Date('2021-02-08'),
    leadershipBlueprint: undefined,
  },
];
