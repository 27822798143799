import { UserState, UserAction } from './types';
import { mockUser } from './mock';

export const initialState: UserState = {
  loaded: false,
  loggedIn: false,
  authErrorMessage: '',
  userInfo: {
    employee: {
      id: '',
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      fullName: '',
      title: '',
      photoUrl: '',
      totalReportsCount: '',
      insights: undefined,
      isActive: false,
    },
    isImpersonationAllowed: false,
    isBadgeAdmin: false,
  },
  badges: [],
  interests: [],
};

export const userReducer = (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case 'AUTHENTICATE_USER_SUCCESS':
      return {
        ...state,
        loggedIn: true,
      };
    case 'AUTHENTICATE_USER_ERROR':
      return {
        ...state,
        loggedIn: false,
        authErrorMessage: action.msg,
      };
    case 'LOAD_USER_SUCCESS':
      return {
        ...state,
        loggedIn: true, // for refresh
        loaded: true,
        userInfo: action.userInfo,
      };
    case 'LOAD_USER_ERROR':
      return {
        ...state,
        loggedIn: false,
        loaded: true,
        authErrorMessage: action.msg,
      };
    case 'LOAD_MOCK_USER':
      return {
        ...state,
        loggedIn: true, // for refresh
        loaded: true,
        userInfo: mockUser,
      };
    case 'LOGOUT_USER_SUCCESS':
    case 'RESET_USER':
      return {
        ...initialState,
      };
    case 'LOAD_MY_BADGES_SUCCESS':
      return {
        ...state,
        badges: action.badges,
      };
    case 'LOAD_MY_BADGES_ERROR':
      return {
        ...state,
      };
    case 'ADD_TO_MY_BADGES_SUCCESS':
      return {
        ...state,
        badges: [...state.badges, action.badge],
      };
    case 'LOAD_MY_INTERESTS_SUCCESS':
      return {
        ...state,
        interests: action.interests,
      };
    case 'LOAD_MY_INTERESTS_ERROR':
      return {
        ...state,
      };
    case 'ADD_TO_MY_INTERESTS_SUCCESS':
      return {
        ...state,
        interests: [...state.interests, action.interest],
      };
    case 'REMOVE_FROM_MY_INTERESTS_SUCCESS':
      return {
        ...state,
        interests: [...state.interests.filter((i) => i.id !== action.interest.id)],
      };
    default:
      return state;
  }
};
