import React, { useState, useEffect, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { DecisionStatusValue } from '../../store/admin';
import { AdminBadgesContainerProps } from './AdminBadgesContainer';
import { Toast } from '@zillow/constellation';

export const AdminBadgesContainerLogic = ({
  pendingBadges,
  loadPendingBadges,
  submitBadgeDecision,
  enqueueToast,
}: AdminBadgesContainerProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rejectReason, setRejectReason] = useState('');

  const loadPendingBadgesCallback = useCallback((): Promise<void> => {
    return loadPendingBadges();
  }, [loadPendingBadges]);

  const approveCallback = useCallback(() => {
    ReactGA.event({ category: 'Badges', action: 'Admin Approve Badge' });
    const badgeId = pendingBadges[selectedIdx].badge?.id;
    if (badgeId) {
      setIsSubmitting(true);
      submitBadgeDecision(badgeId, DecisionStatusValue.BADGE_DECISION_TYPE_APPROVE, '')
        .then(() => {
          setRejectReason('');
          enqueueToast(<Toast appearance="success" body="The badge has been approved." />);
        })
        .catch(() => {
          enqueueToast(
            <Toast appearance="error" body="Something went wrong. Please try again later" />,
          );
        })
        .finally(() => setIsSubmitting(false));
    }
  }, [submitBadgeDecision, pendingBadges, selectedIdx]);

  const rejectCallback = useCallback(() => {
    ReactGA.event({ category: 'Badges', action: 'Admin Reject Badge' });
    const badgeId = pendingBadges[selectedIdx].badge?.id;
    if (badgeId) {
      setIsSubmitting(true);
      submitBadgeDecision(badgeId, DecisionStatusValue.BADGE_DECISION_TYPE_REJECT, rejectReason)
        .then(() => {
          setRejectReason('');
          enqueueToast(<Toast appearance="success" body="The badge has been rejected." />);
        })
        .catch(() => {
          enqueueToast(
            <Toast appearance="error" body="Something went wrong. Please try again later" />,
          );
        })
        .finally(() => setIsSubmitting(false));
    }
  }, [submitBadgeDecision, pendingBadges, selectedIdx, rejectReason]);

  useEffect(() => {
    setIsLoading(true);
    loadPendingBadgesCallback().then(() => {
      setIsLoading(false);
    });
  }, []);

  return {
    isLoading,
    isSubmitting,
    selectedIdx,
    setSelectedIdx,
    rejectReason,
    setRejectReason,
    approveCallback,
    rejectCallback,
  };
};
