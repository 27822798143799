/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { StringValue } from './google/protobuf/wrappers';
import { Badge } from './zallengine_badge';
import { Interest } from './zallengine_interest';
import { NamePronunciation } from './zallengine_name_pronunciation';
import { CoreValue, Pronoun } from './zallengine_zg101';

export const protobufPackage = 'zallengine_contract';

export interface PersonalInfo {
  pronoun: Pronoun | undefined;
  favoriteCoreValue: CoreValue | undefined;
  funFacts: PersonalInfo_FunFactAnswer[];
  interests: Interest[];
  badges: Badge[];
  namePronunciation: NamePronunciation | undefined;
  primaryContactNumber: string | undefined;
  linkedinProfileUrl: string | undefined;
}

export interface PersonalInfo_FunFactAnswer {
  funFactId: string;
  input: string;
}

function createBasePersonalInfo(): PersonalInfo {
  return {
    pronoun: undefined,
    favoriteCoreValue: undefined,
    funFacts: [],
    interests: [],
    badges: [],
    namePronunciation: undefined,
    primaryContactNumber: undefined,
    linkedinProfileUrl: undefined,
  };
}

export const PersonalInfo = {
  encode(message: PersonalInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pronoun !== undefined) {
      Pronoun.encode(message.pronoun, writer.uint32(10).fork()).ldelim();
    }
    if (message.favoriteCoreValue !== undefined) {
      CoreValue.encode(message.favoriteCoreValue, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.funFacts) {
      PersonalInfo_FunFactAnswer.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.interests) {
      Interest.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.badges) {
      Badge.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.namePronunciation !== undefined) {
      NamePronunciation.encode(message.namePronunciation, writer.uint32(50).fork()).ldelim();
    }
    if (message.primaryContactNumber !== undefined) {
      StringValue.encode(
        { value: message.primaryContactNumber! },
        writer.uint32(58).fork(),
      ).ldelim();
    }
    if (message.linkedinProfileUrl !== undefined) {
      StringValue.encode({ value: message.linkedinProfileUrl! }, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PersonalInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePersonalInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pronoun = Pronoun.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.favoriteCoreValue = CoreValue.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.funFacts.push(PersonalInfo_FunFactAnswer.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.interests.push(Interest.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.badges.push(Badge.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.namePronunciation = NamePronunciation.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.primaryContactNumber = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.linkedinProfileUrl = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PersonalInfo {
    return {
      pronoun: isSet(object.pronoun) ? Pronoun.fromJSON(object.pronoun) : undefined,
      favoriteCoreValue: isSet(object.favoriteCoreValue)
        ? CoreValue.fromJSON(object.favoriteCoreValue)
        : undefined,
      funFacts: globalThis.Array.isArray(object?.funFacts)
        ? object.funFacts.map((e: any) => PersonalInfo_FunFactAnswer.fromJSON(e))
        : [],
      interests: globalThis.Array.isArray(object?.interests)
        ? object.interests.map((e: any) => Interest.fromJSON(e))
        : [],
      badges: globalThis.Array.isArray(object?.badges)
        ? object.badges.map((e: any) => Badge.fromJSON(e))
        : [],
      namePronunciation: isSet(object.namePronunciation)
        ? NamePronunciation.fromJSON(object.namePronunciation)
        : undefined,
      primaryContactNumber: isSet(object.primaryContactNumber)
        ? String(object.primaryContactNumber)
        : undefined,
      linkedinProfileUrl: isSet(object.linkedinProfileUrl)
        ? String(object.linkedinProfileUrl)
        : undefined,
    };
  },

  toJSON(message: PersonalInfo): unknown {
    const obj: any = {};
    if (message.pronoun !== undefined) {
      obj.pronoun = Pronoun.toJSON(message.pronoun);
    }
    if (message.favoriteCoreValue !== undefined) {
      obj.favoriteCoreValue = CoreValue.toJSON(message.favoriteCoreValue);
    }
    if (message.funFacts?.length) {
      obj.funFacts = message.funFacts.map((e) => PersonalInfo_FunFactAnswer.toJSON(e));
    }
    if (message.interests?.length) {
      obj.interests = message.interests.map((e) => Interest.toJSON(e));
    }
    if (message.badges?.length) {
      obj.badges = message.badges.map((e) => Badge.toJSON(e));
    }
    if (message.namePronunciation !== undefined) {
      obj.namePronunciation = NamePronunciation.toJSON(message.namePronunciation);
    }
    if (message.primaryContactNumber !== undefined) {
      obj.primaryContactNumber = message.primaryContactNumber;
    }
    if (message.linkedinProfileUrl !== undefined) {
      obj.linkedinProfileUrl = message.linkedinProfileUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PersonalInfo>, I>>(base?: I): PersonalInfo {
    return PersonalInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PersonalInfo>, I>>(object: I): PersonalInfo {
    const message = createBasePersonalInfo();
    message.pronoun =
      object.pronoun !== undefined && object.pronoun !== null
        ? Pronoun.fromPartial(object.pronoun)
        : undefined;
    message.favoriteCoreValue =
      object.favoriteCoreValue !== undefined && object.favoriteCoreValue !== null
        ? CoreValue.fromPartial(object.favoriteCoreValue)
        : undefined;
    message.funFacts = object.funFacts?.map((e) => PersonalInfo_FunFactAnswer.fromPartial(e)) || [];
    message.interests = object.interests?.map((e) => Interest.fromPartial(e)) || [];
    message.badges = object.badges?.map((e) => Badge.fromPartial(e)) || [];
    message.namePronunciation =
      object.namePronunciation !== undefined && object.namePronunciation !== null
        ? NamePronunciation.fromPartial(object.namePronunciation)
        : undefined;
    message.primaryContactNumber = object.primaryContactNumber ?? undefined;
    message.linkedinProfileUrl = object.linkedinProfileUrl ?? undefined;
    return message;
  },
};

function createBasePersonalInfo_FunFactAnswer(): PersonalInfo_FunFactAnswer {
  return { funFactId: '0', input: '' };
}

export const PersonalInfo_FunFactAnswer = {
  encode(
    message: PersonalInfo_FunFactAnswer,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.funFactId !== '0') {
      writer.uint32(8).int64(message.funFactId);
    }
    if (message.input !== '') {
      writer.uint32(18).string(message.input);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PersonalInfo_FunFactAnswer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePersonalInfo_FunFactAnswer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.funFactId = longToString(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.input = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PersonalInfo_FunFactAnswer {
    return {
      funFactId: isSet(object.funFactId) ? globalThis.String(object.funFactId) : '0',
      input: isSet(object.input) ? globalThis.String(object.input) : '',
    };
  },

  toJSON(message: PersonalInfo_FunFactAnswer): unknown {
    const obj: any = {};
    if (message.funFactId !== '0') {
      obj.funFactId = message.funFactId;
    }
    if (message.input !== '') {
      obj.input = message.input;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PersonalInfo_FunFactAnswer>, I>>(
    base?: I,
  ): PersonalInfo_FunFactAnswer {
    return PersonalInfo_FunFactAnswer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PersonalInfo_FunFactAnswer>, I>>(
    object: I,
  ): PersonalInfo_FunFactAnswer {
    const message = createBasePersonalInfo_FunFactAnswer();
    message.funFactId = object.funFactId ?? '0';
    message.input = object.input ?? '';
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
