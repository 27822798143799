import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Flex,
  Heading,
  Label,
  Input,
  FormField,
  Textarea,
  LoadingButton,
  ButtonGroup,
  Spacer,
  Paragraph,
  Alert,
  withToast,
} from '@zillow/constellation';
import { AdminBadgesContainerLogic } from './AdminBadgesContainer.hooks';
import { RootState, PendingBadge } from '../../store';
import {
  loadPendingBadges,
  submitBadgeDecision,
  BadgeDecisionType,
  selectPendingBadges,
} from '../../store/admin';
import { ZallWallLink } from '../../components/Links';
import { Badge as BadgeIcon } from '../../components/Badge';
import DynamicConfig from '../../config/DynamicConfig';
import { Loader } from '../../components/Loader';
import { RightPanel } from '../InsightsTwinsContainer';

export interface AdminBadgesContainerProps {
  /** list of all pending badges */
  pendingBadges: PendingBadge[];
  loadPendingBadges: () => Promise<void>;
  submitBadgeDecision: (
    badgeId: string,
    decision: BadgeDecisionType,
    reason: string,
  ) => Promise<void>;
  enqueueToast: (msg: JSX.Element) => void;
}

const LeftPanel = styled(Flex)`
  width: 240px;
  border-right: 2px solid #ccc;
  border-bottom: none;
  min-height: 100vh;
  text-align: center;
`;

const PendingBadgeIcon = styled.div<{ selected?: boolean; processed?: boolean }>`
  border: ${(props) => (props.selected ? 'dashed' : 'none')};
  filter: ${(props) => (props.processed ? 'grayscale(100%)' : 'none')};

  :hover {
    cursor: pointer;
  }
`;

const IconWrapper = styled(Flex)`
  text-align: center;
`;

const AdminBadgesContainerBase: React.FC<AdminBadgesContainerProps> = (
  props: AdminBadgesContainerProps,
) => {
  const { pendingBadges } = props;
  const {
    isLoading,
    isSubmitting,
    selectedIdx,
    setSelectedIdx,
    rejectReason,
    setRejectReason,
    approveCallback,
    rejectCallback,
  } = AdminBadgesContainerLogic(props);
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();

  return (
    <Loader loading={isLoading}>
      {pendingBadges.length > 0 ? (
        <Flex display="flex" flexWrap="wrap" marginTop="lg" data-testid="admin-badges-container">
          <LeftPanel>
            <Heading level="4" marginY="sm">
              Pending Badges
            </Heading>
            {pendingBadges.map((pb, idx) => (
              <PendingBadgeIcon
                key={idx}
                onClick={() => setSelectedIdx(idx)}
                selected={idx === selectedIdx}
                processed={pb.processed}
              >
                <BadgeIcon
                  name={pb.badge?.name || ''}
                  iconUrl={`${ZALL_ENGINE_PROXY}${pb.badge?.iconUrl}`}
                />
              </PendingBadgeIcon>
            ))}
          </LeftPanel>
          <RightPanel>
            <Flex display="flex" flexWrap="wrap">
              <IconWrapper flex="2">
                <BadgeIcon
                  name={pendingBadges[selectedIdx].badge?.name || ''}
                  iconUrl={`${ZALL_ENGINE_PROXY}${pendingBadges[selectedIdx].badge?.iconUrl}`}
                  size={'200px'}
                />
              </IconWrapper>
              <Flex flex="3">
                <FormField
                  readOnly
                  label={<Label>Badge name</Label>}
                  control={
                    <Input
                      value={pendingBadges[selectedIdx].badge?.name}
                      data-testid="requested-badge-name"
                    />
                  }
                />
                <Label>Requested by</Label>
                <Paragraph marginY="xs">
                  <ZallWallLink
                    to={`/${pendingBadges[selectedIdx].owner?.username}`}
                    target="_blank"
                    data-testid="badge-owner-link"
                  >
                    {pendingBadges[selectedIdx].owner?.fullName}
                  </ZallWallLink>
                </Paragraph>

                <FormField
                  readOnly
                  label={<Label>Requestor department</Label>}
                  control={<Input value={pendingBadges[selectedIdx].department} />}
                  data-testid="badge-requestor-department"
                />
                <FormField
                  readOnly
                  label={<Label>Business justification</Label>}
                  control={<Input value={pendingBadges[selectedIdx].businessJustification} />}
                  data-testid="badge-business-justification"
                />
                <FormField
                  readOnly
                  label={<Label>Badge description</Label>}
                  control={<Input value={pendingBadges[selectedIdx].badge?.description} />}
                  data-testid="badge-description"
                />
                <FormField
                  readOnly
                  label={<Label>Estimated number of employees to receive this badge</Label>}
                  control={<Input value={pendingBadges[selectedIdx].estNumOfAssignees} />}
                  data-testid="badge-est-num-of-assignees"
                />
              </Flex>
              <Flex flex="2" padding="xl">
                {!pendingBadges[selectedIdx].processed && (
                  <ButtonGroup aria-label="approve and reject button" direction="column" fluid>
                    <LoadingButton
                      buttonType="primary"
                      onClick={approveCallback}
                      loading={isSubmitting}
                      data-testid="badge-approve-button"
                    >
                      Approve
                    </LoadingButton>
                    <LoadingButton
                      buttonType="caution"
                      disabled={rejectReason === ''}
                      onClick={rejectCallback}
                      loading={isSubmitting}
                      data-testid="badge-reject-button"
                    >
                      Reject
                    </LoadingButton>
                    <Spacer marginTop="xs" />
                    <Textarea
                      placeholder="Please provide reject reason"
                      value={rejectReason}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setRejectReason(e.target.value)
                      }
                      data-testid="badge-reject-reason"
                    />
                  </ButtonGroup>
                )}
              </Flex>
            </Flex>
          </RightPanel>
        </Flex>
      ) : (
        <Alert body="No pending badge at the moment." data-testid="no-badges-alert" />
      )}
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => ({
  pendingBadges: selectPendingBadges(state),
});

const mapDispatchToProps = {
  loadPendingBadges: loadPendingBadges,
  submitBadgeDecision: submitBadgeDecision,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const AdminBadgesContainer = connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(withToast(AdminBadgesContainerBase));

export { AdminBadgesContainer as default, AdminBadgesContainerBase };
