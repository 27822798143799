import React from 'react';
import ReactGA from 'react-ga4';
import {
  FormField,
  Select
} from '@zillow/constellation';
import { debounce } from 'lodash';

interface LoadLimitDropdownProps {
    pageLimiter: (value: number) => void;
    perPage: number;
}
const writeToGA = debounce(() => {
    ReactGA.event({ category: 'Load Limit', action: 'Clicked Load Limit Dropdown' });
  }, 1000);

export const LoadLimitDropdown: React.FC<LoadLimitDropdownProps> = ({
    pageLimiter,
    perPage
}: LoadLimitDropdownProps) => {
    const perPageOption = [10, 20, 50];
    
    return(
        <FormField
        data-testid="render-form-test"
        fluid={false}
        control={
            <Select
            placeholder="Load Limit"
            data-testid="render-select-test"
            fluid={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                writeToGA();
                pageLimiter(parseInt(e.target.value));
            }}
            >
            <option data-testid={perPage+"-render-option-test"} value="DEFAULT">
                {perPage + " items per page"}
            </option>
            {perPageOption.map((value, i) => (
                (perPage != value &&
                <option data-testid={value+"-render-option-test"} value={value} key={i}>
                {value + " items per page"}
                </option>)
            ))}
            </Select>
        }
        />
    )
    };