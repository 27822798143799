import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RpcImpl } from '../../contract/rpc';
import {
  ZallWebClientImpl,
  GetInsightsTwinsRequest,
  GetInsightsTwinsResponse,
  GetEmployeesWithInsightsColorRequest,
  GetEmployeesWithInsightsColorResponse,
} from '../../contract/zallengine_zallweb';
import {
  InsightsState,
  InsightsAction,
  LoadInsightsTwinsSuccess,
  LoadInsightsLeadersSuccess,
  LoadInsightsError,
} from './types';
import {
  validateGetInsightsTwinsResp,
  validateGetEmployeesWithInsightsColorResp,
} from './validators';
import DynamicConfig from '../../config/DynamicConfig';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { Employee } from '../index';
import { GetDisplayError } from '../errorHelpers';

/**
 *
export type ThunkAction<R, S, E, A extends Action> = (
  dispatch: ThunkDispatch<S, E, A>,
  getState: () => S,
  extraArgument: E
) => R;
 */

export const loadInsightsTwinsSuccess = (
  twins: Employee[],
  employee: Employee,
): LoadInsightsTwinsSuccess => ({
  type: 'LOAD_INSIGHTS_TWINS_SUCCESS',
  twins: twins,
  employee: employee,
});

export const loadInsightsLeadersSuccess = (leaders: Employee[]): LoadInsightsLeadersSuccess => ({
  type: 'LOAD_INSIGHTS_LEADERS_SUCCESS',
  leaders: leaders,
});

export const loadInsightsError = (msg: string): LoadInsightsError => ({
  type: 'LOAD_INSIGHTS_ERROR',
  msg,
});

export const loadInsightsTwins = (
  username: string,
): ThunkAction<Promise<void>, InsightsState, null, InsightsAction> => async (
  dispatch: ThunkDispatch<InsightsState, null, InsightsAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: GetInsightsTwinsRequest = {
    username: username,
  };

  return client
    .GetInsightsTwins(req)
    .then((resp: GetInsightsTwinsResponse) => {
      const [twins, employee, err] = validateGetInsightsTwinsResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(loadInsightsTwinsSuccess(twins, employee));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(loadInsightsError(errResp.message));
      return Promise.reject(errResp);
    });
};

export const loadInsightsLeaders = (
  color: string,
): ThunkAction<Promise<void>, InsightsState, null, InsightsAction> => async (
  dispatch: ThunkDispatch<InsightsState, null, InsightsAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: GetEmployeesWithInsightsColorRequest = {
    color: color,
  };

  return client
    .GetEmployeesWithInsightsColor(req)
    .then((resp: GetEmployeesWithInsightsColorResponse) => {
      const [leaders, err] = validateGetEmployeesWithInsightsColorResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(loadInsightsLeadersSuccess(leaders));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(loadInsightsError(errResp.message));
      return Promise.reject(errResp);
    });
};
