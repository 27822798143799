import React from 'react';
import styled from 'styled-components';
import { spaceMixin } from '@zillow/constellation';
import { BannerLogic } from './Banner.hooks';

import zillowBanner from './BannerImages/zillow.png';
import zgBanner from './BannerImages/zillowgroup.png';
import streetEasyBanner from './BannerImages/streeteasy.png';
import hotPadsBanner from './BannerImages/hotpads.png';
import truliaBanner from './BannerImages/trulia.png';
import dotloopBanner from './BannerImages/dotloop.png';
import zhlBanner from './BannerImages/zillow-home-loans.png';
import mortechBanner from './BannerImages/mortech.png';
import bridgeInteractiveBanner from './BannerImages/bridge-interactive.png';
import retslyBanner from './BannerImages/retsly.png';
import nakedApartmentsBanner from './BannerImages/naked-apartments.png';
import realEstateBanner from './BannerImages/realestate.png';
import outEastBanner from './BannerImages/out-east.png';
import mloaBanner from './BannerImages/mloa.png';
import './SpringFlowers.scss';

export interface BannerProps {
  /** the brand a person is affiliated with */
  brand?: string;
  /** standard children prop */
  children?: React.ReactNode;
}

type BrandBannersType = {
  [key: string]: {
    background: string;
    backgroundColor: string;
  };
};

export const BrandBanners: BrandBannersType = {
  zillow: {
    background: zillowBanner,
    backgroundColor: '#00315e',
  },
  zillowgroup: {
    background: zgBanner,
    backgroundColor: '#00315e',
  },
  streeteasy: {
    background: streetEasyBanner,
    backgroundColor: '#0080c5',
  },
  hotpads: {
    background: hotPadsBanner,
    backgroundColor: '#48c7b5',
  },
  trulia: {
    background: truliaBanner,
    backgroundColor: '#03acba',
  },
  dotloop: {
    background: dotloopBanner,
    backgroundColor: '#61b7ff',
  },
  zhl: {
    background: zhlBanner,
    backgroundColor: '#00315e',
  },
  mortech: {
    background: mortechBanner,
    backgroundColor: '#0174e3',
  },
  bridgeinteractive: {
    background: bridgeInteractiveBanner,
    backgroundColor: '#03b2ef',
  },
  retsly: {
    background: retslyBanner,
    backgroundColor: '#166daa',
  },
  nakedapartments: {
    background: nakedApartmentsBanner,
    backgroundColor: '#63c4d5',
  },
  realestatecom: {
    background: realEstateBanner,
    backgroundColor: '#f93937',
  },
  realnetouteast: {
    background: outEastBanner,
    backgroundColor: '#b1ecd3',
  },
  mloa: {
    background: mloaBanner,
    backgroundColor: '#f05223',
  },
};

const BannerWrapper = styled.div<{ background: string; backgroundColor: string }>`
  background: no-repeat scroll 100% 0;
  background-image: url(${(props) => props.background});
  background-color: ${(props) => props.backgroundColor};
  height: 175px;
  margin-bottom: -${spaceMixin('xl')};
  width: 100%;
  overflow: hidden;
  position: relative;
}`;

const BannerContent = styled.div`
  z-index: 1; // higher than animations, if any
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  position: absolute;
`;

export const Banner: React.FC<BannerProps> = (props: BannerProps) => {
  const { bannerRef, bannerType } = BannerLogic(props);
  const { children } = props;

  return (
    <BannerWrapper ref={bannerRef} {...bannerType}>
      <BannerContent>{children}</BannerContent>
    </BannerWrapper>
  );
};

/**
 * brands currently in db
 *
 * Zillow
 * Zillow Group
 * StreetEasy
 * HotPads
 * Trulia
 * dotloop
 * ZHL
 * Mortech
 * Bridge Interactive
 * Retsly
 * Naked Apartments
 * RealEstate.com
 * ZCS
 * RealNet/OutEast
 * MLOA
 */
