import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import {
  Flex,
  Heading,
  LoadingButton,
  Form,
  FormField,
  FormHelp,
  Label,
  Input,
  Button,
  TextButton,
  FieldSet,
  Legend,
  LabeledControl,
  Radio,
  Anchor,
  Alert,
  mediaBreakpointMixin,
} from '@zillow/constellation';
import { RootState } from '../../store';
import { BadgeApplication } from '../../store/admin';
import { submitNewBadge } from '../../store/badge';
import { Modal } from '../../components/Modal';
import { BadgeRequestContainerLogic } from './BadgeRequestContainer.hooks';
import DynamicConfig from '../../config/DynamicConfig';

export interface BadgeRequestContainerProps extends RouteComponentProps {
  submitNewBadge: (application: BadgeApplication) => Promise<void>;
}

const ModalHeader = styled(Heading)`
  text-align: left;
`;

const FormLabel = styled(Label)`
  cursor: default;
`;

const RequestBadgeBtn = styled(TextButton)`
  width: fit-content;
`;

const RadioButtonsWrapper = styled(Flex)`
  display: flex;
  justify-content: space-between;
  width: 50%;
  @media ${mediaBreakpointMixin('sm_lte')} {
    width: 80%;
  }
`;

export type BadgeFormValues = {
  hasReadGuidelines: string;
  badgeName: string;
  badgeDesc: string;
  bizJustif: string;
  numEmployees: number;
  badgeImg: File;
};

const BadgeRequestContainerBase: React.FC<BadgeRequestContainerProps> = (
  props: BadgeRequestContainerProps,
) => {
  const {
    isModalOpen,
    isUploading,
    submitSuccess,
    submitError,
    openModal,
    closeModal,
    onSubmit,
    validateFileDimensions,
    validateFileSize,
    register,
    handleSubmit,
    control,
    errors,
  } = BadgeRequestContainerLogic(props);
  const { NEW_BADGE_GUIDELINES_URL, BADGE_MAKER_URL } = DynamicConfig.GetConfig();

  return (
    <>
      <Modal
        header={
          <ModalHeader level={6}>
            {submitSuccess ? 'Thank you for your submission!' : 'Request New Badge'}
          </ModalHeader>
        }
        body={
          submitSuccess ? (
            <Flex marginX="lg" marginY="sm">
              Someone from the People Operations team will review your request and respond within 2
              days.
            </Flex>
          ) : (
            <Flex marginX="lg" marginY="sm" data-testid="request-new-badge-form">
              <Form>
                <FieldSet
                  required
                  legend={
                    <Legend>
                      Have you read the&nbsp;
                      <Anchor href={NEW_BADGE_GUIDELINES_URL} target="_blank" rel="noopener noreferrer">
                        badge guidelines
                      </Anchor>{' '}
                      on Zallway?
                    </Legend>
                  }
                  error={errors.hasReadGuidelines?.message !== undefined}
                  description={
                    <FormHelp>
                      {errors.hasReadGuidelines &&
                        'You must read the badge guidelines before requesting a new badge'}
                    </FormHelp>
                  }
                  marginBottom="xs"
                >
                  <RadioButtonsWrapper>
                    <LabeledControl
                      label={<Label>Yes</Label>}
                      control={
                        <Radio
                          value="Yes"
                          {...register('hasReadGuidelines', {
                            required: true,
                            validate: (v) => v === 'Yes',
                          })}
                        />
                      }
                    />
                    <LabeledControl
                      label={<Label>No</Label>}
                      control={
                        <Radio
                          value="No"
                          {...register('hasReadGuidelines', {
                            required: true,
                            validate: (v) => v === 'Yes',
                          })}
                        />
                      }
                    />
                  </RadioButtonsWrapper>
                </FieldSet>

                <FormField
                  required
                  label={<FormLabel>Name of new badge</FormLabel>}
                  control={
                    <Input {...register('badgeName', { required: 'Please enter a badge name' })} />
                  }
                  error={errors.badgeName?.message !== undefined}
                  description={<FormHelp>{errors.badgeName?.message}</FormHelp>}
                />
                <FormField
                  required
                  label={<FormLabel>Badge Description</FormLabel>}
                  control={
                    <Input
                      {...register('badgeDesc', { required: 'Please enter the badge description' })}
                    />
                  }
                  error={errors.badgeDesc?.message !== undefined}
                  description={<FormHelp>{errors.badgeDesc?.message}</FormHelp>}
                />
                <FormField
                  required
                  label={<FormLabel>Business Justification</FormLabel>}
                  control={
                    <Input
                      {...register('bizJustif', {
                        required: 'Please enter the business justification',
                      })}
                    />
                  }
                  error={errors.bizJustif?.message !== undefined}
                  description={<FormHelp>{errors.bizJustif?.message}</FormHelp>}
                />
                <FormField
                  required
                  label={<FormLabel>Estimated number of employees to receive this badge</FormLabel>}
                  control={
                    <Input
                      type="number"
                      {...register('numEmployees', {
                        valueAsNumber: true,
                        required: 'Please indicate the number of employees',
                        min: { value: 1, message: 'Please indicate a valid number of employees' },
                      })}
                    />
                  }
                  error={errors.numEmployees?.message !== undefined}
                  description={<FormHelp>{errors.numEmployees?.message}</FormHelp>}
                />
                <FormField
                  required
                  label={<FormLabel>Upload badge</FormLabel>}
                  control={
                    <Controller
                      name="badgeImg"
                      control={control}
                      rules={{
                        required: 'Please upload an icon for the badge',
                        validate: {
                          fileDimensions: async (v) =>
                            (await validateFileDimensions(v)) || 'Badge icon must be 500x500 px',
                          fileSize: (v) =>
                            validateFileSize(v) || 'Badge icon should not exceed 200KB',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="file"
                          accept="image/png"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.files && e.target.files.length > 0)
                              field.onChange(e.target.files[0]);
                          }}
                        />
                      )}
                    />
                  }
                  error={errors.badgeImg?.message !== undefined}
                  description={
                    <FormHelp>
                      Need help designing this badge? Use the tool{' '}
                      <Anchor href={BADGE_MAKER_URL} target="_blank" rel="noopener noreferrer">
                        here
                      </Anchor>{' '}
                      to create the badge yourself
                      <br />
                      {errors.badgeImg?.message
                        ? errors.badgeImg.message
                        : 'Badge icons must be a 500x500 px PNG file and have a max size of 200KB'}
                    </FormHelp>
                  }
                />

                {submitError && (
                  <Alert appearance="error" body="Something went wrong. Please try again later." />
                )}
              </Form>
            </Flex>
          )
        }
        footer={
          submitSuccess ? (
            <Button buttonType="primary" onClick={closeModal}>
              Okay
            </Button>
          ) : (
            <LoadingButton
              buttonType="primary"
              loading={isUploading}
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </LoadingButton>
          )
        }
        isOpen={isModalOpen}
        handleClose={closeModal}
        wide
        shouldCloseOnOutsideClick={false}
        data-testid="badge-request-modal"
      />

      <RequestBadgeBtn
        marginTop="xs"
        onClick={openModal}
        data-testid="badge-request-modal-request-new-badge"
      >
        Request New Badge
      </RequestBadgeBtn>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  submitNewBadge: submitNewBadge,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const BadgeRequestContainer = withRouter(
  connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
    mapStateToProps,
    mapDispatchToProps,
  )(BadgeRequestContainerBase),
);

export { BadgeRequestContainer as default, BadgeRequestContainerBase };
