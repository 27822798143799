import { Interest } from '../index';
import { InterestState, InterestAction } from './types';

export const initialState: InterestState = {
  allInterests: [],
  targetInterest: {} as Interest,
  interestFans: [],
  loadError: '',
};

export const interestReducer = (state = initialState, action: InterestAction): InterestState => {
  switch (action.type) {
    case 'LOAD_ALL_INTERESTS_SUCCESS':
      return {
        ...state,
        allInterests: action.interests,
      };
    case 'LOAD_INTEREST_FANS_SUCCESS':
      return {
        ...state,
        interestFans: action.fans,
        targetInterest: action.interest,
      };
    case 'LOAD_INTEREST_ERROR':
      return {
        ...state,
        loadError: action.msg,
      };
    default:
      return state;
  }
};
