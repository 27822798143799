import { Badge } from '../index';
import { BadgeState, BadgeAction } from './types';

export const initialState: BadgeState = {
  allBadges: [],
  targetBadge: {} as Badge,
  badgeOwner: undefined,
  badgeMembers: [],
  loadError: '',
};

export const badgeReducer = (state = initialState, action: BadgeAction): BadgeState => {
  switch (action.type) {
    case 'LOAD_ALL_BADGES_SUCCESS':
      return {
        ...state,
        allBadges: action.badges,
      };
    case 'LOAD_BADGE_WALL_DETAIL_SUCCESS':
      return {
        ...state,
        badgeMembers: action.members,
        badgeOwner: action.owner,
        targetBadge: action.badge,
      };
    case 'LOAD_BADGE_ERROR':
      return {
        ...state,
        loadError: action.msg,
      };
    case 'ADD_BADGE_MEMBER_SUCCESS':
      return {
        ...state,
        badgeMembers: [...state.badgeMembers, action.employee],
      };
    case 'REMOVE_BADGE_MEMBER_SUCCESS':
      return {
        ...state,
        badgeMembers: [...state.badgeMembers.filter((i) => i.id !== action.employee.id)],
      };
    case 'UPDATE_BADGE_DETAIL_SUCCESS':
      return {
        ...state,
        targetBadge: action.badge,
      };
    default:
      return state;
  }
};
