import React, { useState } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import { Paragraph, Text, TextButton, FormField, Select } from '@zillow/constellation';
export interface DropdownQuestionProps {
  /** label of a question */
  label: string;
  /** answer of a question */
  answer?: string;
  /** options to select of a question */
  options: string[];
  /** if text question is editable */
  editable?: boolean;
  /** submit callback */
  onSubmit?: (ans: string) => void;
  /** GA props */
  gaCategory: string;
  gaAction: string;
}

const QuestionWrapper = styled.div``;

export const DropdownQuestion: React.FC<DropdownQuestionProps> = ({
  label,
  answer,
  options,
  editable,
  onSubmit,
  gaCategory,
  gaAction,
}: DropdownQuestionProps) => {
  const [editing, setEditing] = useState(false);
  const [ans, setAns] = useState(answer);

  return (
    <QuestionWrapper data-testid="render-question-test">
      <Text data-testid="render-label-test" fontType="bodySmallHeading">
        {label}
      </Text>
      {editable && (
        <>
          <Text data-testid="render-editable-test" fontType="bodySmallHeading">
            {' '}
            -{' '}
          </Text>
          <TextButton
            data-testid="render-text-button-test"
            fontType="bodySmallHeading"
            onClick={() => {
              if (!editing) ReactGA.event({ category: gaCategory, action: gaAction });

              if (editing && onSubmit && ans) onSubmit(ans);
              setEditing(!editing);
            }}
          >
            {editing ? 'Save' : 'Edit'}
          </TextButton>
        </>
      )}
      {editing ? (
        <FormField
          data-testid="render-form-test"
          control={
            <Select
              data-testid="render-select-test"
              defaultValue={ans || 'DEFAULT'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAns(e.target.value)}
            >
              <option value="DEFAULT" disabled>
                Select one
              </option>
              {options.map((opt, i) => (
                <option value={opt} key={i}>
                  {opt}
                </option>
              ))}
            </Select>
          }
        />
      ) : (
        <Paragraph data-testid="render-answer-test">{ans}</Paragraph>
      )}
    </QuestionWrapper>
  );
};
